.alerts {
  margin-top: var(--space-20);
  .alert {
    margin-bottom: var(--space-20);
  }
}

.alert {
  display: block;
  border: 1px solid var(--grey);
  border-radius: 1rem;
  padding: var(--space-12) var(--space-20);
  position: relative;
  padding-right: var(--space-28);
  font-size: var(--font-size-14);
  line-height: var(--line-height-20);
  font-weight: bold;
  min-height: 44px;
}

.alert[type=error] {
  border-color: var(--red);
  color: var(--red);
  padding-left: var(--space-50);
  background-image: url("data:image/svg+xml,%3Csvg height='20' viewBox='0 0 20 20' width='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23ff2d37' stroke-linejoin='round'%3E%3Cpath d='m10.8944272 1.78885438 8.381966 16.76393202c.2469893.4939785.0467649 1.0946515-.4472136 1.3416408-.1388554.0694277-.2919685.1055728-.4472136.1055728h-16.76393201c-.55228475 0-1-.4477153-1-1 0-.1552451.03614509-.3083582.10557281-.4472136l2.70061195-5.4012239 5.68135406-11.36270812c.24698925-.4939785.84766229-.69420284 1.34164079-.44721359.1935274.0967637.3504499.25368619.4472136.44721359z'/%3E%3Cg stroke-linecap='round' stroke-width='2'%3E%3Cpath d='m10 13.1264648v-4.1264648'/%3E%3Cpath d='m10 16.4396973v-.4396973'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: top var(--space-12) left var(--space-16);
  background-repeat: no-repeat;
}

.alert[type=status], .alert[type=success] {
  border-color: var(--green);
  color: var(--green);
  padding-left: var(--space-45);
  background-image: url("data:image/svg+xml,%3Csvg height='12' viewBox='0 0 14 12' width='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 5.63225345 4 4.22419009 8-9.85644354' fill='none' stroke='%232db928' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(1 1)'/%3E%3C/svg%3E");
  background-position: top var(--space-16) left var(--space-16);
  background-repeat: no-repeat;

}

.alert__close {
  position: absolute;
  top: var(--space-12);
  right: var(--space-12);
}
