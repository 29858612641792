/*
---
name: Textarea
category: Fields
---

## Default
```textarea.html
<div style="padding: 1rem;">
  <con-field type="textarea" floatlabel>
    <label for="bio">Bio</label>
    <textarea id="bio" name="bio"></textarea>
    <div class="field__help">Enter your bio.</div>
  </con-field>
</div>
```

## Focused
```textarea-focused.html
<div style="padding: 1rem;">
  <con-field type="textarea" floatlabel is-focused>
    <label for="bio">Bio</label>
    <textarea id="bio" name="bio"></textarea>
    <div class="field__help">Enter your bio.</div>
  </con-field>
</div>
```

## Disabled
```textarea-disabled.html
<div style="padding: 1rem;">
  <con-field type="textarea" floatlabel disabled>
    <label for="bio">Bio</label>
    <textarea disabled id="bio" name="bio"></textarea>
    <div class="field__help">Enter your bio.</div>
  </con-field>
</div>
```

## Success
```textarea-success.html
<div style="padding: 1rem;">
  <con-field type="textarea" floatlabel success-status>
    <label for="bio">Bio</label>
    <textarea id="bio" name="bio"></textarea>
    <div class="field__help">Enter your bio.</div>
  </con-field>
</div>
```

## Error state
```textarea-error.html
<div style="padding: 1rem;">
  <con-field type="textarea" floatlabel error-status>
    <label for="bio">Bio</label>
    <textarea id="bio" name="bio"></textarea>
    <div class="field__help field__error">Please enter your bio.</div>
    <div class="field__help">Enter your bio.</div>
  </con-field>
</div>
```
*/
con-field[floatlabel][type=textarea] {

}

con-field[type=textarea] {
  &[is-focused] {
    textarea {
      border-color: var(--blue);
    }
  }
}
