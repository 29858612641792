@mixin truncate-overflow {
  overflow: hidden;
  position: relative;
  max-height: calc(var(--truncate-overflow-lh, var(--line-height-22)) * var(--truncate-overflow-max-lines, 3));
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;

  &::before {
    position: absolute;
    content: "...";
    bottom: 0;
    right: 0;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    background: white;
  }
}

.truncate-overflow {
  @include truncate-overflow;
}

