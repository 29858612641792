.technical-documents-page {
  .marquee {
    border-bottom: 2px solid var(--yellow);
  }
  .banner {
    padding: var(--space-38) 0;
  }
  .banner + .banner {
    border-top: 1px solid var(--light-grey);
  }
}
