.tire-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem 1rem;
  color: var(--black);
  justify-content: center;
  align-items: center;

  @include mobile {
    grid-template-columns: 1fr 1fr;
  }
}

.tire-cards--flex {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0 0;
  > * {
    max-width: 250px;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    @include mobile {
      max-width: 165px;
      width: calc(50% - 1rem);
    }
    padding: 0.5rem;
  }
}

.tire-cards__item {
  text-align: center;
  display: block;
  background-color: var(--white);
  padding: var(--space-32) var(--space-16);
  border-radius: 10px;
  height: 100%;

  > * + * {
    margin-top: var(--space-16);
  }

  @include mobile {
    padding: var(--space-20) var(--space-12);
  }

  @include hover-reset;
  &:hover {
    box-shadow: 0 0 40px 0 rgba(0,0,0,0.20);
    h3 {
      border-bottom: 1px solid var(--yellow);
    }
    img {
      animation: bounce 1.2s ease-out;
    }
  }

  h3 {
    @include as-h6;
    display: inline-block;
    @include mobile {
      font-size: var(--font-size-12);
    }
  }

  img {
    display: block;
    margin: 0 auto;
    @include mobile {
      width: auto;
      height: 100px;
    }
  }
}


