.no-results {
  @include regular-shadow;
  background-color: var(--white);
  padding: var(--space-32);
  border-radius: var(--space-16);

  > * + * {
    margin-top: var(--space-16);
  }
}

.no-results__content {
  display: flex;
  > * + * {
    margin-left: var(--space-12);
  }

  .icon {
    font-size: 2rem;
  }
}

.no-results__text {

}
