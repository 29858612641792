$sizes: 0, 4, 8, 12, 16, 20, 32, 38, 60;

@mixin space-x($size) {
  > * + * {
    margin-left: $size;
  }
}

@mixin mobile-space-x($size) {
  > * + * {
    @include mobile {
      margin-left: $size;
    }
  }
}

@mixin space-y($size) {
  > * + * {
    margin-top: $size;
  }
}


@mixin mobile-space-y($size) {
  > * + * {
    @include mobile {
      margin-top: $size;
    }
  }
}

@each $size in $sizes {
  .space-x-#{$size} {
    @include space-x(var(--space-#{$size}));
  }

  .mobile:space-x-#{$size} {
    > * + * {
      @include mobile-space-x(var(--space-#{$size}));
    }
  }

  .space-y-#{$size} {
    @include space-y(var(--space-#{$size}));
  }

  .mobile:space-y-#{$size} {
    @include mobile-space-y(var(--space-#{$size}));
  }
}
