/*
---
name: Radios
category: Fields
---

```radios.html
<con-field type="radios">
  <fieldset>
    <legend>Which is your car?</legend>
    <ul>
      <li>
       <input checked required="" type="radio" name="model" value="13551">
       <label>Savana 2500</label>
      </li>
      <li>
       <input required="" type="radio" name="model" value="13561">
       <label>Savana 3500</label>
      </li>
    </ul>
    <div class="field__help">Choose a vehicle</div>
  </fieldset>
</con-field>
```

## Focused
```radios-focused.html
<con-field type="radios" is-focused>
  <fieldset>
    <legend>Which is your car?</legend>
    <ul>
      <li>
       <input checked required="" type="radio" name="model" value="13551">
       <label>Savana 2500</label>
      </li>
      <li>
       <input required="" type="radio" name="model" value="13561">
       <label>Savana 3500</label>
      </li>
    </ul>
    <div class="field__help">Choose a vehicle</div>
  </fieldset>
</con-field>
```

## Disabled
```radios-disabled.html
<con-field type="radios" disabled>
  <fieldset>
    <legend>Which is your car?</legend>
    <ul>
      <li>
       <input disabled checked required="" type="radio" name="model" value="13551">
       <label>Savana 2500</label>
      </li>
      <li>
       <input disabled required="" type="radio" name="model" value="13561">
       <label>Savana 3500</label>
      </li>
    </ul>
    <div class="field__help">Choose a vehicle</div>
  </fieldset>
</con-field>
```

## Success
```radios-success.html
<con-field type="radios" success-status>
  <fieldset>
    <legend>Which is your car?</legend>
    <ul>
      <li>
       <input checked required="" type="radio" name="model" value="13551">
       <label>Savana 2500</label>
      </li>
      <li>
       <input required="" type="radio" name="model" value="13561">
       <label>Savana 3500</label>
      </li>
    </ul>
    <div class="field__help">Choose a vehicle</div>
  </fieldset>
</con-field>
```

## Error
```radios-error.html
<con-field type="radios" error-status>
  <fieldset>
    <legend>Which is your car?</legend>
    <ul>
      <li>
       <input checked required="" type="radio" name="model" value="13551">
       <label>Savana 2500</label>
      </li>
      <li>
       <input required="" type="radio" name="model" value="13561">
       <label>Savana 3500</label>
      </li>
    </ul>
    <div class="field__help field__error">Please choose a vehicle</div>
    <div class="field__help">Choose a vehicle</div>
  </fieldset>
</con-field>
```
*/

/*
---
name: Checkboxes
category: Fields
---

```radios.html
<con-field type="checkboxes">
  <fieldset>
    <legend>Which is your car?</legend>
    <ul>
      <li>
       <input checked required="" type="checkbox" name="model" value="13551">
       <label>Savana 2500</label>
      </li>
      <li>
       <input required="" type="checkbox" name="model" value="13561">
       <label>Savana 3500</label>
      </li>
    </ul>
    <div class="field__help">Choose a vehicle</div>
  </fieldset>
</con-field>
```

## Focused
```radios-focused.html
<con-field type="checkboxes" is-focused>
  <fieldset>
    <legend>Which is your car?</legend>
    <ul>
      <li>
       <input checked required="" type="checkbox" name="model" value="13551">
       <label>Savana 2500</label>
      </li>
      <li>
       <input required="" type="checkbox" name="model" value="13561">
       <label>Savana 3500</label>
      </li>
    </ul>
    <div class="field__help">Choose a vehicle</div>
  </fieldset>
</con-field>
```

## Disabled
```radios-disabled.html
<con-field type="checkboxes" disabled>
  <fieldset>
    <legend>Which is your car?</legend>
    <ul>
      <li>
       <input disabled checked required="" type="checkbox" name="model" value="13551">
       <label>Savana 2500</label>
      </li>
      <li>
       <input disabled required="" type="checkbox" name="model" value="13561">
       <label>Savana 3500</label>
      </li>
    </ul>
    <div class="field__help">Choose a vehicle</div>
  </fieldset>
</con-field>
```

## Success
```radios-success.html
<con-field type="checkboxes" success-status>
  <fieldset>
    <legend>Which is your car?</legend>
    <ul>
      <li>
       <input checked required="" type="checkbox" name="model" value="13551">
       <label>Savana 2500</label>
      </li>
      <li>
       <input required="" type="checkbox" name="model" value="13561">
       <label>Savana 3500</label>
      </li>
    </ul>
    <div class="field__help">Choose a vehicle</div>
  </fieldset>
</con-field>
```

## Error
```radios-error.html
<con-field type="checkboxes" error-status>
  <fieldset>
    <legend>Which is your car?</legend>
    <ul>
      <li>
       <input checked required="" type="checkbox" name="model" value="13551">
       <label>Savana 2500</label>
      </li>
      <li>
       <input required="" type="checkbox" name="model" value="13561">
       <label>Savana 3500</label>
      </li>
    </ul>
    <div class="field__help field__error">Please choose a vehicle</div>
    <div class="field__help">Choose a vehicle</div>
  </fieldset>
</con-field>
```
*/

/*
---
name: Single checkbox
category: Fields
---

```checkbox.html
<con-field type="checkbox">
  <input required="" type="checkbox" name="tos" id="tos" value="1">
  <label for="tos">Agree to terms and conditions</label>
</con-field>
```

## Focused
```checkbox-focused.html
<con-field type="checkbox" is-focused>
  <input required="" type="checkbox" name="tos" id="tos" value="1">
  <label for="tos">Agree to terms and conditions</label>
</con-field>
```

## Disabled
```checkbox-disabled.html
<con-field type="checkbox" disabled>
  <input disabled required="" type="checkbox" name="tos" id="tos" value="1">
  <label for="tos">Agree to terms and conditions</label>
</con-field>
```

## Success
```checkbox-success.html
<con-field type="checkbox" success-status>
  <input required="" type="checkbox" name="tos" id="tos" value="1">
  <label for="tos">Agree to terms and conditions</label>
</con-field>
```

## Error
```checkbox-error.html
<con-field type="checkbox" error-status>
  <input required="" type="checkbox" name="tos" id="tos" value="1">
  <label for="tos">Agree to terms and conditions</label>
  <div class="field__help field__error">You need to agree to terms and conditions</div>
</con-field>
```
*/
con-field[type=checkbox],
con-field[type=checkboxes],
.field__type-checkbox {
  display: flex;
  align-items: center;

  input + label {
    margin-left: var(--space-8);
  }
}

con-field[type=checkbox],
con-field[type=checkboxes],
con-field[type=radio],
con-field[type=radios] {
  legend {
    font-weight: bold;
    display: block;
  }

  label {
    font-weight: normal;
    text-transform: initial !important;
    letter-spacing: normal;
  }

  fieldset > * + * {
    margin-top: var(--space-8);
  }

  fieldset > ul {
    input + label {
      margin-left: var(--space-12);
    }

    li {
      display: flex;
    }


    li + li {
      margin-top: var(--space-8);
    }
  }

  &[disabled] {
    legend, label {
      color: var(--grey);
    }
  }

  &[is-focused] {
    input:focus {
      border-color: var(--blue);
    }
  }

  .field__help {
    padding-left: var(--space-32);
  }
}


con-field[type=checkbox],
con-field[type=radio] {
  flex-wrap: wrap;
  label {
    font-weight: bold;
  }

  .field__help {
    flex: 1 0 100%;
    padding-left: var(--space-28);
  }
}


con-field[error-status][type=checkbox],
con-field[error-status][type=checkboxes],
con-field[error-status][type=radio],
con-field[error-status][type=radios] {
  input {
    border-color: var(--red);
  }
}

con-field[error-status][type=checkbox] {
  background-image: none;
  label {
    color: var(--black) !important;
  }
}

con-field[success-status][type=checkbox] {
  background-image: none;
}
