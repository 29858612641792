.tire-feature {
  [slot=tooltip] {
    img + * {
      margin-top: var(--space-12);
    }
    > * + * {
      margin-top: var(--space-12);
    }
    .link-with-arrow::after {
      display: none;
    }
  }
}
