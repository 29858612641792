con-location-form {
  display: block;

  form > * + * {
    margin-top: var(--space-4);
  }

  div {
    position: relative;
    margin-bottom: var(--space-4);
    input {
      padding-right: var(--space-34) !important;
    }
    button {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      font-size: var(--font-size-32);
      @include mobile {
        font-size: var(--font-size-24);
      }
    }
  }

  &[inverted] {
    .icon__pin {
      --pin-fill: var(--yellow);
      --circle-fill: #000000;
    }
  }
}
