  @mixin full-bleed {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

.full-bleed {
  @include full-bleed;
}

@mixin container-bleed {
  width: 100vw;
  margin-left: -1rem;
}

.container-bleed {
  @include container-bleed;
}
