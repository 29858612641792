$mobile_width: 768px;
$wide_width: 1170px;

:root {
  --ratio: 1.5;
  --base-font-size: 16px;

  --breadcrumb-min-height: 62px;
  --mobile-breadcrumb-min-height: 32px;

  // Animations
  --animate-duration: 1s;
  --animate-delay: 100ms;
  --animate-repeat: 1;

  // Colors
  --dark-yellow: #c27e00;
  --yellow: #ffa500;
  --yellow-contrast: #a36a00;
  --light-yellow: #fed185;
  --dark-black: #1D1D1D;
  --black: #333;
  --light-black: rgba(0,0,0, .6);
  --darkest-grey: #7e7e7e;
  --dark-grey: #949494;
  --grey: #cdcdcd;
  --light-grey: #e9e9e9;
  --lightest-grey: #f3f3f3;
  --white: #fff;
  --blue: #009dd1;
  --blue-contrast: #007ea8;
  --red: #E0000B;
  --green: #26A022;

  // Typography
  --font-family: "Stag Sans", Arial, Helvetica, sans-serif;
  --font-family-bold: "Stag Sans Bold", Arial, Helvetica, sans-serif;

  // Font sizes
  --font-size-10: 0.625rem; // 10px
  --font-size-12: 0.75rem; // 12px
  --font-size-14: 0.875rem; // 14px
  --font-size-15: 0.93rem; // 15px
  --font-size-16: 1rem; // 16px
  --font-size-18: 1.125rem; // 18px
  --font-size-20: 1.25rem; // 20px
  --font-size-22: 1.375rem; // 22px
  --font-size-24: 1.5rem; // 24px
  --font-size-28: 1.75rem; // 28px
  --font-size-30: 1.875rem; // 30px
  --font-size-32: 2rem; // 32px
  --font-size-40: 2.5rem; // 40px
  --font-size-42: 2.625rem; // 42px
  --font-size-45: 2.8125rem; // 45px
  --font-size-50: 3.125rem; // 50px
  --font-size-60: 3.75rem; // 60px

  // Line heights
  --line-height-16: 1rem; // 16px
  --line-height-18: 1.125rem; // 18px
  --line-height-20: 1.25rem; // 20px
  --line-height-22: 1.375rem; // 22px
  --line-height-24: var(--ratio); // 24px
  --line-height-26: 1.625rem; // 26px
  --line-height-28: 1.75rem; // 28px
  --line-height-30: 1.875rem; // 30px
  --line-height-32: 2rem; // 32px
  --line-height-34: 2.125rem; // 34px
  --line-height-36: 2.25rem; // 36px
  --line-height-38: 2.375rem; // 38px
  --line-height-40: 2.5rem; // 40px
  --line-height-48: 3rem; // 48px
  --line-height-60: 3.75rem; // 60px

  // Letter spacing
  --letter-spacing-0_25: 0.015625rem; // 0.25px
  --letter-spacing-0_5: 0.03125rem; // 0.5px
  --letter-spacing-1: 0.0625rem; // 1px
  --letter-spacing-1_25: 0.078125rem; // 1.25px

  // Horizontal rhythm
  --space-0: 0; // 4px
  --space-4: 0.25rem; // 4px
  --space-8: 0.5rem; // 8px
  --space-12: 0.75rem; // 12px
  --space-13: 0.8125rem; // 13px
  --space-16: 1rem; // 16px
  --space-20: 1.25rem; // 20px
  --space-22: 1.375rem; // 22px
  --space-24: 1.5rem; // 24px
  --space-26: 1.625rem; // 26px
  --space-28: 1.75rem; // 28px
  --space-32: 2rem; // 32px
  --space-34: 2.125rem; // 34px
  --space-38: 2.375rem; // 38px
  --space-45: 2.8125rem; // 45px
  --space-50: 3.125rem; // 50px
  --space-60: 3.75rem; // 60px
  --space-80: 5rem; // 80px

  // Screen sizes
  //--mobile-width: 48rem; // 768px
  --mobile-width: $mobile_width; // 768px
  --toolbar-height: 80px;
  --header-height: 74px;
}
