.images-section {
  > * + * {
    margin-top: var(--space-38);
    @include mobile {
      margin-top: var(--space-22);
    }
  }
}

.images-section__title {
  @include mobile {
    @include as-h3;
    text-align: center;
  }
}

.images-section__subtitle {
  text-align: center;
  margin-top: var(--space-4);

  a {
    @include text-cta;
  }

  @include middle {
    display: none;
  }
}

.images-section__images {
  display: grid;
  grid-gap: var(--space-60) var(--space-20);
}

.images-section--tire-images .images-section__images {
  grid-template-columns: repeat(auto-fill, minmax(250px, 335px));
  grid-gap: var(--space-45);
  @include mobile {
    grid-template-columns: 1fr;
    grid-gap: var(--space-22);
  }
}

.images-section--logos {
  > * + * {
    @include mobile {
      margin-top: var(--space-34);
    }
  }

  .images-section__subtitle {
    @include mobile {
      margin-top: var(--space-4);
    }
  }

  .images-section__images {
    grid-template-columns: repeat(auto-fill, 268px);
    @include mobile {
      grid-template-columns: minmax(auto, 280px);
      justify-content: center;
      grid-row-gap: var(--space-38);
    }
  }

  .image-asset__meta {
    @include mobile {
      padding-left: var(--space-16);
      padding-right: var(--space-16);
    }
  }

  .image-asset__links {
    @include mobile {
      padding-right: 0;
    }
  }
}
