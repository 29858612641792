.news-teaser {
  display: flex;
  @include mobile {
    display: block;
  }
}

.news-teaser__title {
  @include as-h6;
  margin-bottom: var(--space-8);
}

.news-teaser__content {
  padding-top: var(--space-8);
  @include mobile {
    padding-top: 0;
  }
}

.news-teaser__image-stub {
  background-color: #000000;
  background-image: url("/themes/custom/nextcontinental/assets/images/contiseal-white.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50%;
  height: 128px;
  width: 100%;
  border-radius: 1rem;
  @include mobile {
    display: none;
  }
}

.news-teaser__image {
  border-radius: 1rem;
  overflow: hidden;
  width: 171px;
  flex-shrink: 0;
  margin-right: var(--space-16);

  @include mobile {
    border: 1px solid var(--light-grey);
    width: 100%;
    margin-bottom: var(--space-8);
    margin-right: 0;
  }

  a {
    @include animate-image-on-hover;
    display: block;
    border-radius: 1rem;
    background-color: var(--light-grey);
  }

  img {
    width: 100%;
    height: auto;
  }
}

