.new-mini-teaser {
  h2 {
    @extend .as-h6;
  }
  > * + * {
    margin-top: var(--space-4);
  }
}

.new-mini-teaser__body {
  @extend .text-base;
}
