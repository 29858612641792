.media-gallery {

  .media--tire-gallery-item,
  .media--media-gallery-item,
  .media--media-gallery-item > a {
    @include animate-image-on-hover;

    @include mobile {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }

  > * {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.25rem;
    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  .media--tire-gallery-item,
  .media--media-gallery-item {
    display: block;
    text-align: center;
    border-radius: .625rem;
    background-color: var(--lightest-grey);

    @include mobile {
      border-radius: 0;
    }

    picture, img {
      //border-radius: .625rem;
      @include mobile {
       // border-radius: 0;
      }
    }
  }

  .media--tire-gallery-item {
    background-color: var(--light-grey);
    padding: var(--space-24);
    > .image {
      background-color: var(--light-grey);
    }
  }

  con-column-slider .media--tire-gallery-item,
  con-column-slider .media--media-gallery-item {
    position: relative;
    &:before {
      @include mobile {
        content: '';
        background: url("data:image/svg+xml,%3Csvg height='21' viewBox='0 0 21 21' width='21' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(1 1)'%3E%3Ccircle cx='9.5' cy='9.5' fill='%23fff' r='9.5' stroke='%23ffa500'/%3E%3Cpath d='m6 9.5h8m-4-4v8' stroke='%23333' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E") center center no-repeat;
        width: 21px;
        height: 21px;
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 1;
      }
    }
  }

  con-column-slider .media--media-gallery-item:not(:nth-of-type(1)):not([data-con-column-slider-slide-visible]) {
    @include mobile {
      display: none;
    }
  }
}

.media-gallery__more {
  text-align: center;
  @include mobile {
    display: none;
  }
}

* + .media-gallery__more {
  margin-top: var(--space-24);
}

.media-gallery--three-col {
  > * {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.25rem;
    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  .media--media-gallery-item {
    @include mobile {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
