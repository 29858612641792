.webform-page {

}

.webform-page__webform-section {
  --background-color: var(--lightest-grey);
  background-color: var(--background-color);
  padding-top: var(--space-80);
  padding-bottom: var(--space-80);

  @include mobile {
    padding-top: var(--space-38);
    padding-bottom: var(--space-38);
  }

  .webform-submission-form {
    --con-field-float-label-background: var(--white);
  }
}

.webform-page__webform-section--animated {
  con-field {
    @include animated;
    @include animated--fadeInUp;
    @include animated-staggered(20);

    &[type="select"] {
      position: relative;
      z-index: 1;
    }
  }
}

.webform-page__webform-container {
  margin: 0 auto;
  max-width: 495px;
  width: 100%;
}

.webform-page__webform {

  form {
    & > * + *,
    .fieldset-wrapper > * + *,
    .webform-elements > * + * {
      margin-top: var(--space-24);
    }

    * + #ajax-wrapper,
    #ajax-wrapper + *,
    * + .webform-type-fieldset,
    .webform-type-fieldset + * {
      margin-top: var(--space-60);
      @include mobile {
        margin-top: var(--space-38);
      }
    }

    con-field[floatlabel][is-focused] label,
    con-field[floatlabel]:not([is-empty]) label {
      background: linear-gradient(to bottom, var(--background-color) 50%, #fff 50%);;
    }

    input:not([type=file]):not([type=submit]), select, textarea {
      background-color: var(--white);
    }

    #edit-photo-upload--description {
      font-style: italic;
      br {
        content:'';
        &:after{content:' ';}
      }
    }

    //con-field[type=textarea] {
    //  width: 140%;
    //  margin-left: -20%;
    //  @include mobile {
    //    width: 100%;
    //    margin-left: 0;
    //  }
    //}

    .field__name-tos-yesno {
      margin-top: var(--space-60);
      @include mobile {
        margin-top: var(--space-38);
      }
      > div {
        flex: 1 0 100%;
        margin-bottom: var(--space-8);
      }
      label {
        @include mobile {
          flex-basis: 80%;
        }
      }
      input[type=checkbox] {
        @include mobile {
          position: relative;
          top: -10px;
        }
      }
    }

    [type=submit] {
      @include mobile {
        width: 100%;
      }
    }

    [type=select], [type=tel] {
      @include middle {
        max-width: 300px;
      }
    }

  }
}

con-field[type=webform_image_file] {
  .field__help {
    padding-left: 0;
  }
}
