.tcp-page__disclosures {
  .text-content {
    ol {
      list-style-position: outside;
      margin-left: 0.75em;
      li {
        padding-left: 0.5em;
        &::marker {
          font-weight: bold;
        }
      }
    }
    li + li {
      margin-top: 1.5em;
    }
    p + p {
      margin-top: 1em;
    }
  }
}

.banner + .tcp-page__disclosures, .tcp-page__disclosures {
  padding-top: var(--space-60);
  border-top: 0;
}
