.promo-bar {
  background-color: #000002;
  display: flex;
  align-content: center;
  align-items: center;
  min-height: 36px;
  .btn {
    @include mobile {
      width: 100%;
    }
  }
}

.path-frontpage {
  .promo-bar {
    border-top: 0;
    position: sticky;
    top: var(--header-height);

    @include touch {
      --header-height: 49px;
    }

    @include middle {
      z-index: 99;
    }
  }

  &.toolbar-vertical .promo-bar {
    top: calc(var(--header-height) + var(--toolbar-height));
  }
}

.promo-bar__disclosure {
  position: sticky;
  width: 100%;

  [slot=toggle] {
    height: 36px;
    display: block;
    text-align: center;
    color: var(--white);
    font-size: var(--font-size-12);
    font-weight: bold;
    margin: 0 auto;
    padding: var(--space-4);

    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 22px;

    background-image: url("data:image/svg+xml,%3Csvg height='11' viewBox='0 0 11 11' width='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6.5 1v11m-5.5-5.5h11' fill='none' stroke='%23FFF' stroke-linecap='round' transform='translate(-1 -1)'/%3E%3C/svg%3E");
  }

  &[expanded] [slot=toggle] {
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg height='2' viewBox='0 0 13 1' width='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1 7h11' fill='none' stroke='%23FFF' stroke-linecap='round' transform='translate(0 -6)'/%3E%3C/svg%3E");
  }

  [slot=detail] {

  }

  &[expanded] [slot=detail] {
    text-align: center;
    background-color: #000002;
    color: var(--white);
    border: 5px solid var(--yellow);
    display: block;
    position: relative;
    padding: var(--space-16);
  }

  [slot=close] {
    display: none;

  }

  &[expanded] [slot=close] {
    display: block;
    position: absolute;
    top: var(--space-12);
    right: var(--space-12);
    @include mobile {
      top: 0.5rem;
      right: 0.5rem;
    }
  }
}

.promo-bar__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.promo-bar__container > * + * {
  margin-top: var(--space-16);
  @include mobile {
    margin-top: var(--space-20);
  }
}

.promo-bar__image {
  // Image should compensate bottom padding of the detail slot, so it looks like on the edge.
  margin-bottom: -1rem;
  margin-top: var(--space-24);
  @include mobile {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.promo-bar__title {
  @include section-title;
}

.promo-bar__subtitle {
  @include text-xl;
  margin-top: var(--space-12);
  max-width: 780px;
  @include mobile {
    @include text-base;
  }
}

// Promo bar modal
.promo-modal__content {
  @include text-lg;
  text-align: center;
  background-color: var(--white);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.promo-modal__content > * + * {
  margin-top: var(--space-12);
}

.promo-modal__content img {
  // Image should compensate bottom padding of the detail slot, so it looks like on the edge.
  margin-bottom: calc(-1 * var(--space-20));
  margin-top: var(--space-16);
  width: 100%;
  height: auto;
}

.promo-modal__title {
  font-size: var(--font-size-20);
  line-height: var(--line-height-24);
  font-weight: bold;
}
