.banner-with-image {
}

.banner-with-image--default {
  background: var(--yellow);

  .banner-with-image__inner {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: var(--space-12);
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 132px;

    padding: var(--space-12) 0;

    @include mobile {
      display: flex;
      flex-direction: column;
      padding-top: var(--space-20);
      padding-bottom: 0;
      grid-gap: var(--space-4);
    }

    > * {
      @include mobile {
        padding: var(--space-12) 0;
      }
    }
  }

  .banner-with-image__image {
    @include mobile {
      align-self: auto;
      order: 3;
      overflow: hidden;
      position: relative;
      padding-top: var(--space-4);
      padding-bottom: 0;
    }

    &.banner-with-image__image--has-mobile-version {
      .banner-with-image__mobile-image {
        display: none;
      }

      @include mobile {
        > img {
          display: none;
        }
        .banner-with-image__mobile-image {
          display: block;
        }
      }
    }
  }

  .banner-with-image__copy {
    text-align: center;

    @include mobile {
      padding: 0;
    }

    div {
      margin-top: var(--space-4);
    }
  }

  .banner-with-image__copy-title {
    @include as-h4;
    @include mobile {
      font-size: var(--font-size-16);
      margin-bottom: var(--space-8);
    }
  }

  .banner-with-image__copy-text {
    @include mobile {
      font-size: var(--font-size-12);
      font-weight: bold;
      line-height: var(--line-height-20);
    }

    p + p {
      margin-top: var(--space-4);
    }
  }

  .banner-with-image__ctas {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;

    > * + * {
      margin-top: var(--space-12);
    }
  }
}

.banner-with-image--brand22 {
  background-color: #000000;
  background-repeat: no-repeat;
  background-position: top right;
  color: var(--white);
  padding: var(--space-80) 0;
  background-image: var(--default-background-image);
  background-size: cover;

  @include mobile {
    padding: 125px 0 var(--space-38) 0;
    text-align: center;
    background-image: var(--mobile-background-image, var(--default-background-image));
  }

  .banner-with-image__inner {
    > * + * {
      margin-top: var(--space-16);
    }
  }

  .banner-with-image__copy {
    background-color: rgba(0, 0, 0, 0.5);
    max-width: 620px;

    @include mobile {
      background-color: transparent;
      max-width: none;
    }
  }

  .banner-with-image__copy-title,
  .banner-with-image__copy-text {
    font-size: 42px;
    line-height: 56px;
    font-weight: 300;
    color: #FFFFFF;

    .text-uppercase {
      font-size: var(--font-size-32);
      @include text-letterspace;
    }

    @include mobile {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .banner-with-image__ctas {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    @include mobile {
      flex-direction: column;
    }

    > * + * {
      margin-left: var(--space-12);
      @include mobile {
        margin-left: 0;
        margin-top: var(--space-12);
      }
    }

    .btn {
      @include mobile {
        width: 100%;
      }
    }
  }

}

.banner-with-image--style-center {
  position: relative;

  @include mobile {
    padding: 0;
  }

  .banner-with-image__background {
    background-image: var(--default-background-image);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    @include mobile {
      height: 160px;
      background-size: cover;
      background-position: center center;
      background-image: var(--mobile-background-image, var(--default-background-image));
      position: static;
      width: 100%;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      @include mobile {
        display: none;
      }
    }
  }

  .banner-with-image__copy-title {
    text-transform: uppercase;
    letter-spacing: 6px;
  }

  .banner-with-image__inner {
    position: relative;
    padding: var(--space-38) 0;
  }

  .banner-with-image__copy {
    background: none;
    text-align: center;
    margin: 0 auto;
    max-width: 840px;
    > * + * {
      @include mobile {
        margin-top: var(--space-8);
      }
    }
  }

  .banner-with-image__copy-text {
    font-size: var(--font-size-20);
    line-height: var(--line-height-26);
    @include mobile {
      font-size: var(--font-size-18);
    }
  }

  .banner-with-image__ctas {
    justify-content: center;
  }

  .banner-with-image__inner > * + * {
    margin-top: var(--space-24);
  }
}
