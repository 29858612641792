.load-more-pager {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > * + * {
    margin-top: var(--space-20);
  }
  .pager {
    display: flex;
    @include mobile {
      width: 100%;
    }
  }
  .pager__item {
    @include mobile {
      width: 100%;
    }
  }
  .btn {
    @include mobile {
      width: 100%;
    }
  }
}
