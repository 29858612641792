.news-list-with-image {
  @include very-dark-background;
  --vertical-padding: var(--space-60);
  padding: var(--vertical-padding) 0;
  @include mobile {
    --vertical-padding: var(--space-38);
  }
}

.news-list-with-image:nth-child(even) {
  @include dark-background;
}

.news-list-with-image__body {
  display: flex;
  justify-content: space-between;

  > * + * {
    margin-left: var(--space-80);
    @include mobile {
      margin-left: 0;
    }
  }

  @include mobile {
    display: block;
    max-width: 460px;
    margin: 0 auto;
  }
}

.news-list-with-image__subtitle {
  @include text-lg;
  margin-top: var(--space-4);
}

.news-list-with-image--image-right .news-list-with-image__body {
  flex-direction: row-reverse;
  > * + * {
    margin-left: 0;
    margin-right: var(--space-80);
    @include mobile {
      margin-right: 0;
    }
  }
}

.news-list-with-image__image {
  max-width: 460px;
  width: 100%;
  margin-right: var(--space-20);
  position: relative;
  border-radius: var(--space-12);
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }

  &:after {
    @include mobile {
      content: '';
      position: absolute;
      background: rgba(0, 0, 0, .5);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.news-list-with-image--image-right .news-list-with-image__image {
  margin-right: 0;
}

.news-list-with-image__overlay {
  display: none;
  @include mobile {
    padding: var(--space-16);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    color: var(--white);
  }
}

.news-list-with-image__list-wrapper {
  width: 100%;
  box-sizing: content-box;
  @include mobile {
    padding: 0;
  }
}

.news-list-with-image__list {
  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .btn {
    @include mobile {
      width: 100%;
    }
  }
}

.news-list-with-image__title, .news-list-with-image__title a {
  @include as-h1u;
}

.news-list-with-image .view-rows {
  border-top: 1px solid var(--yellow);
  margin-top: var(--space-28);
  padding-top: var(--space-28);
  margin-bottom: var(--space-20);

  @include mobile {
    margin-top: var(--space-24);
    border: 0;
    padding-top: 0;
  }

  > * {
    border-bottom: 1px solid var(--light-grey);
    padding-bottom: var(--space-20);
  }

  > * + * {
    margin-top: var(--space-20);
  }
}
