.cruising-with-continental {
  .share-buttons {
    color: white;
    --facebook-icon-f-fill: transparent;
    @include mobile {
      color: var(--black);
    }
    .icon {
      font-size: var(--font-size-24);
    }
  }

  .rich-text {
    padding-top: var(--space-38);
    @include mobile {
      padding-top: 0;
    }
  }

  .marquee__content {
    @include mobile {
      padding-top: var(--space-20);
      padding-bottom: var(--space-20);
    }
  }

  .marquee__texts {
    display: flex;
    flex-direction: column;
    @include mobile {
      align-items: center;
    }
  }

  .marquee__title {
    max-width: 400px;
  }

  .marquee__text {
    max-width: 350px;
  }
}
