/*
---
name: Button
category: Elements
---

### Button styles
```colors.html
<button class="btn">White</button><br/><br/>
<button class="btn btn--border-black">White with black border</button><br/><br/>
<button class="btn btn--black">Black</button><br/><br/>
<button class="btn btn--yellow">Yellow</button><br/><br/>
<button class="btn btn--border-yellow">White with yellow border</button><br/><br/>
<button class="btn btn--blank">Blank button</button><br/><br/>
<button class="btn btn--blank btn--narrow">Blank paddingless button</button><br/><br/>

<div style="background-color: var(--black); display: inline-block; padding: 20px; margin-bottom: 20px;">
  <button class="btn btn--black btn--border-white">Black with white border</button><br/><br/>
  <button class="btn">White</button><br/><br/>
  <button class="btn btn--black btn--transparent">Transparent button</button><br/></br>
  <button class="btn btn--black btn--undecorated">Button without hover decoration</button><br/></br>
</div>
```

### Button as a block:
```block.html
<button class="btn btn--yellow btn--block">Button as block</button>
```

### Full-width button:
```fullwidth.html
<button class="btn btn--yellow btn--fullwidth">Button that occupies full width</button>
```

### Button with an icon:

#### with left icon
```with-left-icon.html
<button class="btn btn--black"><span class="icon"><svg height="11" viewBox="0 0 11 11" width="11" xmlns="http://www.w3.org/2000/svg"><path d="m6.5 1v11m-5.5-5.5h11" fill="none" stroke="#fff" stroke-linecap="round" transform="translate(-1 -1)"/></svg></span> <span>Button with left icon</span></button>
```

#### with right icon
```with-right-icon.html
<button class="btn btn--black"><span>Button with right icon</span> <span class="icon"><svg height="11" viewBox="0 0 11 11" width="11" xmlns="http://www.w3.org/2000/svg"><path d="m6.5 1v11m-5.5-5.5h11" fill="none" stroke="#fff" stroke-linecap="round" transform="translate(-1 -1)"/></svg></span></button>
```

### Disabled button
```disabled-button.html
<button disabled class="btn btn--black">Disabled button</button>
```

### Expand and collapse buttons
```button-expand.html
<button class="btn btn--expand btn--black"><span>Expand this section</span></button>
```
```button-collapse.html
<button class="btn btn--collapse btn--black"><span>Collapse this section</span></button>
```
*/
button > * {
  // Removes pointer events from any children of the button;
  // This makes button always the target of the click.
  pointer-events: none;
}

@mixin btnTextBase {
  display: inline-flex;
  justify-content: center;
  vertical-align: top;
  align-items: center;
  line-height: 1.4;
  font-size: var(--font-size-12);
  font-weight: bold;
  letter-spacing: var(--letter-spacing-1_25);
  text-transform: uppercase;
}

@mixin btn {
  @include hasIcon;
  @include btnTextBase;
  text-align: center;
  padding: var(--space-12) var(--space-26);
  border-width: 2px;
  border-style: solid;
  border-radius: 26px;

  background-color: var(--white);
  border-color: var(--white);
  color: var(--black);
  text-decoration: none;
  transition: all .4s ease-in-out;

  @include mobile {
    padding: var(--space-12) var(--space-20);
  }

  &:hover, &:focus {
    background-color: var(--grey);
    border-color: var(--grey);
    color: var(--black);
    background-image: none;
    cursor: pointer;
    text-decoration: none;
  }

  &:disabled, &:disabled:hover, &:disabled:focus {
    color: var(--dark-grey) !important;
    cursor: not-allowed !important;
    background-color: var(--lightest-grey) !important;
    border-color: var(--lightest-grey) !important;
  }

  sup {
    top: -0.25em;
  }
}

.btn {
  @include btn;
}

@mixin btn--sm {
  line-height: 1.2;
  padding: var(--space-8) var(--space-26);
}

.btn--sm {
  @include btn--sm;
}

.btn--undecorated {
  &:hover {
    border-color: transparent;
    background-color: transparent !important;
  }
}

@mixin btn--narrow {
  padding-left: 0;
  padding-right: 0;
}

.btn--narrow {
  @include btn--narrow;
}

.btn--blank {
  @extend .btn--undecorated;
  @extend .btn--transparent;
  border-color: transparent !important;
}

.btn--as-link {
  @extend .btn--blank;
  @extend .btn--narrow;

  &:hover {
    @include text-underline--yellow;
  }
}

.btn--blank.btn--ondark {
  color: var(--white);
}

.btn--block {
  display: block;
}

.btn--fullwidth {
  width: 100%;
}

.btn--ondark {
  &:disabled, &:disabled:hover, &:disabled:focus {
    background-color: var(--black) !important;
    border-color: var(--black) !important;
    color: var(--darkest-grey) !important;
  }
}

@mixin btn--border-white {
  border-color: var(--white);

  &:hover, &:focus {
    background-color: var(--white);
    border-color: var(--white);
    color: var(--black);
  }
}

.btn--border-white.btn--border-white {
  @include btn--border-white;
}

@mixin btn--text-white {
  color: var(--white);
}

.btn--text-white.btn--text-white {
  @include btn--text-white;
}

.hover\:btn--black.hover\:btn--black {
  &:hover, &:focus {
    background-color: var(--black);
    border-color: var(--black);
    color: var(--white);
  }
}

.hover\:btn--white.hover\:btn--white {
  &:hover, &:focus {
    background-color: var(--white);
    border-color: var(--white);
    color: var(--black);
  }
}

@mixin btn--black {
  background-color: var(--black);
  border-color: var(--black);
  color: var(--white);

  &:hover {
    background-color: var(--darkest-grey);
    border-color: var(--darkest-grey);
    color: var(--white);
  }
}

.btn--black {
  @include btn--black;
}

@mixin btn--dark-black {
  @include btn--black;
  background-color: #000000;
  border-color: #000000;
}

.btn--dark-black {
  @include btn--dark-black;
}


@mixin btn--border-black {
  border-color: var(--black);

  &:hover, &:focus {
    background-color: var(--black);
    border-color: var(--black);
    color: var(--white);
  }
}

.btn--border-black.btn--border-black {
  @include btn--border-black;
}

@mixin btn--yellow {
  background-color: var(--yellow);
  border-color: var(--yellow);
  color: var(--black);

  &:hover, &:focus {
    background-color: var(--light-yellow);
    border-color: var(--light-yellow);
    color: var(--black);
  }
}

.btn--yellow {
  @include btn--yellow;
}

.hover\:btn--yellow {
  &:hover, &:focus {
    background-color: var(--yellow);
    border-color: var(--yellow);
    color: var(--black);
  }
}

@mixin btn--border-yellow {
  border-color: var(--yellow);

  &:hover, &:focus {
    background-color: var(--yellow);
    border-color: var(--yellow);
    color: var(--black);
  }
}

.btn--border-yellow.btn--border-yellow {
  @include btn--border-yellow;
}

@mixin btn--border-red {
  border-color: var(--red);

  &:hover, &:focus {
    background-color: var(--red);
    border-color: var(--red);
    color: var(--white);
  }
}

.btn--border-red.btn--border-red {
  @include btn--border-red;
}

.btn--transparent {
  background-color: transparent;
}


@mixin btnWithBackgroundIcon {
  span {
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 22px;
  }
}

@mixin btnExpand {
  span {
    background-image: url("data:image/svg+xml,%3Csvg height='11' viewBox='0 0 11 11' width='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6.5 1v11m-5.5-5.5h11' fill='none' stroke='%23fff' stroke-linecap='round' transform='translate(-1 -1)'/%3E%3C/svg%3E");
  }
}

@mixin btnCollapse {
  span {
    background-image: url("data:image/svg+xml,%3Csvg height='2' viewBox='0 0 13 1' width='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1 7h11' fill='none' stroke='%23fff' stroke-linecap='round' transform='translate(0 -6)'/%3E%3C/svg%3E");
  }
}

.btn--expand {
  @include btnWithBackgroundIcon;
  @include btnExpand;
}

.btn--collapse {
  @include btnWithBackgroundIcon;
  @include btnCollapse;
}

.vertical-buttons {
  display: flex;
  flex-direction: column;
  text-align: center;

  > * + * {
    margin-top: var(--space-8);
  }
}

.editor-cta-wrapper {
  display: flex;
  justify-content: center;
}

* + .editor-cta-wrapper,
.editor-cta-wrapper + * {
  margin-top: var(--space-8);
}
