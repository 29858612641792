con-simple-stores-near {
  display: block;

  dl {
    display: flex;
    font-size: var(--font-size-15);
  }

  .icon__pin {
    --pin-fill: var(--yellow);
    --circle-fill: #000000;
  }
}

con-simple-stores-near[simplified] {
  dl {
    margin-bottom: var(--space-8);

    dt {
      width: 60px;
      margin-right: 10px;
      color: var(--yellow);
    }

    dd {
      font-weight: bold;
    }
  }
}

con-simple-stores-near:not([simplified]) {
  padding-bottom: 80px;

  @include mobile {
    padding-bottom: 0;
  }

  .simple-stores-near__columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;

    @include mobile {
      display: block;
    }
  }

  & div:nth-child(2) {

  }

  dl {
    @include mobile {
      @include regular-border-bottom;
      padding-bottom: var(--space-20);
      margin-bottom: var(--space-20);
    }
  }

  dt {
    margin-right: 10px;
    text-align: left;
    text-transform: uppercase;
    width: 80px;
    flex-shrink: 0;
    @include mobile {
      text-align: center;
      width: 65px;
    }

    div {
      text-align: center;
      display: inline-block;
      @include mobile {
        position: relative;
        top: 7px;
      }
    }

    span {
      font-size: var(--font-size-40);
      font-weight: normal;
      line-height: 1;
      display: block;
      color: var(--yellow);
      margin-bottom: var(--space-4);
      @include mobile {
        font-size: var(--font-size-30);
      }
    }
  }

  dd {
    ul {
      margin-top: var(--space-8);
    }

    li + li {
      margin-top: var(--space-8);
    }
  }


  address {
    text-transform: uppercase;
    font-size: var(--font-size-14);
    line-height: var(--line-height-22);
  }

  .btn {
    @include mobile {
      display: flex;
    }
  }

  .con-simple-stores-near__cta {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    @include mobile {
      position: static;
    }
  }
}

.simple-stores-near__title {
  text-transform: uppercase;
}

.simple-stores-near__table {
  margin-bottom: var(--space-8);
  display: table;
  table-layout: fixed;
  max-width: 605px;
  width: 100%;
  @include touch {
    display: block;
  }
}

.simple-stores-near__row {
  display: table-row;

  @include touch {
    display: grid;
  }

  > * {
    @include regular-border-bottom;
    display: table-cell;
    padding: var(--space-16) 0;

    @include touch {
      display: block;
      border-bottom: 0;
      padding: 0;
    }
  }

  &:last-child {
    > * {
      border-bottom: 0;
    }
  }

  > * + * {
    padding-left: var(--space-38);
    @include touch {
      padding-left: 0;
    }
  }

  > * + *:not(.simple-stores-near__title) {
    @include touch {
      margin-top: var(--space-12);
    }
  }

  @include touch {
    border-bottom: 0;
    grid-template-columns: 50px 1fr;
    grid-column-gap: var(--space-38);
    padding: var(--space-16) 0;
  }

  &:last-of-type {
    border-bottom: 0;
  }
}

.simple-stores-near__distance {
  color: var(--yellow);
  font-weight: bold;
  width: 60px;

  @include touch {
    grid-row: span 3;
  }
}

.simple-stores-near__content {
  display: flex;
}

.simple-stores-near__directions {
  width: 165px;
  box-sizing: content-box;
  @include touch {
    width: auto;
  }

}
