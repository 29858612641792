.tires-filter-form {
  $form-space: var(--space-20);

  & > *:not([type=submit]) {

    padding-bottom: $form-space;
    @include regular-border-bottom;
  }

  & > * + *:not([type=submit]) {
    margin-top: $form-space;
  }

  h2 {
    @include mobile {
      text-align: center;
    }
  }
}

.tires-filter-form legend {
  @include text-cta;
  display: table;
}

.tires-filter-form .fieldset-wrapper {
  margin-top: var(--space-12);
}

.tires-filter-form .field__type-checkbox {
  display: flex;
  align-items: center;

  label {
    @include text-small;
    font-weight: bold;
  }

  input {
    margin-right: var(--space-8);
  }
}

.tires-filter-form {
  .field__type-checkbox + .field__type-checkbox {
    margin-top: var(--space-8);
  }
}

.tires-filter-form__reset.tires-filter-form__reset {
  border-bottom: 0;
}

.tires-filter-form__submit {
  @include mobile {
    width: 100%;
  }
}
