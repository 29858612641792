.path-user .layout-content > article {
  @include container;
}

.user-login-form {
  max-width: 350px;
  margin: var(--space-60) auto;

  [type=submit] {
    @include btn;
    @include btn--yellow;
  }
}
