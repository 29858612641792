.site-search {
  @include very-dark-background;
}

.site-search__marquee {
  padding: var(--space-38) 0 var(--space-16) 0;

  con-field[type="textfield"] input {
    background-color: var(--white);
  }

  con-field[type="textfield"] label {
    @include visually-hidden;
  }
}

.site-search__header {
  width: 100%;

  > * + * {
    margin-top: var(--space-38);
  }
}

.site-search__form-wrapper {
  form {
    position: relative;

    .field__name-sort-by {
      display: none;
    }

    con-field {
      padding-top: 1px;
    }

    con-field[floatlabel][is-empty]:not([is-focused]) label {
      top: 25px;
    }

    [type=text] {
      padding-top: 18px;
      padding-bottom: 18px;
      border-color: #969696;
    }

    [type=submit] {
      @include btn;
      @include btn--yellow;
      position: absolute;
      top: 5px;
      right: 5px;

      @include middle {
        .icon {
          display: none;
        }
      }

      @include mobile {
        width: 44px;
        height: 44px;
        padding: 0;
        span:not(.icon) {
          display: none;
        }
        .icon {
          position: relative;
          margin-left: 0 !important;
        }
      }
    }
  }
}

.site-search__sort-by {
  label {
    text-transform: capitalize;
  }

  select {
    padding: 0;
  }
}

.site-search__status {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-left: var(--space-4);
  padding-bottom: var(--space-16);

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

}

con-custhelp-search[hasresults] + .site-search__container .site-search__status {
  padding-top: var(--space-80);
  @include mobile {
    padding-top: var(--space-38);
  }
}

.site-search__container {
  max-width: 970px;
}

.site-search__results {
  > * + * {
    margin-top: var(--space-16);
  }
}

.site-search__results + .pager-with-summary {
  margin-top: var(--space-20);
}

.site-search__results-wrapper {
  @include dark-background;
  padding-top: var(--space-38);
  padding-bottom: var(--space-20);
  @include mobile {
    padding-top: var(--space-20);
    padding-bottom: var(--space-20);
  }
}

con-custhelp-search[hasresults] .site-search__results-wrapper {
  @include dark-background;
}

.site-search__no-results {
  text-align: center;
  padding: var(--space-60) 0;

  @include mobile {
    padding: var(--space-20) 0;
  }

  > * + * {
    margin-top: var(--space-28);
    @include mobile {
      margin-top: var(--space-20);
    }
  }

  h2 {
    font-size: var(--font-size-50);
    line-height: var(--font-size-60);
    font-weight: 300;
    max-width: 16ch;
    margin: 0 auto;
    @include mobile {
      @include as-h2;
    }
  }
}

.search-sort-by-form {
  min-width: 130px;
  con-select {
    color: var(--white);
  }
}
