.tire-search-options-tabs {
  text-align: center;
}

.tire-search-options-tabs--has-value {
  &:after {
    background: var(--dark-yellow);
    width: 100%;
  }
}

.tire-search-options-tab {
  min-width: 170px;
  //padding-left: var(--space-45);
  //padding-right: var(--space-45);
  overflow: hidden;
  text-overflow: ellipsis;
  @include mobile {
    min-width: 100px;
  }
}

.tire-search-options-tab[selected] {
  &:after {
    background: var(--yellow);
  }
}

.tire-search-options-tab + .tire-search-options-tab {
  margin-left: var(--space-20);
}
