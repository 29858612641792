.rtp-marquee {
  padding: var(--space-60) 0;
  @include mobile {
    padding: var(--space-45) 0 var(--space-20) 0;
  }
}

.rtp-marquee__container {
  display: flex;
  justify-content: space-between;
  @include mobile {
    display: block;
  }
}

.rtp-marquee__left {
  max-width: 535px;
  margin-right: var(--space-32);
  @include mobile {
    margin: 0 auto var(--space-20) auto;
    text-align: center;
  }

  > * + * {
    margin-top: var(--space-20);
    @include mobile {
      margin-top: var(--space-8);
    }
  }
}

.rtp-marquee__right {
  max-width: 420px;
  @include mobile {
    margin: 0 auto;
    max-width: 495px;
  }
}

.rtp-marquee__title {
  @include as-h1;
}

.rtp-marquee__text {
  @include text-lg;
  @include mobile {
    @include text-base;
  }
}

.rtp-marquee__card {
  @include regular-shadow;
  display: flex;
  align-items: flex-start;

  border-radius: 0.75rem;
  padding: var(--space-20) var(--space-12) var(--space-20) var(--space-26) ;

  @include mobile {
    padding: var(--space-20) var(--space-28);
  }

  & > * + * {
    margin-left: var(--space-12);
  }

  a {
    display: inline-block;
  }

  .icon {
    font-size: var(--font-size-40); // Icon is larger that a standard.
    flex-shrink: 0;
    margin-top: var(--space-8);
  }

  .text-content {
    > * + * {
      margin-top: var(--space-8);
    }
  }
}
