.check-now-button {
  @extend .link-button;

  > .icon:last-child:not(:first-child) {
    margin-left: 0.25em;
  }

  @include mobile {
    align-items: center;
    text-align: right;
  }
}
