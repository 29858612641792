con-details {
  .con-details__toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: var(--space-8);
    }
  }

  & > *:nth-child(2) {
    display: block;
    margin-top: 20px;
  }
}

con-details > h2 {
  min-height: 40px;
  display: flex;
  align-items: center;
}

con-details[mobileonly] {
  .con-details__toggle {
    @include middle {
      cursor: default;
    }
  }
}

con-details[connected]:not([open]):not([mobileonly]) {
  & > *:nth-child(2) {
    display: none;
  }
}

con-details[connected]:not([open])[mobileonly] {
  & > *:nth-child(2) {
    @include mobile {
      display: none;
    }
  }
}

con-details[righthanded] .con-details__toggle {
  justify-content: space-between;
}
