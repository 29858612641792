.partners__grid {
  > * + * {
    margin-top: var(--vertical-gap);
  }
  .view-rows {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--space-20);
    @include mobile {
      grid-template-columns: 1fr;
    }
  }
}
