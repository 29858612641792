.stores-near-block {
  @include very-dark-background;

  padding-top: var(--space-80);
  padding-bottom: var(--space-80);

  @include mobile {
    padding-top: var(--space-38);
    padding-bottom: var(--space-38);
  }

  & > * {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 100px;

    @include mobile {
      display: block;
      & > * + * {
        margin-top: var(--space-38);
      }
    }
  }
}

.stores-near-block__title {
  @include as-h2;
  font-weight: 300;
  @include mobile {
    @include as-h3;
  }
}

.stores-near-block__container {
  position: relative;
  --container-padding: var(--space-38);
  @include mobile {
    --container-padding: var(--space-20);
  }
}
