.tire-best-for__wrapper {
  @include mobile {
    display: flex;
    justify-content: center;
  }

  > * + * {
    @include mobile {
      margin-left: var(--space-16);
    }
  }
}

.tire-best-for--inline {
  flex-wrap: wrap;

  @include mobile {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: var(--space-16);
  }

  .tire-best-for__label {
    @include text-cta;
    @include mobile {
      display: none;
    }
  }

  .item-list__item {
    margin-right: var(--space-16);
    @include mobile {
      margin-right: 0;
    }
    con-tooltip {
      @include mobile {
        img[slot=target] {
          margin: 0;
        }
      }
    }
  }

  .item-list__item + .item-list__item {
    margin-left: 0;
  }
}

