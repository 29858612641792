@import "elements/list";

/**
 * Handling Long Words and URLs (Forcing Breaks, Hyphenation, Ellipsis, etc)
 * https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
 */
@mixin dont-break-out {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}

.dont-break-out {
  @include dont-break-out;
}


/*
---
name: Text styles
category: Typography
---

```text-styles.html
<p><b>Bold text</b></p>
<p><b>Strong text</b></p>
<p><small>Small text</small></p>
```
*/
b,
strong {
  font-weight: bold;
}

small {
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-0_5);
  font-weight: bold;
}

sup {
  font-size: 0.6em;
  display: inline-block; // We don't need underline below TM on a:hover, setting display to inline-block solves that.
}

/*
---
name: Paragraphs
category: Typography
---

```single.html
<p>A single paragraph</p>
```

```consecutive.html
<p>A paragraph</p>
<p>Followed by another parahraph</p>
```

*/
p + p {
  margin-top: var(--s1);
}

/*
---
name: Headings
category: Typography
---

### H1 - H6
```headings.html
<h1>I'm an H1 heading</h1>
<h2>I'm an H2 heading</h2>
<h3>I'm an H3 heading</h3>
<h4>I'm an H4 heading</h4>
<h5>I'm an H5 heading</h5>
<h6>I'm an H6 heading</h6>
```

### Other styles
##### Subtle heading
```subtle.html
<h1 class="subtle-heading">I'm a subtle heading</h1>
<h1 class="subtle-heading subtle-heading--yellow-right-arrow">I'm a subtle heading with right yellow arrow</h1>
```
*/

@mixin text-letterspace {
  letter-spacing: 6px;
}

@mixin as-h1 {
  font-size: var(--font-size-42);
  line-height: var(--line-height-48);
  font-weight: 300;

  @include touch {
    font-size: var(--font-size-30);
    line-height: var(--line-height-36);
  }
}

h1,
.as-h1 {
  @include as-h1;
}

@mixin as-h1u {
  @include as-h1;
  @include text-letterspace;
  text-transform: uppercase;
}

.as-h1u {
  @include as-h1u;
}

@mixin as-h2 {
  font-size: var(--font-size-30);
  line-height: var(--line-height-38);
}

h2,
.as-h2 {
  @include as-h2;
}

@mixin as-h3 {
  font-size: var(--font-size-24);
  line-height: var(--line-height-32);
}

h3,
.as-h3 {
  @include as-h3;
}

@mixin as-h4 {
  font-size: var(--font-size-20);
  line-height: var(--line-height-24);
  font-weight: bold;

  @include touch {
    font-size: var(--font-size-14);
    line-height: var(--line-height-20);
    letter-spacing: var(--letter-spacing-0_5);
    font-weight: bold;
  }
}

h4,
.as-h4 {
  @include as-h4;
}

@mixin as-h5 {
  font-size: var(--font-size-16);
  line-height: var(--line-height-22);
  letter-spacing: var(--letter-spacing-0_25);
  font-weight: bold;
}

h5,
.as-h5 {
  @include as-h5;
}

@mixin as-h6 {
  font-size: var(--font-size-14);
  line-height: var(--line-height-20);
  letter-spacing: var(--letter-spacing-0_5);
  font-weight: bold;
}

h6,
.as-h6 {
  @include as-h6;
}

@mixin subtle-heading {
  font-size: var(--font-size-14);
  font-weight: normal;
  line-height: var(--line-height-20);
  letter-spacing: var(--letter-spacing-0_5);
  text-transform: uppercase;
  color: var(--black);
}

.subtle-heading {
  @include subtle-heading;
}

@mixin subtle-heading-with-yellow-right-arrow {
  &:after {
    content: '';
    width: 1rem;
    height: 1rem;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='7px' height='9px' viewBox='0 0 7 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='NAV' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Nav_TiresExpanded_M' transform='translate(-686.000000, -226.000000)' stroke='%23FFA500' stroke-width='2'%3E%3Cg id='tires_expanded' transform='translate(-0.000000, 184.000000)'%3E%3Cg id='performance' transform='translate(518.000000, 40.000000)'%3E%3Cg id='arrow-/-gallery-R' transform='translate(169.000000, 3.000000)'%3E%3Cpolyline transform='translate(2.000000, 3.500000) scale(1, -1) translate(-2.000000, -3.500000) ' points='-4.54569715e-13 0 4 3.5 -4.54569715e-13 7'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: var(--space-12);
    position: relative;
    top: -1px;
  }
}

.subtle-heading--yellow-right-arrow {
  @include subtle-heading-with-yellow-right-arrow;
}

@mixin section-title {
  @include as-h1u;
}

.section-title {
  @include section-title;
}

/*
---
name: Body text styles
category: Typography
---

```text-styles.html
<small>I'm a small text</small>
<p class="text-xl">I'm an XL text</p>
<p class="text-lg">I'm a large text</p>
<p class="text-base">I'm a base body text</p>
<p class="text-small">I'm a small body text</p>
<p class="text-cta">I'm a CTA text</p>
```
*/
@mixin text-xl {
  font-size: var(--font-size-24);
  line-height: var(--line-height-34);
}

.text-xl {
  @include text-xl;
}

@mixin text-lg {
  font-size: var(--font-size-18);
  line-height: var(--line-height-26);
}

.text-lg {
  @include text-lg;
}

@mixin text-base {
  font-size: var(--font-size-15);
  line-height: var(--line-height-22);
}

.text-base {
  @include text-base;
}

@mixin text-small {
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
}

.text-small {
  @include text-small;
}

.text-cta {
  @include text-cta;
}

a.text-cta,
button.text-cta {
  &:hover {
    @include text-underline--yellow;
  }
}

.text-cta.has-icon {
  .icon {
    font-size: var(--font-size-16);
  }
}

/*
---
name: Textual content
category: Typography
---
Wrap text into an element with `text-content` class to get default
text styles.

```textual-content.html
<section class="text-content">
  <p>
    Finance your tire purchase with the Continental Tire
    Synchrony Car Care credit card. Plus, use it for routine
    maintenance, gas, parts and more.
  </p>
  <ul class="checkmark-list">
    <li>No Annual Fee*</li>
    <li>$0 Fraud Liability</li>
    <li>Secure online account access</li>
  </ul>
  <p>
    Apply in-store or <a href=""><b>online</b></a> and get a
    quick response
  </p>
  <p class="text-small">
    *For new accounts: Purchase APR is 29.99%; Minimum interest
    charge is $2
  </p>
</div>
```
*/
@mixin text-content {
  > * + *, li > * + * {
    margin-top: 1.4em;
  }

  > h2 + *, h3 + * {
    margin-top: 0.6em;
  }

  * + blockquote {
    margin-top: 0.6em;
  }

  h2 {
    @include mobile {
      font-size: var(--font-size-20);
    }
  }

  h3 {
    font-weight: bold;
    font-size: var(--font-size-20);
    @include mobile {
      line-height: var(--line-height-24);
    }
  }

  ul, ol {
    @include list-base;
  }

  ul {
    list-style: disc;
  }

  hr {
    color: var(--grey);
  }

  small {
    display: inline-block;
  }

  a {
    @include dont-break-out;
  }

  table {
    @include mobile {
      width: 100%;
    }

    caption {
      font-family: var(--font-family-bold);
      font-size: var(--font-size-14);
      color: var(--white);
      background-color: var(--black);
      padding: var(--space-8);
    }

    th {
      background-color: var(--light-grey);
      text-transform: uppercase;
      font-size: var(--font-size-12);
      letter-spacing: 1px;
    }

    th, td {
      border: 1px solid var(--grey);
      padding: var(--space-8);
    }

    td {
      font-size: var(--font-size-14);
      line-height: var(--line-height-22);
    }
  }
}

.text-content {
  @include text-content;
}

@mixin article-content {
  @include text-content;
  max-width: 79ch;

  table:not([width]) {
    @include wide {
      width: 134%;
      max-width: 747px;
      margin-left: -17%;
      margin-right: -17%;
    }
  }

  hr {
    color: var(--yellow);
    margin-top: var(--space-38);
    margin-bottom: var(--space-38);
  }

  * + h1,
  * + h2,
  * + h3,
  * + h4 {
    margin-top: var(--space-45);
  }

  h5 {
    text-transform: uppercase;
  }

  * + figure[role=group],
  * + .media--embed,
  {
    margin-top: var(--space-60);
    @include mobile {
      margin-top: var(--space-38);
    }
  }

  figure[role=group] + *,
  .media--embed + *:not(figcaption),
  .small-embeds-grid + p {
    margin-top: var(--space-38);
  }

  .media--embed, .media--small-embed {
    img {
      border-radius: var(--space-12);
    }
  }

  a {
    font-weight: bold;
  }

  ol {
    margin-left: var(--space-45) !important;
    @include mobile {
      margin-left: var(--space-24) !important;
    }
  }

  ul {
    margin-left: var(--space-34) !important;
    @include mobile {
      margin-left: var(--space-24) !important;
    }
  }

  ol:not(.headings-list) {
    list-style: none;
    counter-reset: li;

    li {
      counter-increment: li;
      position: relative;
    }


    li::before {
      content: counter(li) ". ";
      color: var(--dark-yellow);
      display: inline-block;
      font-weight: bold;
      position: absolute;
      width: 1em;
      height: 1em;
      left: -1.5em;
    }

    &[type=a] {
      li:before {
        content: counter(li, lower-alpha) ". ";
      }
    }
  }

  ul {
    @include reqular-list;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.text-letterspace {
  @include text-letterspace;
}

.text-color-yellow {
  color: var(--yellow);
}

.text-underline {
  text-decoration: underline;
}

.text-centered {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}

.text-lowercase {
  text-transform: lowercase;
}

.article-content {
  @include article-content;
}

