#block-nextcontinental-local-tasks {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1000;

  .contextual-links {
    display: none;
  }

  ul {
    display: flex;
  }

  a {
    padding: var(--space-4) var(--space-8);
    background-color: var(--light-yellow);

    &.is-active {
      color: var(--black);
      background-color: var(--yellow);
      font-weight: bold;
    }
  }
}
