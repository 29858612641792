.tire-promo-cardlist {
  > * + * {
    margin-top: var(--space-20);
  }
}

.tire-promo-cardlist__title {
  font-size: var(--font-size-32);
  line-height: var(--line-height-34);

  @include mobile {
    font-size: var(--font-size-24);
  }
}

.tire-promo-cardlist__item {
  @include regular-border-bottom;
  padding-bottom: var(--space-16);

  @include mobile {
    text-align: center;
  }

  &:last-child {
    border-bottom: 0;
  }

  > * + * {
    margin-top: var(--space-16);
  }
}

.tire-promo-cardlist__links {
  display: block;
  column-count: 2;
  column-gap: 20px;

  @include mobile {
    column-count: unset;
  }

  > * + * {
    margin-top: var(--space-12);
  }

  li {
    &.has-icon > .icon:first-child:not(:last-child) {
      margin-right: 0.25em;
    }
  }
}

.tire-promo-cardlist__link {
  font-size: var(--font-size-20);
  line-height: var(--line-height-34);
  font-weight: bold;
}
