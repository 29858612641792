.news-category-page-list {
  display: block;
  padding: var(--space-80) 0;
  @include mobile {
    padding: var(--space-38) 0;
  }
}

.news-category-page-list--dark {
  @include dark-background;
}

.news-category-page-grid-2 {
  .view-rows {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--space-60) var(--space-38);
    @include mobile {
      grid-template-columns: 1fr;
      grid-row-gap: var(--space-32);
    }
  }

  > * + * {
    margin-top: var(--space-60);
  }

  .load-more-pager {
    margin-top: var(--space-50);
    @include mobile {
      margin-top: var(--space-28);
    }
  }
}

.news-category-page-grid-3 {
  .view-rows {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--space-60) var(--space-20);
    @include mobile {
      grid-template-columns: 1fr;
      grid-row-gap: var(--space-20);
    }
  }

  > * + * {
    margin-top: var(--space-60);
  }
}

/**
 * Animations
 */
.news-category-page-grid-2--animated,
.news-category-page-grid-3--animated {
  .view-rows article {
    @include animated;
    @include animated--fadeInUp;
    @include animated-staggered(10);
  }

  .load-more-pager {
    @include animated;
    @include animated--fadeInUp;
    @include animated-order(8);
  }
}
