con-media-carousel {
  display: block;
  height: 100vh;

  .con-media-carousel__main {
    .splide__slide {
      min-height: 50vh;
    }
  }
}

con-media-carousel .con-media-carousel__main {
  .splide__slide {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    img {
      display: block;
      margin: 0 auto;
      height: auto;
      max-width: 100%;
    }
  }
  .splide__arrow {
    background: none;
    span {
      font-size: 2.5rem;
    }
    @include mobile {
      display: none;
    }
  }
}

con-media-carousel[data-items-count="1"] {
  margin: 0 -20px;
  @media screen and (max-width: 932px) and (orientation: landscape) {
    .con-media-carousel__main {
      .splide__slide {
        min-height: 70vh;
        min-height: 70dvh;
      }
      .splide__video__wrapper {
        height: 70vh;
        height: 70dvh;
      }
    }
  }
}

con-media-carousel .con-media-carousel__thumbs {
  margin-top: 60px;
  .splide__list {
    justify-content: center;
  }
  .splide__slide.splide__slide {
    border: 0;
    &.is-active {
      border: 0;
      opacity: 0.5;
    }
  }
}
