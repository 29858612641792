.banner {
  padding: var(--space-80) 0;
  @include mobile {
    padding: var(--space-38) 0;
  }
}

.banner--dark {
  @include very-dark-background;
  @include mobile {
    .btn {
      width: 100%;
    }
  }
}

.banner__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  > * + * {
    margin-top: var(--space-16);
  }

  .btn {
    @include mobile {
      width: 100%;
    }
  }
}

.banner__download {
  @include text-cta;
}
