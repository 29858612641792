.tire-seller-banner {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;

  b {
    display: block;
    margin-right: var(--space-12);
    flex-shrink: 0;
  }

  svg {
    max-width: 180px;
    width: 100%;
    height: auto;
  }

  p {
    margin-top: var(--space-4);
  }
}

.tire-seller-banner__grid {
  width: 100%;
  flex-shrink: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-items: center;
  justify-content: flex-start;

  > * {
    max-width: 33.333333333%;
    display: block;
    margin-bottom: var(--space-16);
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }
}

.tire-seller-banner__item {
  img {
    max-width: 100%;
    height: auto;
  }
}

.tire-seller-banner--big {
  display: block;

  .tire-seller-banner__grid {
    justify-content: center;
  }

  b {
    display: block;
    margin-bottom: var(--space-8);
  }

  svg {
    max-width: 245px;
    width: 100%;
    height: auto;
  }
}
