con-tire-specs {
  display: block;
}

.con-tire-specs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--space-38);

  > * + * {
    margin-top: var(--space-24);
  }

  con-tire-variants-select {
    display: block;
    max-width: 400px;
    width: 100%;
    margin-top: var(--space-38);
  }
}

.con-tire-specs__match-status-message {
  @include as-h3;
  text-align: center;
  font-weight: 300;
  @include mobile {
    font-size: var(--font-size-18);
  }
}

.con-tire-specs__panel {
  background-color: var(--white);
  padding: var(--space-38) var(--space-20) var(--space-20) var(--space-20);
  color: var(--black);
  border-radius: 10px;
  width: 100%;

  @include mobile {
    padding: 0 var(--space-20) var(--space-20) var(--space-20);
  }

  @media print {
    padding-top: var(--space-12);
  }
}

.con-tire-specs__main {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 40px;
  text-align: center;
  margin-bottom: var(--space-26);

  @include mobile {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-column-gap: 0;
    border-bottom: 1px solid #ccc;
  }

  li > * {
    display: block;
  }

  li {

    b {
      font-weight: normal;
      text-transform: capitalize;
      @include as-h6;
      margin: var(--space-4) 0 var(--space-8) 0;
      @include mobile {
        margin-bottom: var(--space-4);
      }
    }

    i {
      font-style: normal;
      font-size: var(--font-size-50);
      color: var(--dark-yellow);
      line-height: 1;
    }

    span {
      @include text-small;
      text-transform: uppercase;
      font-weight: bold;
    }

    @include mobile {
      flex-basis: 33%;
      padding: var(--space-16) 0;
      i {
        font-size: var(--font-size-30);
      }
    }
  }

  li:nth-child(1), li:nth-child(2), li:nth-child(3) {
    @include mobile {
      border-bottom: 1px solid #ccc;
    }
  }
}

.con-tire-specs__details {
  text-align: center;
  @include mobile {
    margin-bottom: 0;
  }

  .btn {
    @include mobile {
      width: 100%;
    }
  }
}

.con-tire-specs__details-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: var(--space-38);
  margin: var(--space-26) 0;

  @include touch {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: var(--space-12);
    margin-bottom: var(--space-16);
  }

  > div {
    @include regular-border-top;
    @include touch {
      &:nth-child(2) {
        border-top: 0;
      }
    }
  }

  dl {
    @include regular-border-bottom;
    display: flex;
    justify-content: space-between;
    padding: var(--space-4) 0;
    @include touch {
      padding: var(--space-4) 0;
    }
    @include mobile {
      flex-direction: column;
      text-align: left;
    }
  }

  dt {
    @include as-h6;
    text-align: left;
  }

  dd {
    @include text-base;
  }
}

.con-tire-specs__details-list--collapsed {
  display: none !important;
}

.con-tire-specs__rpm {
  grid-column: 2;
  @include touch {
    grid-column: initial;
  }
}

.con-tire-specs__utqg {
  grid-column: 3;
  @include touch {
    grid-column: initial;
  }
}

.con-tire-specs__details-buttons {
  @include space-x(var(--space-20));
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include mobile {
    justify-content: center;
  }
}
