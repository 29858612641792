$tire-promo-sections-padding: var(--space-80);
$tire-promo-sections-mobile-padding: var(--space-38);

.tire-promo-page .marquee__content {
  min-height: 400px;
  @include touch {
    min-height: auto;
  }
}

/**
 * Sections stack
 */
.tire-promo-sections-stack {
  & > * {
    padding: $tire-promo-sections-padding 0;
    @include mobile {
      padding: $tire-promo-sections-mobile-padding 0;
    }
  }

  .tire-promo-tire-cards {
    padding: 0 0 var(--space-8) 0;
  }

  .tire-promo-details {
    @include mobile {
      padding-top: var(--space-28);
    }
  }
}

.tire-promo-sections-stack--variant {
  & > * {
    padding: var(--space-60) 0;
    @include mobile {
      padding: $tire-promo-sections-mobile-padding 0;
    }
  }

  .tire-promo-details {
    @include mobile {
      padding-bottom: var(--space-28);
    }
  }
}

/**
 * Marquee
 */
.tire-promo-marquee {
  position: relative;

  @include touch {
    background-color: var(--white);
  }

  .marquee__text {
    @include middle {
      letter-spacing: var(--letter-spacing-1);
    }
  }

  .marquee__cta {
    margin-top: var(--space-12);
    @include touch {
      margin-top: var(--space-4);
    }
  }

  .marquee__buttons {
    @include touch {
      width: 100%;
      margin-top: var(--space-12);
    }
  }

  .marquee__buttons .btn {
    @include touch {
      @include btn--yellow;
    }
  }
}

.tire-promo-marquee__jump-link {
  position: absolute;
  left: calc(50% - 10px);
  bottom: 10px;

  @include touch {
    bottom: calc(-1 * var(--space-4));
  }

  .icon__arrow-down-outline {
    @include middle {
      stroke-width: 1;
    }

    svg {
      font-size: 28px;
    }
  }
}

/**
 * Columns
 */
.tire-promo-columns {
  --gap: var(--space-32);
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--gap) / -2);
  @include mobile {
    margin: 0;
    display: block;
  }

  & > * {
    margin: 0 calc(var(--gap) / 2);
    @include mobile {
      margin: var(--space-8) 0 var(--space-28) 0;
    }
  }

  .tire-promo-columns__main {
    flex-basis: 0;
    flex-grow: 999;
    min-width: calc(50% - var(--gap));

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > * + * {
      margin-top: var(--space-12);
    }
  }

  .tire-promo-columns__aside {
    flex-basis: 340px;
    flex-grow: 1;
  }
}

/**
 * Social buttons bar
 */
.tire-promo-share-button-bar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @include mobile {
    justify-content: center;
  }

  > * + * {
    margin-left: var(--space-16);
  }
}

.tire-promo-share-button-bar--centered {
  justify-content: center;
}

/**
 * Tire promo content area
 */
.tire-promo-content-area {
  @include mobile {
    text-align: center;
    margin: 0 auto;
  }

  > * + * {
    margin-top: var(--space-20);
  }
}

.tire-promo-content-area--centered {
  text-align: center;
  margin: 0 auto;
}

/**
 * Tire promo text
 */
.tire-promo-text {
  @include text-xl;
  @include mobile {
    @include text-base;
  }
}

/**
 * Details
 */
.tire-promo-details__content {
  max-width: 64ch;
}

.tire-promo-details__content--wide {
  max-width: 80ch;
}



.tire-promo-details__list {
  @include mobile {
    display: flex;
    justify-content: space-around;
  }

  li + li {
    margin-top: var(--space-20);
    @include mobile {
      margin-top: 0;
    }
  }

  svg {
    @include mobile {
      flex-shrink: 0;
    }
  }

  a span {
    @include mobile {
      word-spacing: 8ch;
      max-width: 100px;
    }
  }
}

.tire-promo-columns__aside .tire-promo-details__list {
  @include mobile {
    margin-top: var(--space-28);
  }
}

.tire-promo-details__logo svg {
  font-size: var(--font-size-60);
  @include mobile {
    font-size: var(--font-size-50);
  }
}

/**
 * Tire cards
 */

/**
 * How To
 */
.tire-promo-howto {
  @include dark-background;
  position: relative;
  @include mobile {
    padding-bottom: var(--space-16);
  }
}

.tire-promo-howto--no-bottom-padding {
  padding-bottom: 0; // Reset sections padding to apply it individually to .tire-promo-howto__aside.
}

.tire-promo-howto--has-gradient {
  background: linear-gradient(180deg, #FFFFFF 70%, #F5F5F5 100%);
}

.tire-promo-howto__title {
  @include as-h1u;
  text-align: center;
  margin-bottom: var(--space-38);
  @include mobile {
    margin-bottom: var(--space-16);
  }
}

.tire-promo-page--multi .tire-promo-howto__title {
  margin-top: var(--space-20);
  @include mobile {
    margin-top: var(--space-4);
  }
}

.tire-promo-howto__content {
  max-width: 71ch;

  // Prevent tooltip from being covered.
  position: relative;
  z-index: 1;
}

.tire-promo-howto__aside {
  //@include text-base;

  & > * + * {
    margin-top: var(--space-38);
  }

  & > * + p {
    margin-top: var(--space-20);
  }
}

.tire-promo-howto--no-bottom-padding .tire-promo-howto__aside {
  padding-bottom: $tire-promo-sections-padding;

  @include mobile {
    padding-bottom: 0;
  }
}

.tire-promo-howto__instructions {
  @include mobile {
    text-align: center;
  }

  .btn {
    @include mobile {
      display: block;
      width: 100%;
    }
  }

  p {
    @include mobile {
      padding-left: var(--space-8);
      padding-right: var(--space-8);
    }
    b {
      display: inline-block; // We want bold text to not break.
    }
  }

  & > * + * {
    margin-top: var(--space-12);
  }

  a:not(.btn) b {
    &:hover, &:focus {
      @include text-underline--yellow;
    }
  }
}

.tire-promo-howto__instructions-header {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 1.56px;
  text-transform: uppercase;
  font-weight: bold;
}

.tire-promo-howto__questions {
  @include regular-shadow;
  color: var(--black);
  background-color: #fff;
  display: flex;
  max-width: 330px;
  border-radius: 0.75rem;
  padding: var(--space-22) var(--space-12) var(--space-20) var(--space-26) ;

  .icon {
    font-size: var(--font-size-40);
  }

  @include mobile {
    padding: var(--space-24);
    max-width: none;
  }

  & > * + * {
    margin-left: var(--space-16);
  }

  a {
    display: inline-block;
  }

  svg.icon {
    font-size: var(--font-size-40); // Icon is larger that a standard.
  }
}

.tire-promo-howto__tire_decoration {
  position: relative;
  left: -90px;
  width: calc(100% + 120px); // The image should be wider than container by 120px to compensate left negative.
  max-width: 800px; // The image can't be wider than actual image size.
  height: auto;
  @include mobile {
    display: none;
  }
}

.tire-promo-howto__tire_decoration--mobile {
  display: none;
  @include mobile {
    display: block;
    left: -25px;
    width: calc(100% + 50px);
    margin-top: var(--space-38);
  }
}

.tire-promo-howto__note {
  @include text-small;
  letter-spacing: var(--letter-spacing-0_5);
  font-style: italic;
  max-width: 330px;

  @include mobile {
    max-width: none;
  }
}

/**
 * Qualifying tires
 */
.tire-promo-qualifying-tires {
  @include regular-border-top;
  margin-top: var(--space-28);
  padding-top: var(--space-34);

  @include mobile {
    margin-bottom: var(--space-12);
  }

  > * + * {
    margin-top: var(--space-24);
  }
}

.tire-promo-qualifying-tires__title {
  @include subtle-heading;
}

.tire-promo-qualifying-tires__list {
  column-count: 2;
  column-gap: 40px;
  @include mobile {
    column-count: unset;
  }

  > * + * {
    margin-top: var(--space-12);
  }

  li {
    &.has-icon > .icon:first-child:not(:last-child) {
      margin-right: 0.25em;
    }
  }
}

.tire-promo-qualifying-tires__name {
  @include as-h5;
  font-size: var(--font-size-15);
}


/**
 * Terms & conditions
 */
.tire-promo-tos {
  padding-top: var(--space-38);

  @include mobile {
    //margin-top: calc(-1 * var(--space-60));
    z-index: 1;
    position: relative;
  }

  > * > * + * {
    margin-top: var(--space-32);
    @include mobile {
      margin-top: var(--space-38);
    }
  }
}

.tire-promo-tos__title {
  @include as-h6;
  text-align: center;
  margin-bottom: var(--space-8);
}

.tire-promo-tos__content {
  margin: 0 auto;
}
