/*
---
name: Button bar
category: Components
---

```button-bar.html
<div class="button-bar">
  <button class="btn btn--border-black">One</button>
  <button class="btn btn--border-black">Two</button>
</div>
```

## Bar with centered buttons
```button-bar-centered.html
<div class="button-bar button-bar--centered">
  <button class="btn btn--border-black">One</button>
  <button class="btn btn--border-black">Two</button>
</div>
```
 */
@mixin button-bar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @include mobile {
    display: block;
  }

  @include touch {
    justify-content: center;
  }

  > * + * {
    margin-left: var(--space-20);
    @include mobile {
      margin-top: var(--space-12);
    }
  }

  > * {
    @include mobile {
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.button-bar {
  @include button-bar;

}

.button-bar--centered {
  justify-content: center;
}
