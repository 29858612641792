/*
---
name: Form field
category: Fields
---
# Field states

## is success
```textinput-success.html
<br/>
<con-field floatlabel success-status class="field__type-textfield field__name-name">
  <label for="edit-name">First name</label>
  <input type="text" id="edit-name" name="name" value="" size="60" maxlength="60">
  <div class="field__description">Enter your first name.</div>
</con-field>
```

## has error
```textinput-error.html
<br/>
<con-field floatlabel error-status class="field__type-textfield field__name-name">
  <label for="edit-name">First name</label>
  <input type="text" id="edit-name" name="name" value="" size="60" maxlength="60">
  <div class="field__description">Enter your first name.</div>
</con-field>
```
*/

/**
 * Base.
 */
con-field {
  --input-padding-x: var(--space-20);
  position: relative;
  display: block;

  label {
    text-transform: uppercase;
    @media print {
      --con-field-float-label-color: var(--black);
      color: var(--con-field-float-label-color) !important;
    }
  }

  .field__description {
    @include text-small;
    margin-left: var(--input-padding-x, var(--space-26));
    margin-top: var(--space-8);
  }

  .field__description a, .field__description__link {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }
}


/**
 * States.
 */
con-field[success-status]:not([is-empty]) {
  &[type=textfield],
  &[type=tel],
  &[type=email],
  &[type=select],
  &[type=radios],
  &[type=checkboxes],
  &[type=textarea] {

    //@include mobile {
    //  padding-right: 30px;
    //}
    //
    //&:after {
    //  content: '';
    //  position: absolute;
    //  top: var(--space-12);
    //  right: -30px;
    //  width: 16px;
    //  height: 16px;
    //  background: url("data:image/svg+xml,%3Csvg height='12' viewBox='0 0 14 12' width='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 5.63225345 4 4.22419009 8-9.85644354' fill='none' stroke='%232db928' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(1 1)'/%3E%3C/svg%3E") center center no-repeat;
    //  @include mobile {
    //    right: 0;
    //  }
    //}
  }
}

con-field[error-status] {
  &[type=textfield],
  &[type=tel],
  &[type=email],
  &[type=select],
  &[type=radios],
  &[type=checkboxes],
  &[type=textarea] {
    //@include mobile {
    //  padding-right: 30px;
    //}
    //
    //&:after {
    //  content: '';
    //  position: absolute;
    //  top: var(--space-12);
    //  right: -30px;
    //  width: 20px;
    //  height: 20px;
    //  background: url("data:image/svg+xml,%3Csvg height='20' viewBox='0 0 20 20' width='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23ff2d37' stroke-linejoin='round'%3E%3Cpath d='m10.8944272 1.78885438 8.381966 16.76393202c.2469893.4939785.0467649 1.0946515-.4472136 1.3416408-.1388554.0694277-.2919685.1055728-.4472136.1055728h-16.76393201c-.55228475 0-1-.4477153-1-1 0-.1552451.03614509-.3083582.10557281-.4472136l2.70061195-5.4012239 5.68135406-11.36270812c.24698925-.4939785.84766229-.69420284 1.34164079-.44721359.1935274.0967637.3504499.25368619.4472136.44721359z'/%3E%3Cg stroke-linecap='round' stroke-width='2'%3E%3Cpath d='m10 13.1264648v-4.1264648'/%3E%3Cpath d='m10 16.4396973v-.4396973'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") center center no-repeat;
    //  @include mobile {
    //    right: 0;
    //  }
    //}
  }

  input[type=text],
  input[type=number],
  input[type=email],
  input[type=tel],
  input[type=date],
  input[type=password],
  select,
  textarea {
    border-color: var(--red);
  }
}

con-field[floatlabel][error-status]:not([is-empty]):not([is-focused]) {
  > label {
    color: var(--red) !important;
  }
}

/**
 * Float label.
 */
con-field[floatlabel] {
  padding-top: var(--space-12);
  label {
    z-index: 1;
    position: absolute;
    left: var(--input-padding-x, 26px);
    cursor: text;
    transition: transform .1s;
    //background-color: var(--con-field-float-label-background, var(--white));
    opacity: 1;
    top: 0;
    font-size: var(--font-size-10);
    letter-spacing: var(--letter-spacing-1);
    color: var(--darkest-grey);
    line-height: 1;
    font-weight: bold;
    padding: var(--space-4) 0;
    transform: translateY(-50%);
  }

  ::placeholder {
    color: transparent;
  }
}

con-field[isdark][floatlabel] {
  label {
    color: var(--white);
  }
}

// Label at the center of input.
con-field[floatlabel][is-empty]:not([is-focused]):not([is-expanded]) {
  label {
    @include inputText;
    font-size: var(--font-size-12);
    top: 2.125rem; // 34px;
    min-width: 70%;
    pointer-events: none;
    background-color: var(--con-field-float-label-background, var(--white));
    padding: var(--space-8) 0;
  }
}

con-field[floatlabel][is-focused], con-field[floatlabel][is-expanded] {
  label {
    //color: var(--blue-contrast);
  }
}

con-field[disabled] {

}

con-field[floatlabel][disabled] {
  label {
    color: var(--darkest-grey) !important;
  }
}
