.p-two-columns--dark {
  @include dark-background;
}

.p-two-columns--black {
  @include very-dark-background;
}

.p-two-columns__grid {
  display: grid;
  grid-template-columns: 5fr 4fr;
  grid-column-gap: 110px;

  padding-top: var(--space-80);
  padding-bottom: var(--space-60);

  @include mobile {
    padding-top: var(--space-38);
    padding-bottom: var(--space-20);
  }

  @include mobile {
    grid-template-columns: 1fr;
  }

  /**
   * Disable .container for all children blocks.
   */
  .container {
    margin: 0;
    padding: 0 !important;
    max-width: none !important;
  }
}

.p-two-columns__right {
  max-width: 350px;
  @include mobile {
    max-width: none;
    margin-top: var(--space-45);
  }
}
