.media-gallery-modal {
  @include middle {
    --modal-x-padding: 40px;
  }
}

con-media-gallery {
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
  @include mobile {
    margin: 0;
    padding: 0;
  }
}

.gallery__inner {
  width: 100%;

  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.gallery__slides {
  position: relative;
}

.gallery__tracker-wrapper {
  overflow: hidden;
}

.gallery__title {
  font-size: var(--font-size-40);
  font-weight: normal;
  line-height: 1.25;
  margin: 0 0 var(--space-24) 0;
  text-align: center;
  flex-shrink: 0;
  @include mobile {
    font-size: var(--font-size-24);
  }
}

.gallery__tracker {
  display: flex;
  grid-template-columns: repeat(1, 1fr);
  transition: 0.5s ease;
  min-height: 0;
  height: 100%;
  max-height: 60vh;
  flex-shrink: 1;

  @include mobile {
    max-height: 50vh;
  }
}

.gallery__thumbnails {
  flex-shrink: 0;
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include mobile {
    grid-template-columns: repeat(auto-fill, minmax(38px, 38px));
    grid-gap: 10px 10px;
  }

  > * {
    --thumbnail-space: var(--space-8);
    margin-left: var(--thumbnail-space);
    margin-right: var(--thumbnail-space);
    margin-bottom: calc(2 * var(--thumbnail-space));
    @include mobile {
      --thumbnail-space: var(--space-4);
    }
  }


}

.gallery__thumbnail {
  width: 76px;
  height: 76px;
  background-color: var(--black);
  cursor: pointer;
  border-radius: 10px;
  padding: 0;

  @include mobile {
    width: 38px;
    height: 38px;
  }

  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  }

  &--active {
    opacity: 0.5;
  }

  &--hasvideo {
    position: relative;

    &:after {
      display: block;
      content: '';
      width: 38px;
      height: 38px;
      position: absolute;
      transform: translate3d(-50%, -50%, 0);
      top: 50%;
      left: 50%;
      z-index: 1;
      background-image: url("data:image/svg+xml,%3Csvg height='38' viewBox='0 0 38 38' width='38' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cfilter id='a' height='180%25' width='180%25' x='-40%25' y='-40%25'%3E%3CfeOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='2'/%3E%3CfeColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'/%3E%3CfeMerge%3E%3CfeMergeNode in='shadowMatrixOuter1'/%3E%3CfeMergeNode in='SourceGraphic'/%3E%3C/feMerge%3E%3C/filter%3E%3Cg fill='none' fill-rule='evenodd' filter='url(%23a)' transform='translate(4 2)'%3E%3Ccircle cx='15' cy='15' fill='%23000' fill-opacity='.6' r='14' stroke='%23fff' stroke-width='2'/%3E%3Cpath d='m13.421 10.263 5.526 4.934-5.526 4.935z' fill='%23ffa500'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      cursor: pointer;
      @include mobile {
        background-image: url("data:image/svg+xml,%3Csvg height='20' viewBox='0 0 38 38' width='20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cfilter id='a' height='180%25' width='180%25' x='-40%25' y='-40%25'%3E%3CfeOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='2'/%3E%3CfeColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'/%3E%3CfeMerge%3E%3CfeMergeNode in='shadowMatrixOuter1'/%3E%3CfeMergeNode in='SourceGraphic'/%3E%3C/feMerge%3E%3C/filter%3E%3Cg fill='none' fill-rule='evenodd' filter='url(%23a)' transform='translate(4 2)'%3E%3Ccircle cx='15' cy='15' fill='%23000' fill-opacity='.6' r='14' stroke='%23fff' stroke-width='2'/%3E%3Cpath d='m13.421 10.263 5.526 4.934-5.526 4.935z' fill='%23ffa500'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
      }
    }
  }

  img {
    display: block;
    object-fit: cover;
    max-width: 76px;
    width: 100%;
    height: 76px;
    max-height: none;
    border-radius: 10px;
    @include mobile {
      width: 38px;
      height: 38px;
    }
  }
}

.gallery__slide {
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    margin: 0 auto;
  }

  > img {
    display: block;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  youtube-embed {
    min-height: 55vmin;
    @include mobile {
      min-height: 50vmin;
    }
  }
}


.gallery__pager {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  box-shadow: none;
  cursor: pointer;
}

.gallery__prev,
.gallery__next {
  svg {
    font-size: 3.25rem; // 52px
    @include mobile {
      font-size: var(--font-size-20);
    }
  }
}

.gallery__prev {
  left: -35px;
  @include mobile {
    left: -18px;
  }
}

.gallery__next {
  right: -35px;
  @include mobile {
    right: -18px;
  }
}


con-media-gallery-slide:not([loaded]) {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100vmin;
    height: 100vmin;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    background: var(--light-grey);
  }

  img {
    display: none;
  }
}
