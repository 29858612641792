a.skip-to-main {

  position: absolute;
  top: -150px;
  left: 0;
  padding: var(--space-8);
  background: var(--yellow);
  color: var(--black);
  border-bottom-right-radius: var(--space-8);
  transition: top 1s ease-out;
  z-index: 101;
  text-decoration: underline;
  font-weight: bold;


  &:focus {
    left: 0;
    top: 0;
    outline-color: transparent;
    transition: top .1s ease-in;
  }

  @media (prefers-reduced-motion: reduce) {
    transition-duration: 0.001ms !important;
  }
}
