.tire-search-page__marquee {
  .marquee__texts {
    @include space-y(var(--space-38));
    width: 100%;
    max-width: 820px;
  }

  .marquee__content {
    padding: var(--space-60) 0;
    @include mobile {
      padding: var(--space-45) 0;
    }
  }
}
