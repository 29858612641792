.tire-finder-landing-page {
  .tire-finder__header {
    padding-top: var(--space-38);
    @include mobile {
      padding-top: var(--space-20);
    }
  }
  con-tire-finder-section {
    display: block;
    padding-bottom: var(--space-20);
  }
}

.tire-finder__header {
  padding: 0 0 var(--space-38) 0;
  @include mobile {
    padding: var(--space-20) 0;
  }
  h1 {
    @include text-letterspace;
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
  }
}

.tire-finder__tabs,
.tire-finder__sections {
  width: 100%;
}

.tire-finder__tabs {
  display: block;

  input[disabled], select[disabled], button[disabled] {
    pointer-events: none
  }

  button {
    @include mobile {
      width: 100%;
    }
  }

  .blank-marquee {
    @include mobile {
      padding-bottom: var(--space-20);
    }
  }
}

.tire-finder__tabs[activetab] {

  /**
   * Disabled states for tab titles and tab sections.
   */
  .tire-finder__tab_title {
    color: var(--black);
  }
  .tire-finder__tab_title--active {

  }
}

.tire-finder__tab_links,
.tire-finder__tab_sections {
  display: flex;

  & > * {
    padding: 0;
    width: 100%;
    flex: 1;
  }
}

.tire-finder__tab_sections {
  & > * {
    padding-top: var(--space-24);
  }
}

.tire-finder__tab_links_wrapper {
  background-color: var(--white);
}

con-modal .tire-finder__tab_links_wrapper {
  margin: 0 -20px;
}

.tire-finder__tab_links {
  margin: 0 auto;
  max-width: 340px;
  & > * {
    padding: 0 10px;
  }
}

.tire-finder__tab_title--active span {
  //@include text-underline--yellow;
}

.tire-finder__tab_title {
  @include hover-reset;
  @include text-cta;

  text-align: center;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;


  &--active {
    text-decoration: underline;
  }

  span {
    pointer-events: none;

    // Animates underline from center
    display: inline-block;
    position: relative;

    &:after {
      bottom: 0;
      content: "";
      display: block;
      height: 1px;
      left: 50%;
      position: absolute;
      background: var(--dark-yellow);
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
  }
}

.tire-finder__tab_title--active,
.tire-finder__tab_title:hover {
  text-decoration: none;
  span {
    // Animates underline from center on active/hover
    &:after {
      width: 100%;
      left: 0;
    }
  }
}

.tire-finder__tab_section:not(.tire-finder__tab_section--active) {
  display: none;
}

.tire-finder__tab_section con-fieldset {
  display: flex;
  flex-direction: column;

  > * + *,
  con-tire-vehicle-fieldset > * + *,
  {
    margin-top: var(--space-20);
  }

  con-dependent-selects {
    display: grid;
    grid-gap: var(--space-20);
  }

  > .tire-finder__tos + * {
    margin-top: var(--space-24);
  }

  button {
    margin-left: auto;
    margin-right: auto;
  }
}

.tire-finder__tab_section__checkbox {
  display: flex;
  @include text-small;
  align-items: center;

  input[type="checkbox"] {
    display: block;
    margin-right: var(--space-8);
  }
}

con-tire-finder-button-with-modal {
  cursor: pointer;
  display: inline-block;
}

.tire-finder__form-header {
  @include space-y(var(--space-12));
  margin: var(--space-16) var(--space-32) var(--space-20) var(--space-32);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    @include as-h3;
  }
  @include mobile {
    margin: var(--space-16) 0 var(--space-20) 0;
  }
}

.tire-finder__tos.tire-finder__tos {
  font-size: var(--font-size-14);
  line-height: var(--line-height-20);
  text-align: center;
  margin-top: var(--space-38);
  font-weight: bold;

  &[data-disabled] {
    color: var(--dark-grey);
  }

  a {
    text-decoration: underline;
  }
}

.tire-finder__clear-wrapper {
  text-align: center;
  padding: var(--space-12) 0 var(--space-24) 0;
}

.tire-finder__variants-wrapper {
  > div {
    padding: var(--space-16) 0 var(--space-24) 0;
    text-align: center;
  }
}

.tire-finder__form {
  con-submit-loader-wrapper[loading] + * {
    display: none;
  }
}

.tire-finder__form-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
