.media-page__tabs {
  [slot=tablist] {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    button + button {
      margin-left: var(--space-60);
    }

    button {
      @include text-cta;

      display: block;

      padding: var(--space-12) 0;
      position: relative;

      &:after {
        content: '';
        display: block;
        height: 5px;
        width: 0;
        background: transparent;
        transition: width .5s ease, background-color .5s ease;

        left: 0;
        bottom: 0;
        position: absolute;
      }
    }

    button[selected], button:hover {
      &:after {
        width: 100%;
        background: var(--yellow);
      }
    }
  }

  [slot=panel] {
    padding: var(--space-80) 0;
    @include mobile {
      padding: var(--space-38) 0;
    }
  }
}

.media-page__tabs-panels {
  > * {
    background: var(--dark-grey);
  }
}

/**
 * Logos
 */
.media-page-logos {
  display: grid;
  grid-template-columns: 1fr 310px;
  grid-column-gap: 100px;
  max-width: 1015px;
  margin: 0 auto;
  @include mobile {
    grid-template-columns: 1fr;
    grid-row-gap: var(--space-28);
  }
}

.media-page-logos__main--animated {
  .images-section__images > * {
    --animate-offset: 3;
    @include animated;
    @include animated--fadeInUp;

    &:nth-child(1), &:nth-child(2) {
      animation-delay: calc(var(--animate-offset, 1) * 100ms);
    }

    &:nth-child(3), &:nth-child(4) {
      animation-delay: calc(var(--animate-offset, 1) * 200ms);
    }

    &:nth-child(5), &:nth-child(6) {
      animation-delay: calc(var(--animate-offset, 1) * 300ms);
    }

    &:nth-child(7), &:nth-child(8) {
      animation-delay: calc(var(--animate-offset, 1) * 400ms);
    }

    &:nth-child(9), &:nth-child(10) {
      animation-delay: calc(var(--animate-offset, 1) * 500ms);
    }
  }
}

.media-page-logos__sidebar {
  //@include text-base;
  > * + * {
    margin-top: var(--space-38);
  }

  .text-content {
    ul {
      li + li {
        margin-top: var(--space-20);
      }
    }
  }

  .panel__title {
    @include mobile {
      display: flex;
      justify-content: center;
    }
  }
}

.media-page-guidelines-wrapper {
  margin-top: var(--space-16);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include mobile {
    justify-content: center;
  }

  .btn {
    @include mobile {
      width: 100%;
    }
  }

  .btn + .btn {
    margin-top: var(--space-16);
  }
}

/**
 * Tires
 */
.media-page-tire-images {
  display: grid;
  grid-template-columns: 248px 1fr;
  grid-column-gap: 115px;
  max-width: 1100px;
  margin: 0 auto;

  @include mobile {
    grid-template-columns: 1fr;
    grid-row-gap: var(--space-20);
  }
}

.media-page-tire-images__main {
  & > * {
    @include mobile {
      @include regular-border-top;
      padding-top: var(--space-38);
    }
  }

  & > * + * {
    margin-top: var(--space-80);
    @include mobile {
      margin-top: var(--space-38);
    }
  }
}

.media-page-tire-images__main--animated {
  .images-section {
    --animate-offset: 3;
    @include animated;
    @include animated--fadeInUp;
    @include animated-staggered(10);
  }
}

.media-page-tire-images__sidebar {
  @include mobile {
    display: flex;
    justify-content: center;
  }

  > * + * {
    margin-top: var(--space-38);
    @include mobile {
      margin-top: 0;
    }
  }
}

.media-page-tire-images__notice {
  @include regular-border-top;
  //@include text-base;
  padding-top: var(--space-28);
}
