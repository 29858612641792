.news-list-columns {
  background: var(--lightest-grey);
  padding: var(--space-38) 0;
}

.news-list-columns__body {
  padding: 0 var(--space-16);

  @include mobile {
    padding: 0;
  }

  > * + * {
    margin-top: var(--space-20);
  }
}

.news-list-columns__body--animated {
  .news-list-columns__title {
    @include animated;
    @include animated--fadeInUp;
  }

  .view-rows article {
    @include animated;
    @include animated--fadeInUp;
    @include animated-staggered(3);
  }

  .news-list-columns__button-wrapper {
    @include animated;
    @include animated--fadeInUp;
    @include animated-order(4);
    .btn {
      @include mobile {
        width: 100%;
      }
    }
  }
}

.news-list-columns__title {
  @include mobile {
    text-align: center;
  }
}

.news-list-columns .view-rows {
  --mobile-row-gap: var(--space-20);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: var(--space-45);

  & > * {
    @include mobile {
      border-bottom: 1px solid var(--light-grey);
      padding-bottom: var(--mobile-row-gap);
    }
  }

  @include mobile {
    grid-template-columns: 1fr;
    grid-row-gap: var(--mobile-row-gap);
  }
}

.news-list-columns__button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: var(--space-32);
}
