.sports-page {
  //.card-list {
  //  @include very-dark-background;
  //}
  //.card-list:nth-child(odd) + .card-list {
  //  @include dark-background;
  //}
}

.sports-page__content {
  .card-list {
    @include dark-background;
  }
  .card-list:nth-child(odd) + .card-list {
    @include very-dark-background;
  }
}

con-horizontal-scrollbar + .sports-page__content {
  .card-list {
    @include very-dark-background;
  }
  .card-list:nth-child(odd) + .card-list {
    @include dark-background;
  }
}

.sports-page__body .card-list:nth-of-type(1) {
  border-top: 0;
}
