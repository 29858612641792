.link-button {
  @include hasIcon;
  @include btnTextBase;
  @include a-hover;
}

.link-button--underlined {
  text-decoration: underline;
}

.link-button--full-width {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.link-button--lowercase {
  font-size: var(--font-size-14);
  line-height: var(--line-height-20);
  letter-spacing: 0;
  text-transform: initial;
}

.link-button[disabled] {
  color: var(--dark-grey);
  cursor: not-allowed;
  .icon {
    color: var(--dark-grey);
  }
}
