.share-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);

  > * {
    line-height: 1;
  }

  > * + * {
    margin-left: var(--space-20);
  }

  a {
    display: flex;
  }
}
