.tile {
  text-align: center;
  > * + * {
    margin-top: var(--space-32);
    @include mobile {
      margin-top: var(--space-20);
    }
  }
}

.tile--with-hover:hover {
  .tile__media {
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.4);
  }
}

.tile__media {
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
}

.tile__title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--font-size-18);
  letter-spacing: 3px;
  line-height: var(--line-height-26);
}

.tile__text {
  font-size: var(--font-size-18);
  line-height: var(--line-height-26);
  strong {
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

.tile__content {
  padding: 0 var(--space-20);
  > * + * {
    margin-top: var(--space-4);
  }
}
