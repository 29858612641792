.news-article {
  padding-bottom: var(--space-38);
}

.news-article__header {
  padding: var(--space-20) 0 var(--space-60) 0;
  @include mobile {
    padding: 0 0 var(--space-20) 0;
  }

  h1 {
    @include as-h1;
    max-width: 35ch;
    margin: 0 auto;
    text-align: center;
  }
}

.news-article__partners .news-article__header h1 {
  @include as-h3;
  letter-spacing: 3px;
  text-transform: uppercase;
}


.news-article__columns {
  display: grid;
  grid-template-columns: minmax(0, 755px) 300px;
  grid-column-gap: var(--space-45);
  @include mobile {
    grid-template-columns: 1fr;
    grid-row-gap: var(--space-38);
  }
}

.news-article__body {
  @include text-content;
  @include article-content;

  .media--small-embed,
  .media--embed {
    border-radius: var(--space-12);
  }

  .small-embeds-grid + .media--embed,
  .media--embed + .small-embeds-grid {
    margin-top: var(--space-16);
  }

  figcaption {
    text-align: center;
    margin-top: var(--space-8);
  }

  table {
    img {
      display: block;
      width: 30px;
      height: auto;
    }
    img.align-center {
      margin: 0 auto;
    }
    img.align-right {
      float: right;
    }
  }
}

.news-article__body--animated {
  .media-video, .media-image {
    @include animate-image-on-hover;
  }
}

.news-article__related {
  @include mobile {
    padding-top: var(--space-38);
  }

  ul {
    @include reqular-list;
    margin-left: 1em;
  }

  ul li {
    //@include text-base;
    font-weight: bold;
  }

  ul {
    li + li {
      margin-top: var(--space-16);
    }
  }

  .panel__title {
    @include mobile {
      text-align: left;
    }
  }
}

.news-article__listing-image {
  border-radius: var(--space-12);
  overflow: hidden;
}

/**
 * Layouts
 */
.news-article__default {
  .news-article__body {
    margin: 0 13%;

    @include mobile {
      margin: 0 auto;
      max-width: 60ch;
      padding: 0;
    }

    .news-article__listing-image,
    .media--embed,
    .small-embeds-grid {
      margin-left: -17%;
      margin-right: -17%;
      max-width: 747px;
      @include mobile {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.news-article__partners {
  .news-article__header {
    padding: var(--space-60) 0 var(--space-38) 0;

    h1 {
      @include as-h2;
    }

    @include mobile {
      padding-top: 0;
    }
  }

  .image + .news-article__header {
    padding-top: var(--space-38);
  }

  .news-article__columns {
    grid-template-columns: minmax(0, 750px) 305px;
    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  .sharebar {
    margin-bottom: var(--space-45);
  }

  .news-article__related {
    padding-top: var(--space-38);
    margin-top: var(--space-38);
  }
}

.news-article__gallery--animated {
  .media--media-gallery-item {
    @include animated;
    @include animated--fadeInUp;
    @include animated-staggered(10);
  }
}
