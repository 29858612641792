.tireSpecs.tireSpecs {
  padding-bottom: 0;
  @include mobile {
    margin: 0 -20px;
  }
}

.tire-specs {
  @include very-dark-background;
  @include space-y(var(--space-12));
  padding: var(--space-60) var(--space-38);

  @include mobile {
    padding: var(--space-20) var(--space-20);
  }

  .container {
    @include space-y(var(--space-12));
  }
}

.tire-specs__title {
  text-align: center;
  @include as-h1;
  @include mobile {
    font-size: var(--font-size-32);
    line-height: var(--line-height-36);
  }
}

.tire-specs__view-all {
  text-align: center;
}

con-tire-specs + .tire-specs__view-all.tire-specs__view-all {
  margin-top: var(--space-20);
}
