@mixin mobile {
  @media screen and (max-width: $mobile_width) {
    @content
  }
}

@mixin touch {
  @media screen and (max-width: 1024px) {
    @content
  }
}

@mixin touch-only {
  @media screen and (min-width: $mobile_width + 1) and (max-width: 1024px) {
    @content
  }
}

@mixin middle {
  @media screen and (min-width: $mobile_width + 1) {
    @content
  }
}

//@mixin middle-only {
//  @media screen and (min-width: $mobile_width + 1) and (max-width: $wide_width - 1) {
//    @content
//  }
//}

@mixin wide {
  @media screen and (min-width: $wide_width) {
    @content
  }
}

@mixin max-width($max-width) {
  @media screen and (max-width: $max-width) {
    @content
  }
}
