/*
---
name: Checkbox
category: Form
---

```checkbox.html
  <input type="checkbox" id="checkbox1" />
  <label for="checkbox1">Default checkbox</label>
  <br/>
  <input disabled type="checkbox" id="checkbox2" />
  <label for="checkbox2">Disabled checkbox</label>
```

## Small checkbox
```small-checkbox.html
  <input class="checkbox--small" type="checkbox" id="checkbox1" />
  <label for="checkbox1">Default checkbox</label>
  <br/>
  <input class="checkbox--small" disabled type="checkbox" id="checkbox2" />
  <label for="checkbox2">Disabled checkbox</label>
```
*/
input[type='checkbox'],
input[type='radio'] {
  position: relative;

  display: inline-block;
  margin: 0;
  padding: 0;
  height: 20px;
  width: 20px;

  outline: none;
  vertical-align: top;

  border-radius: 3px;
  border: 1px solid var(--dark-grey);

  cursor: pointer;
  transition: border-color .3s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background: var(--white);

  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
  }

  &:checked {
    border-color: var(--yellow);
  }

  &:disabled {
    border-color: var(--grey);
    cursor: not-allowed;

    & + label {
      cursor: not-allowed;
    }
  }

  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        border-color: var(--yellow);
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 var(--dark-grey);
  }

}

input[type='checkbox']:checked:after {
  left: 6px;
  top: 2px;

  width: 7px;
  height: 12px;

  border: 2px solid var(--black);
  border-top: 0;
  border-left: 0;

  transform: rotate(43deg);
}

input[type=checkbox] + label {
  display: inline-block;
  margin-left: var(--space-4);
  vertical-align: top;

  font-size: var(--font-size-14);
  font-weight: bold;
  letter-spacing: var(--letter-spacing-0_5);
  line-height: 20px;

  cursor: pointer;
}

input[type=checkbox]:disabled + label {
  color: var(--grey);
}

input[type=checkbox].checkbox--small {
  height: 15px;
  width: 15px;

  &:checked:after {
    top: 1px;
    left: 4px;
    width: 5px;
    height: 9px;
  }
}


/*
---
name: Radio
category: Form
---

```radio.html
  <input name="radio" value="1" type="radio" id="radio1" />
  <label for="radio1">Radio 1</label>
  <br/>
  <input name="radio" value="2" type="radio" id="radio2" />
  <label for="radio2">Radio 2</label>
```
*/
input[type='radio'] {
  border-radius: 50%;
  border-color: var(--yellow);

  &:checked:after {
    top: 4px;
    left: 4px;

    width: 10px;
    height: 10px;

    border-radius: 50%;
    background: var(--yellow);
  }
}
