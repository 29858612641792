con-disclosure[expanded] {
  .btn.btn--expand {
    @include btnCollapse;
  }
}

con-disclosure:not([expanded]) {
  .hidden-when-collapsed {
    display: none !important;
  }
}

con-disclosure[expanded] {
  .hidden-when-expanded {
    display: none !important;
  }
}

con-disclosure [slot=toggle] {
  margin: 0 auto;
}

con-disclosure [slot=detail] {
  display: none;
  overflow: hidden;
}

con-disclosure[expanded] [slot=detail] {
  position: relative;
  z-index: 1;
}
