.customer-support {
  @include very-dark-background;
  .container > * + * {
    margin-top: var(--space-80);
    @include mobile {
      margin-top: var(--space-38);
    }
  }
}

.customer-support__wrapper {
  padding: var(--space-80) 0;

  @include mobile {
    padding: var(--space-38) 0;
  }
}

.customer-support__wrapper:nth-child(2) {
  @include dark-background;
  padding: 0;
}

.customer-support__header {
  text-align: center;
  > * + * {
    margin-top: var(--space-12);
  }
}

.customer-support__title {
  .text-uppercase {
    @include text-letterspace;
  }
}

.customer-support__subtitle {
  @include as-h3;
  @include touch {
    @include text-lg;
  }
}

.customer-suppport__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--space-20) var(--space-20);
  @include mobile {
    grid-template-columns: 1fr;
    grid-gap: var(--space-38) var(--space-38);
  }
}

.customer-suppport__cards--animated {
  --animate-offset: 3;
  > * {
    @include animated;
    @include animated--fadeInUp;
    @include animated-staggered(3);
  }
}

.customer-support__warranty-types {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 var(--space-32);
  margin: 0 auto;

  > * {
    flex: 0 0 33%;
    padding: var(--space-38) var(--space-20);
  }

  @include mobile {
    grid-template-columns: 1fr;
    grid-row-gap: var(--space-24);
    max-width: 80%;
    margin: 0 auto;
    padding: 0;

    > * {
      flex: 0 0 100%;
      padding: var(--space-20);
    }
  }

  .logo-with-text {
    img {
      @include mobile {
        width: 60px;
        height: auto;
      }
    }
  }
}

.customer-support__warranty-types--animated {
  --animate-offset: 6;
  > * {
    @include animated;
    @include animated--fadeInUp;
    @include animated-staggered(3);
  }
}

.customer-support + .banner {
  --animate-offset: 9;
}
