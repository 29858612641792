/*
---
name: Stag Sans
category: Fonts
---

```example.html
<p>I'm a Stag Sans normal</p>
<p><b>I'm a Stag Sans bold</b></p>
<p><i>I'm a Stag Sans italic</i></p>
```
*/
@font-face {
  font-family: "Stag Sans";
  src: url("../../fonts/StagSans-Thin.woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Stag Sans";
  src: url("../../fonts/StagSans-Light.woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Stag Sans";
  src: url("../../fonts/StagSans-Book.woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Stag Sans Bold";
  src: url("../../fonts/StagSans-Book.woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Stag Sans";
  src: url("../../fonts/StagSans-BookItalic.woff");
  font-style: italic;
  font-weight: normal;
  font-display: swap;
}
