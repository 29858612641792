.fake-input {

}

.fake-input__label {

}

.fake-input__input {
  @include input;
  @include input-bordered;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
}

.field--transparent {
  .fake-input__input {
    background-color: transparent;
  }
}
