.sharebar {
  @include regular-border-top;
  @include regular-border-bottom;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: var(--space-8);
  height: var(--space-45);
}

.sharebar__controls {
  display: flex;
  align-items: center;

  > * {
    line-height: 1;
  }

  > * + * {
    margin-left: var(--space-16);
  }
}
