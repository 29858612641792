.main-nav-search-for-tire-menu {
  padding: 0 var(--space-20) var(--space-20) 0;
  @include mobile {
    padding: 0 var(--space-20) var(--space-20) var(--space-20);
  }
  .icon {
    font-size: 25px;
  }
}

.header--dark {
  .main-nav-search-for-tire-menu {
    @include mobile {
      border-bottom: 1px solid var(--darkest-grey);
    }
  }
}

