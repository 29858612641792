.search-by-size {

}

.search-by-size__help-wrapper {
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.search-by-size__help {
  margin-left: auto;
  margin-right: auto;

  .icon__help svg {
    --question-stroke-width: 2px;
    --circle-fill: var(--yellow);
    --circle-stroke: var(--yellow);
  }
}

.search-by-size__fields {

}

con-tire-finder-search-by-size-form .tire-finder__fields.tire-finder__fields {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--space-20);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @include mobile {
    grid-gap: var(--space-8);
  }
}

con-tire-finder-search-by-size-form {
  con-field[type="select"] {
    @include mobile {
      --input-padding-x: 12px;
    }
  }

  .tire-finder__form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include space-y(var(--space-38));
    max-width: 520px;
    margin-top: var(--space-8);
  }

  .link-button .icon {
    color: var(--yellow);
  }

  .link-button + con-dependent-selects {
    //margin-top: var(--space-24);
  }
}
