.page--ev-ready {
  .marquee__title {
    font-size: var(--font-size-50);
    line-height: var(--line-height-60);
    @include mobile {
      font-size: var(--font-size-32);
      line-height: var(--line-height-40);
    }

    .text-uppercase {
      font-size: var(--font-size-42);
      @include mobile {
        font-size: var(--font-size-28);
      }
    }

    br {
        display: block !important;
    }
  }
  .marquee--has-jumplink {
    padding: 0;
  }

  .marquee__container {
    margin-top: -80px;
  }
}
