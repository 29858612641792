.news-list-vertical {
  > * > * + * {
    margin-top: var(--space-22);
  }

  .view-rows {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: var(--space-20);
    margin-bottom: var(--space-20);

    > * {
      @include regular-border-bottom;
      padding-bottom: var(--space-20);
    }
  }

  .more-link {
    justify-content: flex-start;
    @include mobile {
      justify-content: center;
    }
  }
}

.news-list-vertical--animated {
  .views-element-container {
    @include animated;
    @include animated--fadeInUp;
  }
  .more-link {
    @include animated;
    @include animated-order(3);
    @include animated--fadeInUp;
  }
}

.news-list-vertical__title {
  @include as-h1u;
  @include mobile {
    text-align: center;
  }
}
