/*
---
name: Select input
category: Form
---

```select.html
<select>
  <option>First</option>
  <option>Second</option>
  <option>Thrid</option>
</select>
```

```select-light.html
<select class="select--light">
  <option>First</option>
  <option>Second</option>
  <option>Thrid</option>
</select>
```
*/
@mixin select {
  @include input;
  @include input-bordered;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='28.0'%3E%3Cpolygon points='0,0 50,0 25.0,28.0' style='fill: %23000;'%3E%3C/polygon%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem top 50%;
  background-size: 20px;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  position: relative;


  &:disabled {
    border-color: var(--grey);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='28.0'%3E%3Cpolygon points='0,0 50,0 25.0,28.0' style='fill: %23cdcdcd;'%3E%3C/polygon%3E%3C/svg%3E");
  }
}

select {
  @include select;
}

@mixin selectLight {
  border: 0;
  text-transform: capitalize;
  padding-left: 0;
  padding-right: 0;
  background-image: url("data:image/svg+xml,%3Csvg height='6' viewBox='0 0 10 6' width='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6.5-2-4.5 4 4.5 4' fill='none' stroke='%23333' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 -1 -1 0 7.25 7.25)'/%3E%3C/svg%3E");
  background-size: 10px;
  background-position: right 0 top 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  letter-spacing: var(--letter-spacing-0_5);
}

con-select[lightstyle] select {
  @include selectLight;
}

con-field[disabled][isdark] {
  --con-field-float-label-background: #000000;
  con-select {
    --con-select-button-bg-color: #000000;
    --con-select-button-disabled-border-color: var(--darkest-grey);
    --con-select-button-disabled-color: var(--darkest-grey);
    select {}
  }
}

con-field[isdark] con-select {
  --con-select-button-color: #000000;
}

.field--transparent {
  --input-text-color: var(--darkest-grey);

  con-select:not([expanded]) {
    background-color: transparent;
    --con-select-button-bg-color: transparent;
  }

  con-select[isdark]:not([expanded]) {
    --con-select-button-color: var(--white);
    --con-select-button-border-color: var(--white);
    --con-select-button-border-width: 1px;
  }

  con-select[isdark][disabled] {
    --con-select-button-color: var(--darkest-grey);
    --con-select-button-border-color: var(--darkest-grey);
  }

  @media print {
    * {
      --input-text-color: var(--black) !important;
      --con-select-button-color: var(--black) !important;
      --con-select-button-border-color: var(--black) !important;
      --con-field-float-label-color: var(--black) !important;
    }
  }
}
