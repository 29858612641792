.experience-marquee + .sports-thumbnails {
  @include very-dark-background;
}

.news-category-page-list--tid-57 {
  .card__media {
    background-color: var(--white);

  }
  .media-image {
    margin: var(--space-20);
  }
}
