.promo-product-page-panel {
  display: flex;
  gap: 20px;
  font-weight: bold;
  font-size: 12px;
}

.promo-product-page-panel__cta {
  flex-shrink: 0;
  max-width: 84px;
  width: 100%;
  text-align: left;
  padding-top: 0;
  padding-bottom: 0;

  .icon {
    margin-top: -4px;
  }
}
