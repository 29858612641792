.con-store-finder-results__item {
  padding: 10px;
}

.con-store-finder-results__item_inner {
  @include text-base;
  border-radius: 12px;
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, .15) 0 0 10px 0;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px 0;
  }
}

.con-store-finder-results__item-top {
  position: relative;
  padding: var(--space-20) var(--space-20) var(--space-16) var(--space-20);
}

.con-store-finder-results__item-top > * + * {
  margin-top: var(--space-4);
}

.con-store-finder-results__item-disclosure {
  @include regular-border-top;

  [slot="toggle"],
  [slot="toggleCollapsed"] {
    padding: var(--space-20);
    width: 100%;
    justify-content: flex-start;
  }

  [slot="toggle"] .icon, [slot="toggleCollapsed"] .icon {
    margin-right: var(--space-12);
  }

  [slot="detail"] {
    padding: 0;
  }

  [slot="detail"] > div {
    border-bottom-left-radius: var(--space-12);
    border-bottom-right-radius: var(--space-12);
    padding: var(--space-32) var(--space-20) var(--space-32) var(--space-45);
    background-color: var(--lightest-grey);

    > * + * {
      margin-top: var(--space-16);
    }
  }

  [slot="detail"] > * + * {
    margin-top: var(--space-20);
  }
}

.con-store-finder-results__item-header {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;

  button {
    text-transform: uppercase;
  }

  button:hover {
    @include text-underline--yellow;
  }
}

.con-store-finder-results__item-distance {
  text-align: center;
  line-height: 1;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: var(--space-8);
}

.con-store-finder-results__item-distance-value {
  color: var(--dark-yellow);
  @include as-h2;
  display: block;
}

.con-store-finder-results__item-distance-unit {
  @include text-cta;
}

.con-store-finder-results__item-open-hours > * + * {
  margin-top: var(--space-8);
}

.con-store-finder-results__item-links {
  margin-left: -20px;

  & > * {
    width: 100%;
    padding-left: var(--space-20);
    height: 24px;
    justify-content: initial;
    align-items: start;
  }

  & > * + * {
    margin-top: var(--space-4);
  }
}
