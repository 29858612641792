
.logo-marquee {
  position: relative;
  display: flex;
  align-items: center;

  background-image: url("/themes/custom/nextcontinental/assets/images/black-bg2.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  padding: var(--space-60) 0 var(--space-38) 0;
  background-color: var(--dark-grey);

  @include mobile {
    display: block;
    padding: 0;
  }
}

.logo-marquee__title {
  @include as-h1;
  br {
    @include mobile {
      display: none;
    }
  }
}

.logo-marquee__content {
  padding-top: var(--space-4);
  padding-bottom: var(--space-8);

  position: relative;
  width: 100%;
  text-align: center;
  z-index: 1;
  color: var(--white);

  @include mobile {
    padding: var(--space-34) 0;
    margin: 0 auto;
    text-align: center;
  }

  > * + * {
    margin-top: var(--space-16);
  }

  img {
    @include mobile {
      width: auto;
      height: 140px;
    }
  }
}

.logo-marquee__texts {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;

  > * + * {
    margin-top: var(--space-16);
  }

  .text-uppercase {
    letter-spacing: 8px;
    @include mobile {
      letter-spacing: 5px;
    }
  }
}

.logo-marquee__text {
  @include as-h3;
  @include mobile {
    font-size: var(--font-size-20);
    line-height: var(--line-height-28);
  }
}

.logo-marquee__buttons {
  > * {
    @include mobile {
      display: block;
    }
  }
  .btn {
    @include mobile {
      width: 100%;
    }
  }
}
