.tiles {
  padding: var(--space-80) 0;
  @include mobile {
    padding: var(--space-38) 0;
  }
}

.tiles__list {
  display: grid;
  grid-gap: var(--space-20);
  margin-top: var(--space-38);
  @include mobile {
    grid-gap: var(--space-38);
  }
}

.tiles__list_col_3 {
  grid-template-columns: repeat(3, 1fr);
}

.tiles__list_col_4 {
  grid-template-columns: repeat(4, 1fr);
}

.tiles__list_col_3, .tiles__list_col_4 {
  @include mobile {
    grid-template-columns: 1fr;
  }
}

.tiles__title {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 6px;
  font-size: var(--font-size-40);
  font-weight: 300;
  line-height: var(--line-height-48);
  @include mobile {
    letter-spacing: 5px;
    font-size: var(--font-size-32);
    line-height: var(--line-height-36);
  }
}

.tiles__subtitle {
  @include as-h3;
  text-align: center;
  margin-top: var(--space-12);
  @include mobile {
    font-size: var(--font-size-18);
    margin-top: var(--space-8);
  }
}
