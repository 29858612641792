/*
---
name: Select
category: Fields
---

## Default
```select.html
<br/>
<con-field type="select" floatlabel>
  <label for="edit-name">Choose one</label>
  <select id="edit-name" name="name">
    <option value disabled selected>Choose one</option>
    <option>First</option>
    <option>Second</option>
    <option>Third</option>
  </select>
  <div class="field__help">Choose an option</div>
</con-field>
```

## Focused
```select-focused.html
<div style="padding: 1rem;">
  <con-field type="select" floatlabel is-focused>
    <label for="edit-name">Choose one</label>
    <select id="edit-name" name="name">
      <option value disabled selected>Choose one</option>
      <option>First</option>
      <option>Second</option>
      <option>Third</option>
    </select>
    <div class="field__help">Choose an option</div>
  </con-field>
</div>
```

## Disabled
```select-disabled.html
<div style="padding: 1rem;">
  <con-field type="select" floatlabel disabled>
    <label for="edit-name">Choose one</label>
    <select id="edit-name" name="name">
      <option value disabled selected>Choose one</option>
      <option>First</option>
      <option>Second</option>
      <option>Third</option>
    </select>
    <div class="field__help">Choose an option</div>
  </con-field>
</div>
```

## Success
```select-success.html
<div style="padding: 1rem;">
  <con-field type="select" floatlabel success-status>
    <label for="edit-name">Choose one</label>
    <select id="edit-name" name="name">
      <option value disabled selected>Choose one</option>
      <option>First</option>
      <option>Second</option>
      <option>Third</option>
    </select>
    <div class="field__help">Choose an option</div>
  </con-field>
</div>
```

## Error state
```select-error.html
<div style="padding: 1rem;">
  <con-field type="select" floatlabel error-status>
    <label for="edit-name">Choose one</label>
    <select id="edit-name" name="name">
      <option value disabled selected>Choose one</option>
      <option>First</option>
      <option>Second</option>
      <option>Third</option>
    </select>
    <div class="field__help field__error">Please Choose an option</div>
    <div class="field__help">Choose an option</div>
  </con-field>
</div>
```
*/
con-field[type=select]:not(.field__type-select--light) {
  position: relative;
}

con-field[type=select] {
  &[is-focused] {
    select {
      border-color: var(--blue);
    }
  }
}

.field__type-select--light {
  con-select, select {
    @include selectLight;
  }
}
