$sizes: 0, 4, 8, 12, 16, 20, 22, 24, 26, 32, 34, 38, 60;

$directions: ("left": "l", "right": "r", "top": "t", "bottom": "b");

@each $direction, $alias in $directions {
  @each $size in $sizes {
    .m#{$alias}-#{$size} {
      margin-#{$direction}: var(--space-#{$size}) !important;
    }
  }
  .m#{$alias}-auto {
    margin-#{$direction}: auto !important;
  }
}

@each $size in $sizes {
  .mx-#{$size} {
    margin-left: var(--space-#{$size}) !important;
    margin-right: var(--space-#{$size}) !important;
  }
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@each $size in $sizes {
  .my-#{$size} {
    margin-top: var(--space-#{$size}) !important;
    margin-bottom: var(--space-#{$size}) !important;
  }
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
