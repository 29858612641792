.tire-specs-page {
  padding-top: var(--space-20);

  con-user-vehicle-info {
    margin: var(--space-20) 0 var(--space-16) 0;
    @include mobile {
      margin: var(--space-20) 0 var(--space-32) 0;
    }
  }
}

.tire-specs-page__header {
  @include space-y(var(--space-8));
  padding-top: var(--space-60);
  font-size: var(--font-size-18);

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include mobile {
    display: block;
    padding-top: var(--space-38);
    text-align: center;
    font-size: var(--font-size-15);
  }
}

.tire-specs-page__header-top {
  @include space-y(var(--space-8));
}

.tire-specs-page__title {
  font-weight: 400;
}

.tire-specs-page__list {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--grey);
  li {
    border-top: 1px solid var(--grey);
    order: 2;
  }
  li:nth-child(odd) {
    background-color: var(--lightest-grey);
  }
}

.tire-specs-page__con-details {
  > *:nth-child(2) {
    margin-top: 0;
  }
  h2 {
    font-size: var(--font-size-15);
    font-weight: bold;
    line-height: 1;
    margin-top: 2px;
  }
  .con-details__toggle {
    width: 100%;
    padding: var(--space-12) 0;
  }
  .con-details__toggle .icon {
    font-size: var(--font-size-12);
  }
}

.tire-specs-page__con-details__content {
  border-top: 3px solid #000;
  padding-top: var(--space-20);
  padding-bottom: var(--space-20);
}

.tire-specs-page__con-details__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0 var(--space-20);

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
  }

  dl {
    padding: var(--space-4) 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey);
    @include mobile {
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
    }
  }

  dt {
    font-size: var(--font-size-12);
    font-weight: bold;
    letter-spacing: var(--letter-spacing-0_5);
  }

  dd {
    font-weight: normal;
    font-size: var(--font-size-15);
  }
}

.tire-specs-page__search-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 210px;
  width: 100%;
  margin: var(--space-20) 0 var(--space-28) 0;

  @include mobile {
    max-width: none;
  }
}

.tire-specs-page__search {
  --input-placeholder-font-size: var(--font-size-14);
  position: relative;
  height: 100%;
  max-width: 240px;
  width: 100%;


  @include mobile {
    max-width: none;
    margin-top: 0;
  }

  .icon {
    position: absolute;
    right: var(--space-12);
    top: 50%;
    transform: translateY(-50%);
  }
  input[type="search"] {
    padding-left: var(--space-16);
    padding-right: var(--space-12);
    border-color: var(--darkest-grey);
    height: 32px;

    @include mobile {
      height: 40px;
    }

    &::placeholder {
      --input-placeholder-color: var(--dark-grey);
      text-transform: initial;
      letter-spacing: 0.5px;
    }
  }
  input[type="search"]:valid + .icon {
    display: none;
  }
}

.tire-specs-page__back-btn {
  .icon {
    font-size: var(--font-size-28);
    --arrow-stroke-color: var(--dark-yellow);
  }
}

[data-checked-target] .con-details__toggle h2 {
  &::after {
    content: "✓";
    padding-left: var(--space-12);
  }
}

.tire-specs-page__tos {
  max-width: none;
  padding: var(--space-38) 0;
  h2 {
    font-size: var(--font-size-12);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-1_25);
  }
}

.tire-specs-page__search-count {
  text-transform: uppercase;
  font-size: var(--font-size-10);
  margin-top: var(--space-4);
  font-weight: bold;
}

.tire-specs-page__print {
  margin-bottom: var(--space-20);
  @include mobile {
    margin-top: var(--space-12);
  }
}
