/**
 * Animations.
 * Inspired by https://github.com/animate-css/animate.css/blob/main/animate.css
 */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@keyframes bounceRight {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(-5px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


@mixin animated {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
  animation-delay: calc(var(--animate-offset, 0) * var(--animate-delay, 100ms));
}

.animated {
  @include animated;
}

.animated-hover {
  &:hover {
    @include animated;
  }
}

.animated-fix-zindex {
  position: relative;
  z-index: 2;
}

@mixin animated-infinite {
  animation-iteration-count: infinite;
}

.animated.infinite {
  @include animated-infinite;
}

.animated.delayed {
  animation-delay: var(--animate-delay);
}

@mixin animated-order($n) {
  animation-delay: calc(#{$n - 1} * var(--animate-offset, 1) * var(--animate-delay, 100ms));
}

@for $x from 2 through 7 {
  .animated-order-#{$x} {
    @include animated-order($x)
  }
}

@mixin animated-faster {
  animation-duration: calc(var(--animate-duration) / 2);
}

.animated.faster {
  @include animated-faster;
}

@mixin animated-fast {
  animation-duration: calc(var(--animate-duration) * 0.8);
}

.animated.fast {
  @include animated-fast;
}

@mixin animated-slow {
  animation-duration: calc(var(--animate-duration) * 2);
}

.animated.slow {
  @include animated-slow;
}

@mixin animated-slower {
  animation-duration: calc(var(--animate-duration) * 3);
}

.animated.slower {
  @include animated-slower;
}


@mixin animated--fadeInUp {
  animation-name: fadeInUp;
}

.animated--fadeInUp {
  @include animated--fadeInUp;
}

.animated--fadeIn {
  animation-name: fadeIn;
}

.animated--bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}

@mixin animated--bounceRight {
  animation-name: bounceRight;
}

.animated--bounceRight {
  @include animated--bounceRight;
}

.animated--slideInUp {
  animation-name: slideInUp;
}

.animated--slideInDown {
  animation-name: slideInDown;
}

@mixin animated-staggered($n, $startFrom: 1) {
  @for $x from $startFrom through $n {
    // Default delay is the highest in the set, so that last elements don't appear
    // before first elements.
    animation-delay: calc(var(--animate-offset, 1) * (100ms * #{$n}));

    &:nth-child(#{$x}) {
      animation-delay: calc(var(--animate-offset, 1) * (100ms * #{$x}));
    }
  }
}

.animated-staggered {
  @include animated-staggered(24);
  //animation: fadeIn 300ms ease forwards;
}

@media (prefers-reduced-motion: reduce) {
  .animated {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animated[class*='Out'] {
    opacity: 0;
  }
}


@media print {
  .animated {
    animation: none !important;
    transition: none !important;
  }

  .animated[class*='Out'] {
    opacity: 0;
  }
}

