.social-media-links-bar {
  background-color: var(--black);
  border-bottom: 4px solid var(--yellow);
  color: var(--white);

  @include mobile {
    border-top: 0;
  }

  ul {
    display: flex;
    align-items: flex-start;
  }

  li {
    padding: var(--space-22) 0;
    flex: 0 0 25%;
    text-align: center;

    @include mobile {
      display: flex;
      padding-bottom: var(--space-16);
      justify-content: center;
      align-items: center;
      height: 4rem;
    }

    .icon {
      color: var(--white);
      font-size: var(--font-size-20);
      @include mobile {
        margin-right: 0 !important;
      }
    }

    span:not(.icon):not(.sr-only) {
      @include mobile {
        display: none;
      }
    }
  }

  a {
    display: inline-flex;
  }

  .link-button {
    line-height: 1;
  }
}

.social-media-links-bar--dark {
  background-color: var(--black);
  border-bottom: 4px solid var(--yellow);
  color: var(--white);


  li {
    padding: var(--space-22) 0;
  }

  li + li {
    border-left: 0;
  }

  .icon {
    font-size: 20px;
  }

  .link-button {
    line-height: 1;
  }
}

//.page-node-type-landing-page {
//  .social-media-links-bar--dark {
//    margin-top: -16px;
//    @include mobile {
//      margin-top: -24px;
//    }
//  }
//}
