con-cookie-popup {
  display: block;
}

.cookie-popup {
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: #333;
  color: var(--white);
  padding: var(--space-32) 0;

  > * {
    position: relative;
  }
}

.cookie-popup__body {
  display: flex;
  padding-right: 100px;

  @include mobile {
    display: block;
    padding-right: 0;
    padding-top: var(--space-16);
  }

  > * + * {
    margin-left: var(--space-20);
    @include mobile {
      margin-left: 0;
      margin-top: var(--space-20);
    }
  }
}

.cookie-popup__text {
  max-width: 74ch;
}

.cookie-popup__buttons {
  display: flex;
  align-items: center;

  > * {
    flex-shrink: 0;
  }

  > * + * {
    margin-left: var(--space-20);
  }
}

.cookie-popup__close {
  position: absolute;
  top: -1.25rem;
  right: var(--space-20);
  @include mobile {
    top: -2.5rem;
    right: var(--space-12);
  }
}
