.promo-teaser {
  box-shadow: 0 0 40px 0 rgba(0,0,0,0.10);
  border-radius: 10px;
  display: flex;
  padding: var(--space-28) var(--space-28) var(--space-28) var(--space-28);
  @include mobile {
    display: block;
    padding: var(--space-24);
  }

  .tag {
    font-size: var(--font-size-14);
    letter-spacing: 0.5px;
  }
}

.promo-teaser__image {
  display: block;
  max-width: 270px;
  width: 100%;
  margin-right: var(--space-28);

  @include mobile {
    max-width: none;
  }
}

.promo-teaser__image-promo-ended-overlay {
  background: rgba(0, 0, 0, 0.55);
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-12);
  span {
    max-width: 200px;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    color: var(--white);
    letter-spacing: 8px;
    line-height: 28px;
  }
}

.promo-teaser--promo-ended .promo-teaser__image {
  position: relative;
}

.promo-teaser__content {
  @include text-lg;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include mobile {
    margin-top: var(--space-28);
  }

  p {
    margin-top: var(--space-8);
    margin-bottom: var(--space-16);
  }

  .btn {
    @include mobile {
      width: 100%;
    }
  }
}

.promo-teaser__title {
  @include as-h3;
}
