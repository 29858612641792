
.promo-flag {
  display: inline-block;
  text-align: center;
  color: var(--dark-black);
  font-weight: bold;
  text-transform: uppercase;
  background-color: var(--yellow);
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: var(--line-height-16);
  padding: 2px var(--space-16) 2px var(--space-12);
  clip-path: polygon(0 0, 100% 0, 95% 50%, 100% 100%, 0 100%);
  transition-duration: 0ms;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.has-promo-flag {
  position: relative;
  overflow: hidden;
  .promo-flag {
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
