.experience-marquee {
  display: flex;
  width: 100%;
  @include mobile {
    display: block;
  }
}

.experience-marquee__column {
  flex: 1 1 50%;
}

.experience-marquee__card {
  @include hover-reset;
  background-color: var(--black);
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;

  &:before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    z-index: 0;
  }

  > * {
    z-index: 1;
  }

  @include mobile {
    height: 220px;
  }
}

.experience-marquee__title {
  @include as-h1u;
  color: var(--white);
  margin-bottom: var(--space-20);
  @include mobile {
    margin-bottom: var(--space-8);
  }
}
