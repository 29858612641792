/*
---
name: Image
category: Elements
---

# Ratio 1:1
```image-1-1.html
<div class="image image--ratio-1-1">
  <img src="https://via.placeholder.com/400" />
</nav>
```


# Ratio 4:3
```image-4-3.html
<div class="image image--ratio-4-3">
  <img src="https://via.placeholder.com/400x300" />
</nav>
```

# Ratio 16:9
```image-16-9.html
<div class="image image--ratio-16-9">
  <img src="https://via.placeholder.com/800x450" />
</nav>
```
*/

@mixin img-responsive {
  width: 100%;
  height: auto;
}

img.img-responsive {
  @include img-responsive;
}

.image {
  display: block;
  position: relative;
  background-color: var(--light-grey);

  img {
    display: block;
    height: auto;
    width: 100%;
    max-width: 100%;
  }
}

.image--full-width {
  width: 100%;
}

.image--video-cover {
  //display: inline-block;
  //position: relative;
  //
  //img {
  //  cursor: pointer;
  //  background-color: var(--light-grey);
  //  display: block;
  //  //width: 100%;
  //  //bottom: 0;
  //  //left: 0;
  //  //position: absolute;
  //  //right: 0;
  //  //top: 0;
  //  //height: auto;
  //}
  //
  //&:after {
  //  content: '';
  //  width: 76px;
  //  height: 76px;
  //  position: absolute;
  //  transform: translate3d(-50%, -50%, 0);
  //  top: 50%;
  //  left: 50%;
  //  z-index: 1;
  //  background-image: url("data:image/svg+xml,%3Csvg height='76' viewBox='0 0 76 76' width='76' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='38' cy='38' fill='%23000' fill-opacity='.2' r='37' stroke='%23fff' stroke-width='2'/%3E%3Cpath d='m34 26 14 12.5-14 12.5z' fill='%23ffa500'/%3E%3C/g%3E%3C/svg%3E");
  //  cursor: pointer;
  //
  //  @include mobile {
  //    width: 36px;
  //    height: 36px;
  //    background-image: url("data:image/svg+xml,%3Csvg height='36' viewBox='0 0 76 76' width='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='38' cy='38' fill='%23000' fill-opacity='.2' r='37' stroke='%23fff' stroke-width='2'/%3E%3Cpath d='m34 26 14 12.5-14 12.5z' fill='%23ffa500'/%3E%3C/g%3E%3C/svg%3E");
  //  }
  //}
}

.image--ratio-square,
.image--ratio-1-1 {
  padding-top: 100%;
}

.image--ratio-4-3 {
  padding-top: 75%;
}

.image--ratio-3-2 {
  padding-top: 66.6666%;
}

.image--ratio-16-9 {
  padding-top: 56.25%;
}

.image--ratio-square,
.image--ratio-1-1,
.image--ratio-3-2,
.image--ratio-4-3,
.image--ratio-16-9 {
  img {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
}

/**
 * Animates children .image on hover
 */
@mixin animate-image-on-hover {
  overflow: hidden;

  > .image {
    transition: 0.4s ease;
  }

  &:hover, &:focus {
    > .image {
      transform: scale(1.08);
    }
  }
}
