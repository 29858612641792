.faqs {
  padding: var(--space-80) 0;
  @include mobile {
    padding: var(--space-38) 0;
  }
  > * > * + * {
    margin-top: var(--space-38);
  }
}

.faqs__list {
  width: 100%;
  max-width: 890px;
  margin-left: auto;
  margin-right: auto;
  > * + * {
    margin-top: var(--space-24);
  }
}

.faqs__title {
  @include as-h1u;
  text-align: center;
}

.faq {

  .faq__question {
    outline: none;
    list-style: none;
    cursor: pointer;
    font-size: var(--font-size-24);
    line-height: var(--line-height-32);
    padding-left: 24px;
    position: relative;
    @include mobile {
      font-size: var(--font-size-18);
      line-height: var(--line-height-26);
    }

    > * {
      display: inline;
    }

    &::marker,
    &::-webkit-details-marker {
      display: none;
    }

    &:focus {
      outline: none;
    }

    &:before {
      position: absolute;
      left: 0;
      top: 7px;
      color: var(--dark-yellow);
      content: "";
      display: inline-block;
      height: 16px;
      width: 16px;

      background-image: url("data:image/svg+xml,%3Csvg height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m8 0c.55228475 0 1 .44771525 1 1v6h6c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-6v6c0 .5522847-.44771525 1-1 1s-1-.4477153-1-1v-6h-6c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1h6v-6c0-.55228475.44771525-1 1-1z' fill='%23c27e00' fill-rule='evenodd'/%3E%3C/svg%3E");
      background-position: left center;
      background-repeat: no-repeat;

      @include mobile {
        top: 4px;
      }
    }
  }

  &[open] {
    .faq__question {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg height='2' viewBox='0 0 16 2' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Crect fill='%23c27e00' fill-rule='evenodd' height='2' rx='1' width='16'/%3E%3C/svg%3E");
      }
    }
  }

  .faq__answer {
    padding-left: 24px;
    margin-top: var(--space-8);
    font-size: var(--font-size-18);
    line-height: var(--line-height-26);
    @include mobile {
      font-size: var(--font-size-15);
      line-height: var(--line-height-22);
    }

    &.article-content ol,
    &.article-content ul {
      margin-left: 28px !important;
    }

    &.article-content .media-image + p {
      margin-top: 8px;
    }
  }

}
