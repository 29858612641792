.sports-teaser {
  @include animate-image-on-hover;
  position: relative;
  background-color: var(--light-black);
  //height: 280px;
  //padding: var(--space-20);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--space-12);
  overflow: hidden;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;

  @include mobile {
    width: 100%;
  }

  .image {
    display: block;
    border-radius: var(--space-12);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
  }

  .sports-teaser__button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    button {
      @include btn;
      @include btn--border-yellow;
      color: var(--white);
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.sports-teaser:hover {
  overflow: hidden;
  text-decoration: none;
  background-size: 108%;

  &:after {
    display: none;
  }

  button {
    background-color: var(--yellow);
    color: var(--black);
  }

  .sports-teaser__bg {
    transform: scale(1.1);
  }
}
