.marquee--events.marquee--bottom {
  .marquee__content {
    padding-bottom: 40px;
  }
}

.marquee--events {
  .text-color-yellow {
    letter-spacing: 8px;
    @include touch {
      display: block;
    }
  }
}

.marquee--events.marquee--mobile-bg-divided {
  @include touch {
    --mobile-marquee-min-height: 224px;
    .marquee__title {
      font-size: 32px;
      .text-color-yellow {
        font-size: 28px;
        margin-top: -48px;
        margin-bottom: 8px;
      }
    }
    .marquee__content {
      padding-top: 8px;
      padding-bottom: 30px;
    }
    .marquee__background {
      position: relative;
      &::after {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
      }
    }
  }
}

.events-list {
  padding: 60px 0;
  @include mobile {
    padding: 40px 0;

  }

  .container {
    max-width: 900px;
  }
}

.events-list__content .view-rows {
  > * + * {
    margin-top: 20px;
  }
}

.event-item {
  display: grid;
  grid-template-columns: 256px 1fr;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  @include mobile {
    grid-template-columns: 1fr;
  }
}

.event-item__left {
  background-color: var(--yellow);
  padding: 32px 24px 20px 24px;
  @include mobile {
    padding: 20px 16px 12px 12px;
  }
}

.event-item__date-pill {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.75px;
  line-height: 1;
  @include mobile {
    text-align: right;
  }
  span {
    display: block;
    font-size: 42px;
    margin-top: 4px;
    font-weight: normal;
    letter-spacing: normal;
    @include mobile {
      font-size: 32px;
      text-align: left;
      margin-top: -10px;
    }
  }
}

.event-item__right {
  padding: 32px 26px 20px 32px;
  @include mobile {
    padding: 12px;
  }
}

.event-item__meta-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-top: 12px;
  font-weight: bold;
  @include mobile {
    margin-top: 8px;
  }
}


.event-item__meta-icon {
  display: inline-block;
  height: 18px;
  margin-top: -3px;
}

.event-item__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.event-item__name {
  font-size: 24px;
  line-height: 26px;
  font-weight: 300;
  @include mobile {
    font-size: 20px;
    line-height: 24px;
  }
}

.event-item__description {
  margin-top: 12px;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  @include mobile {
    margin-top: 8px;
  }
}

.event-item__footer {
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 8px;
  @include mobile {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }
}

.event-item__cta {
  @include mobile {
    width: 100%;
  }
  .btn {
    @include mobile {
      width: 100%;
    }
  }
}

.event-item__taxonomy {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  padding-right: 20px;
}

.event-item__category {
  background-color: #E9E9E9;
  border-radius: 4px;
  padding: 6px 12px;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 1.25px;
  font-weight: bold;
  text-transform: uppercase;
  @include mobile {
    font-size: 10px;
    letter-spacing: .25px;
  }
}

.path-event .event-item {
  max-width: 900px;
  margin: 40px auto;
}
