/*
---
name: Textarea
category: Form
---

```textarea.html
<textarea>Textarea content</textarea>
```
*/
textarea {
  @include input;
  @include input-bordered;
  @include text-base;
  font-weight: normal;
  padding: var(--space-20);
  border-radius: 10px;
}
