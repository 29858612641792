/*
---
name: Header
category: Components
---

```example.html
<header class="header" role="banner">
  <div class="header__logo">
    <a href="/">
      <img src="assets/images/logo.svg" />
    </a>
  </div>

  <button class="header__menu-toggle">
    <svg height="13" viewBox="0 0 21 13" width="21" xmlns="http://www.w3.org/2000/svg"><path d="m0 .5h21m-21 6h21m-21 6h21" fill="none" stroke="#000002" stroke-linecap="round"/></svg>
  </button>

  <div class="header__right">
    <nav class="main-nav">
      <ul>
        <li><a href="">First item</a></li>
        <li><a href="">Second item</a></li>
        <li><a href="">Third item</a></li>
        <li><a href="">Fourth item</a></li>
      </ul>
    </nav>

    <nav class="top-right-nav">
      <ul>
        <li>
          <a href="/"><svg height="31" viewBox="0 0 31 31" width="31" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" transform="translate(1 1)"><circle cx="14.5" cy="14.5" fill="#fff" r="14.5" stroke="#ffa500"/><g stroke="#333" stroke-linecap="round"><path d="m12.5 9.5c1.6866048-1.19262695 3.1963298-1.50211589 4.5291748-.9284668 1.9992676.86047364 2.1080615 3.5461039.8015137 4.6246338-1.3065479 1.0785299-3.3306885 2.4051514-3.3306885 3.6623535v1.6414795"/><path d="m14.5 21.5v1"/></g></g></svg></a>
        </li>
        <li>
          <a href="/"><svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle cx="16" cy="16" fill="#fff" r="15" stroke="#ffa500"/><g stroke="#333"><circle cx="14.944828" cy="14.944828" fill="#fff" r="6.155172"/><path d="m19.34138 19.34138 4.044828 4.044828" stroke-linecap="round" stroke-linejoin="round"/></g></g></svg></a>
        </li>
      </ul>
    </nav>
  </div>
</header>
```
*/
.header-wrapper {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #fff;
}

.toolbar-vertical .header-wrapper {
  position: static;
}

.header {
  display: block;
  background-color: var(--white);
  position: relative;
}

.header__desktop-search {
  @include very-dark-background;
  padding: var(--space-28) var(--space-80);
  border-top: 1px solid var(--darkest-grey);
  border-bottom: 1px solid var(--darkest-grey);
  display: block;
  position: absolute;
  bottom: -113px;
  left: 0;
  right: 0;
  z-index: -1;
  width: 100%;
}

.header__mobile-search {
  padding: var(--space-20);
  @include middle {
    display: none;
  }
}

.header__row {
  display: flex;
  position: relative;

  @include touch {
    background-color: var(--yellow);
    height: var(--space-45);
  }
}

.header__logo {
  a {
    display: block;
  }

  img {
    width: 226px;
    height: 74px;

    @include touch {
      width: 137px;
      height: var(--space-45);
    }
  }

  @media print {
    margin-top: var(--space-20);
  }
}

.header__logo--150 {
  img {
    width: 313px;
    height: 75px;
    @include touch {
      width: auto;
      height: var(--space-45);
    }
  }
}

.header__right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  @include touch {
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 100%;
  }

  @include middle {
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.header__menu-toggle.header__menu-toggle {
  display: none;

  &.is-active {
    background-color: #fff;
    opacity: 1;
  }

  @include touch {
    display: block;
    margin-left: auto;
  }
}

.header--dark {
  background-color: var(--yellow);
  color: #000002;
  border-top: 4px solid #000002;

  .header__right {
    background-color: var(--yellow);
    @include touch {
      background-color: #000002;
    }
  }

  .header__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: var(--space-20);
    @include touch {
      padding-left: var(--space-12);
    }

    img {
      width: 186px;
      height: auto;

      @include touch {
        width: 134px;
        height: auto;
      }
    }
  }

  .header__right {
    padding-right: var(--space-20);
    @include touch {
      padding-right: 0;
    }
  }

  .header__row {
    @include touch {
      background-color: var(--yellow);
    }
  }

  .hamburger {
    opacity: 1;
    &:hover, &:focus {
      opacity: 1;
    }
  }

  .hamburger {
    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before,
    &.is-active .hamburger--inner,
    &.is-active .hamburger-inner::after {
      background-color: var(--dark-black);
      height: 2px;
    }
    &.is-active .hamburger-inner::before {
      display: none;
    }
  }

  .header__menu-toggle.header__menu-toggle {
    &.is-active {
      background-color: var(--yellow);
    }
  }
}

.promo-bar + .header--dark {
  border-top: 0;
}
