/*
---
name: Input
category: Form
---

## Regular input
```input.html
<input type="text"  value="Text value" />
```

```large-input.html
<input class="input--large" type="text" value="Text value" />
```

```disabled-input.html
<input disabled type="text"  value="Text value" />
```


## Underlined input
```underlined-input.html
<input class="input--underlined" type="text" value="Text value" />
```

```underlined-input-large.html
<input class="input--underlined input--large" type="text" value="Text value" />
```
*/
@mixin inputText {
  font-size: var(--font-size-14);
  font-weight: bold;
  letter-spacing: var(--letter-spacing-1_25);
  line-height: 1;
  color: var(--input-text-color, var(--black));
  background-color: var(--input-background-color, var(--white));
}

@mixin input {
  @include inputText;
  display: block;
  width: 100%;
  padding: var(--input-padding-y, var(--space-13)) 0;
  vertical-align: middle;

  border: 0;
  border-radius: 0;

  &:focus {
    border-color: var(--blue) !important;
  }

  &:disabled {
    color: var(--grey) !important;
    border-color: var(--grey);
    cursor: not-allowed;
  }

  &::placeholder {
    @include text-cta;
    font-size: var(--input-placeholder-font-size, var(--font-size-12));
    color: var(--input-placeholder-color, var(--darkest-grey));
  }
}

@mixin input-inverted {
  --input-border-color: var(--white);
  --input-placeholder-color: var(--white);
  --input-text-color: var(--white);
  --input-background-color: transparent;
}

@mixin input-bordered {
  border: 1px solid var(--input-border-color, var(--black));
  border-radius: 25px;
  padding-left: var(--input-padding-x, var(--space-20));
  padding-right: var(--input-padding-x, var(--space-20));
}

@mixin input-underlined {
  border-bottom: 1px solid var(--input-border-color, var(--black));
}

@mixin input-large {
  font-size: var(--font-size-30);
  font-weight: 300;
  text-transform: none;
  padding-top: var(--input-padding-y, var(--space-4));
  padding-bottom: var(--input-padding-y, var(--space-4));

  @include mobile {
    font-size: var(--font-size-24);
  }
}

input[type=text]:not(.input--underlined),
input[type=search]:not(.input--underlined),
input[type=number]:not(.input--underlined),
input[type=email]:not(.input--underlined),
input[type=tel]:not(.input--underlined),
input[type=password]:not(.input--underlined),
input[type=date]:not(.input--underlined) {
  @include input;
  @include input-bordered;
}

.input--underlined {
  @include input;
  @include input-underlined;
}

.input--inverted.input--inverted {
  @include input-inverted;
}

.input--large {
  @include input-large;
}

label[disabled] {
  color: var(--grey);
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  background-image: url("data:image/svg+xml,%3Csvg height='20' viewBox='0 0 20 20' width='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m18 1.125-16.875 16.875m-.5625-17.4375 16.875 16.875' fill='none' stroke='%23c27e00' stroke-width='2' transform='translate(1 1)'/%3E%3C/svg%3E");
  background-size: contain;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 1;
  pointer-events: all;
}
