/*
---
name: Breadcrumb
category: Components
---

```breadcrumb.html
  <nav aria-label="Breadcrumb" class="breadcrumb">
  <ol>
    <li>
      <a href="../../">
        WAI-ARIA Authoring Practices 1.1
      </a>
    </li>
    <li>
      <a href="../../#aria_ex">
        Design Patterns
      </a>
    </li>
    <li>
      <a href="../../#breadcrumb">
        Breadcrumb Pattern
      </a>
    </li>
    <li>
      <a href="./index.html" aria-current="page">
        Breadcrumb Example
      </a>
    </li>
  </ol>
</nav>
```
*/
.breadcrumb {
  padding: var(--space-20);
  min-height: var(--breadcrumb-min-height);
  @include mobile {
    min-height: auto;
    padding: var(--space-12);
  }
}

.breadcrumb ol {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb li {
  display: inline;
  @include text-cta;
  font-weight: bold;
}

.breadcrumb li + li::before {
  display: inline-block;
  margin: 0 var(--space-8) 0 var(--space-8);
  transform: rotate(15deg);
  border-right: 1px solid var(--dark-yellow);
  height: 0.8em;
  content: '';
}

.breadcrumb [aria-current="page"] {
  font-weight: normal;
  text-decoration: none;
}
