.schedule {
  border-top: 1px solid var(--yellow);
}

.schedule__body {
  display: flex;
  justify-content: space-between;
  @include mobile {
    display: block;
  }
}

.schedule__content {
  flex-grow: 1;
  padding-left: var(--space-60);
  padding-right: var(--space-38);
  padding-top: var(--space-80);
  @include mobile {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}

.schedule__image {
  @include mobile {
    margin-top: var(--space-38);
    img {
      width: 100%;
      height: auto;
    }
  }
}

.schedule__header {
  margin-bottom: var(--space-38);
  max-width: 50ch;

  @include mobile {
    max-width: none;
  }

  > * + * {
    @include mobile {
      margin-top: var(--space-8);
    }
  }
}

.schedule__title {
  @include as-h1;
}

.schedule__subtitle {
  @include text-xl;
  @include mobile {
    @include text-base;
  }
}

.schedule__table {
  max-width: 460px;
  @include mobile {
    max-width: none;
  }
  caption {
    display: none;
  }

  table {
    @include regular-shadow;
    text-align: left;
    border-radius: var(--space-12);
    width: 100%;

    th, td {
      padding: var(--space-16) 0 var(--space-16) var(--space-20);
      &:last-child {
        padding-right: var(--space-20);
      }
    }

    th {
      @include text-cta;
      display: table-cell;
      text-transform: uppercase;
      padding-top: var(--space-16);
    }

    thead {
      @include regular-border-bottom;
    }
  }

  [data-collapsible-table-toggle] {
    @include regular-border-top;
    button {
      padding: 0;
    }
  }
}

.schedule__table-header {
  @include as-h5;
  margin-bottom: var(--space-8);
}
