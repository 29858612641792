.has-mobile-menu-open {
  @include touch {
    overflow-y: hidden;
  }
}

con-header-menu {
  @include touch {
    display: none;
    background-color: var(--white);
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    z-index: 100;
    overflow-y: scroll;

    position: absolute;
    top: var(--space-45);
    left: 100%;
    transition: .2s ease;

    &.open {
      display: block;
      left: 0;
      padding-bottom: 45px; // Compensates mobile header height.
    }
  }
}

con-header-menu[active-level="first"] {
  @include touch {
    [data-menu-top-item]:not([data-menu-item-open]) {
      display: none;
    }
  }
}

con-header-menu[active-level="second"] {
  @include touch {
    [data-menu-top-item]:not([data-menu-item-open]) {
      display: none;
    }
  }
}

con-header-menu[active-level="first"] [data-menu-back-item],
con-header-menu[active-level="second"] [data-menu-back-item] {
  @include touch {
    display: block;
    a {
      @include mobile-nav-item-with-children-expanded;
    }
  }
}

con-header-menu [data-menu-top-item-expandable] {
  @include touch {
    display: block;
    width: 100%;
    [data-menu-dropdown] {
      display: none;
    }
  }
}

con-header-menu [data-menu-top-item-expandable][data-menu-item-open] {
  @include touch {
    [data-menu-dropdown] {
      display: block;
    }
  }
}

con-header-menu[active-level="second"] {
  [data-menu-second-level-item]:not([data-menu-item-open]) {
    @include touch {
      display: none;
    }
  }
}
