.tabs [aria-label="tablist"] {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: auto;

  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include mobile {
    justify-content: flex-start;
  }
}

.tabs__tab + .tabs__tab {
  margin-left: var(--space-60);
}

.tabs__tab {
  @include text-cta;
  display: block;
  padding: var(--space-12);
  position: relative;

  &:after {
    content: '';
    display: block;
    height: 5px;
    width: 0;
    background: transparent;
    transition: width .5s ease, background-color .5s ease;

    left: 0;
    bottom: 0;
    position: absolute;
  }
}

.tabs__tab[selected],
.tabs__tab:not([disabled]):hover {
  &:after {
    width: 100%;
    background: var(--dark-yellow);
  }
}

.tabs__tab[disabled] {
  color: var(--darkest-grey);
  cursor: not-allowed;
}
