.stores-menu-item {
  display: flex;

  > * + * {
    margin-left: 150px;
    @include touch {
      margin-left: 0;
    }
  }

  @include touch {
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-column-gap: var(--space-80);
  }

  @include mobile {
    display: block;
  }
}

.stores-menu-item__section {
  @include mobile {
    padding: var(--space-32) var(--space-20);
  }
}

.stores-menu-item__section + .stores-menu-item__section {
  @include mobile {
    @include regular-border-top;
  }
}

.header--dark {
  .stores-menu-item__section + .stores-menu-item__section {
    @include mobile {
      border-color: var(--darkest-grey);
    }
  }
}
