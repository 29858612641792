.tire-teaser {
  background: var(--white);
  border-radius: 0.75rem;
  padding: var(--space-32) var(--space-20);
  display: flex;
  @include regular-shadow;

  @include mobile {
    padding: var(--space-20) var(--space-24) var(--space-28) var(--space-24);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    @include regular-shadow-hover;
  }
}

.tire-teaser__left {
  width: 170px;
  margin-right: var(--space-28);
  flex-shrink: 0;

  @include mobile {
    margin-right: 0;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    @include hover-reset;
  }

  img {
    width: 100%;
    height: auto;
    @include mobile {
      max-width: 100px;
    }
  }
}

.tire-teaser__right {
  width: 100%;
  position: relative;
  flex-grow: 1;
  padding-top: var(--space-8);

  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: var(--space-16);
  }

  & > * + * {
    margin-top: var(--space-8);
  }

  .tire-best-for {
    @include mobile {
      margin-top: 0;
    }
  }
}

.tire-teaser__header {
  display: flex;
  justify-content: space-between;
  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.tire-teaser__header-left {
  margin-right: var(--space-16);
  @include mobile {
    margin-right: 0;
  }

  & > * + * {
    margin-top: var(--space-8);
    @include mobile {
      margin-top: var(--space-4);
    }
  }
}

.tire-teaser__header-right {
  margin-left: auto;
  position: relative;
  top: calc(-1 * var(--space-20));
  @include mobile {
    top: 0;
    margin-left: 0;
  }
}

.tire-teaser__title {
  @include as-h4;
  @include mobile {
    @include as-h6;
    text-align: center;
  }
  .tag {
    @include mobile {
      font-size: var(--font-size-16);
    }
  }
}

.tire-teaser__cta {
  @include mobile {
    margin-top: var(--space-20);
    width: 100%;
  }
}
