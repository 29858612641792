.estimate-payments__wrapper {
  background-color: var(--white);
}

.estimate-payments__container {
  max-width: 990px;
  margin: 0 auto;
}

.estimate-payments {
  background-color: var(--white);
  color: var(--black);
  padding: var(--space-20);
  border-radius: 10px;
  display: flex;
  @include mobile {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  > * + * {
    margin-left: var(--space-20);
    @include mobile {
      margin-left: 0;
      margin-top: var(--space-12);
    }
  }
}

.estimate-payments__container .estimate-payments {
  align-items: center;
  justify-content: center;
}


.estimate-payments__icon {
  display: block;
  flex-shrink: 0;
}

.estimate-payments__texts {
  text-align: left;
  @include mobile {
    text-align: center;
  }
  * {
    display: inline;
    font-size: var(--font-size-18);
    line-height: var(--line-height-26);
    @include mobile {
      font-size: var(--font-size-15);
      line-height: var(--line-height-22);
    }
  }
}

.estimate-payments__title {
  font-weight: bold;
  margin-right: var(--space-4);
}

.estimate-payments__button {
  flex-shrink: 0;
  @include mobile {
    width: 100%;
  }
  .btn {
    @include mobile {
      width: 100%;
    }
  }
}
