form > * + * {
  margin-top: var(--space-12);
}

con-dependent-selects {
  display: grid;
  grid-gap: var(--space-12);
}

fieldset {
  legend {
    font-weight: bold;
  }
  legend + * {
    margin-top: var(--space-8);
  }
}
