.nav-tabs {
  padding-top: var(--space-8);
  ul {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    overflow: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      display: none;
    }

    > li + li {
      margin-left: var(--space-38);
      @include mobile {
        margin-left: var(--space-20);
      }
    }

    a {
      @include text-cta;
      display: block;
      --padding: 10px;
      padding: var(--padding) 0;

      &:after {
        content: '';
        display: block;
        height: 5px;
        width: 0;
        background: transparent;
        transition: width .5s ease, background-color .5s ease;

        bottom: -10px;
        position: relative;
      }

      &:hover {
        text-decoration: none;
      }
    }

    a:hover,
    .active-trail a,
    .active a {
      padding-bottom: calc(var(--padding-bottom) - 5px);
      //border-bottom: 5px solid var(--yellow);
      &:after {
        width: 100%;
        background: var(--yellow);
      }
    }
  }

  .contextual {
    display: none;
  }
}

.nav-tabs--with-down-arrows {
  a {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 3px;
      transform: translateX(-50%);
      width: 12px;
      height: 6px;
      background-image: url("data:image/svg+xml,%3Csvg height='6' viewBox='0 0 12 6' width='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.1875-2.1875 5.625 5-5.625 5' fill='none' stroke='%23ffa500' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 1 1 0 3.1875 -2.1875)'/%3E%3C/svg%3E");
    }
  }
}

.nav-subtabs {
  ul {
    display: flex;
    position: relative;
    justify-content: center;

    > * + * {
      margin-left: var(--space-12);
    }

    a {
      @include btn;
      @include btn--sm;
      @include btn--border-black;
    }

    .active a {
      @include btn--black;
    }
  }
}

.nav-tabs + .nav-subtabs {
  margin-top: var(--space-38);
}

.nav-tabs {
  .nav-tabs__item {
    @include mobile {
      padding-left: var(--space-12);
      padding-right: var(--space-12);
    }
  }
  ul .nav-tabs__item--has-bottom-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      margin-left: 0 !important;
      width: 12px;
    }
    padding-bottom: var(--padding-bottom);

    &:hover:after {
      display: none;
    }
  }
}
