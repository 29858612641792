.field--inline {
  display: inline-flex;
  width: 100%;
  align-items: center;

  label {
    font-size: var(--font-size-12);
    font-weight: bold;
    margin-right: var(--space-8);
    text-transform: initial;
  }

  select, con-select, textarea, input {
    flex: 1;
  }
}

.field--transparent {
  --con-field-float-label-background: var(--background-color, var(--black));
  select, con-select, textarea, input {
    background-color: transparent;
  }
}

.field__help {
  font-size: var(--font-size-12);
  padding-left: var(--space-28);
  margin-top: var(--space-8);
}

.field__error {
  color: var(--red);
  font-weight: bold;
}

con-field[disabled] {
  .field__help {
    color: var(--grey) !important;
  }
}
