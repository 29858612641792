/*
---
name: Panel
category: Components
---

```panel.html
<div class="panel">
  <div class="panel__title">
    Usage guidelines
  </div>
  <div class="panel__body">
    <p>Our logo consists of the wordmark and the horse symbol.</p>
  </div>
</nav>
```

## Without the top border:
```panel-no-border.html
<div class="panel panel--no-border">
  <div class="panel__title">
    Usage guidelines
  </div>
  <div class="panel__body">
    <p>Our logo consists of the wordmark and the horse symbol.</p>
  </div>
</nav>
```
*/
.panel {
  @include regular-border-top;
  padding-top: var(--space-28);
  & > * + * {
    margin-top: var(--space-20);
  }
}

.panel--no-border {
  border: 0;
}

.panel__title {
  @include text-cta;
  @include mobile {
    text-align: center;
  }
}
