.field__type-webform-image-file {
  > * + * {
    margin-top: var(--space-4);
  }

  label {
    //@include text-base;
    font-weight: bold;
    text-transform: none;
  }

  .field__description {
    margin-left: 0;
    font-weight: bold;
    font-style: italic;
    > * {
      display: inline;
    }
    br {
      display: none;
    }
  }
}

.form-managed-file {
  display: flex;
  align-items: center;
  justify-content: space-between;

  input[type=file] {
    width: 100%;
  }

  [type=submit] {
    @include btn;
    @include btn--border-yellow;
    flex-grow: 0;
    max-width: 120px;
  }


}
