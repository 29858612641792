#pr-reviewimagedisplay > .p-w-r {
  .pr-rid .pr-rid-btn-container {
    .pr-rid-btn-text {
      color: var(--black);
    }

    .pr-ellipsis-icon {
      background-color: var(--dark-yellow);
      border: 2px solid var(--dark-yellow);
    }

    .pr-ellipsis-icon__circle__inner {
      stroke: var(--dark-yellow);
    }
  }

  .pr-rid .pr-rid-btn-container:hover, .pr-rid .pr-rid-btn-container:focus {
    color: var(--dark-yellow);
    .pr-rid-btn {
      color: var(--dark-yellow);
    }

    .pr-ellipsis-icon {
      background-color: var(--white);
    }

    .pr-ellipsis-icon__circle__inner {
      fill: var(--dark-yellow);
    }
  }
}

.tire-reviews__embed > .p-w-r,
#pr-reviewlist > .p-w-r,
#pr-questiondisplay > .p-w-r {
  @include text-base;
  font-family: var(--font-family);
  margin: 0 auto;
  max-width: 700px;

  p {
    @include text-base;
  }

  * {
    color: var(--black);
  }

  .form-control {
    padding-left: var(--space-26) !important;
    padding-right: var(--space-26) !important;
  }

  .pr-has-error .pr-control-label span,
  .pr-has-error .control-label span,
  .pr-has-error .form-control,
  .pr-has-error .form-control:focus {
    border-color: var(--red);
    color: var(--red);
  }

  .pr-has-error .pr-form-control-error-wrapper {
    position: relative;

    .pr-control-label span {
      color: var(--red) !important;
    }

    &:after {
      display: block;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg height='20' viewBox='0 0 20 20' width='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23ff2d37' stroke-linejoin='round'%3E%3Cpath d='m10.8944272 1.78885438 8.381966 16.76393202c.2469893.4939785.0467649 1.0946515-.4472136 1.3416408-.1388554.0694277-.2919685.1055728-.4472136.1055728h-16.76393201c-.55228475 0-1-.4477153-1-1 0-.1552451.03614509-.3083582.10557281-.4472136l2.70061195-5.4012239 5.68135406-11.36270812c.24698925-.4939785.84766229-.69420284 1.34164079-.44721359.1935274.0967637.3504499.25368619.4472136.44721359z'/%3E%3Cg stroke-linecap='round' stroke-width='2'%3E%3Cpath d='m10 13.1264648v-4.1264648'/%3E%3Cpath d='m10 16.4396973v-.4396973'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      width: 20px;
      height: 20px;
      position: absolute;
      top: 10px;
      right: 10px;
      //transform: translateY(-50%);
    }

    svg {
      display: none;
    }
  }

  .pr-btn {
    @include text-cta;
    border-radius: var(--space-20);
    background-color: transparent;
    border: 1px solid var(--grey);
    padding-left: var(--space-26);
    padding-right: var(--space-26);

    &.active {
      background-color: var(--yellow);
      border-color: var(--yellow);
    }
  }

  .btn-group-radio-horizontal {
    .pr-btn {
      padding-left: var(--space-20);
      padding-right: var(--space-20);
      height: 44px;
    }
  }

  .pr-btn-fileinput {
    background-image: url("data:image/svg+xml,%3Csvg height='20' viewBox='0 0 20 20' width='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23333' stroke-linecap='round'%3E%3Cpath d='m10 .6v13.6'/%3E%3Cpath d='m4.6 5.8 5.42-5.6 5.38 5.6' stroke-linejoin='round'/%3E%3Cpath d='m1 18.8h18'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: left 25px top 11px;
    padding-left: 55px;
    padding-top: var(--space-12);
    padding-bottom: var(--space-12);

    svg {
      display: none;
    }
  }

  .pr-facebook-btn {
    background-image: url("data:image/svg+xml,%3Csvg height='20' viewBox='0 0 10 20' width='10' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='m18 9.06575223h-3.4789697v-1.65904637c0-.78031788.4841865-.96233399.8249104-.96233399h2.4356048v-3.43305029l-3.5286925-.01132158c-3.2001957 0-3.8074666 2.55954714-3.8074666 4.19769214v1.86806009h-2.4453864v3.48356197h2.4453864v10.4506858h4.0756439v-10.4506858h3.139061z'/%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse fill='%23fff' fill-rule='evenodd' xlink:href='%23a'/%3E%3C/mask%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(-8 -3)'%3E%3Cuse fill='%23fff' xlink:href='%23a'/%3E%3Cg mask='url(%23b)'%3E%3Cpath d='m0 0h20v20h-20z' fill='%23000' transform='translate(3 3)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: 8px;
    padding-left: 40px;
  }

  .pr-instagram-btn {
    background-image: url("data:image/svg+xml,%3Csvg height='20' viewBox='0 0 20 20' width='20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='m19.5813953 4c2.440328 0 4.4186047 1.97827669 4.4186047 4.41860465v11.16279065c0 2.440328-1.9782767 4.4186047-4.4186047 4.4186047h-11.16279065c-2.44032796 0-4.41860465-1.9782767-4.41860465-4.4186047v-11.16279065c0-2.44032796 1.97827669-4.41860465 4.41860465-4.41860465zm-5.5813953 5.47169811c-2.5009121 0-4.52830189 2.02738979-4.52830189 4.52830189s2.02738979 4.5283019 4.52830189 4.5283019 4.5283019-2.0273898 4.5283019-4.5283019-2.0273898-4.52830189-4.5283019-4.52830189zm5.6603774-2.26415094c-.6252281 0-1.1320755.50684745-1.1320755 1.13207547s.5068474 1.13207547 1.1320755 1.13207547c.625228 0 1.1320754-.50684745 1.1320754-1.13207547s-.5068474-1.13207547-1.1320754-1.13207547z'/%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse fill='%23fff' fill-rule='evenodd' xlink:href='%23a'/%3E%3C/mask%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(-4 -4)'%3E%3Cuse fill='%23ffa500' fill-rule='nonzero' xlink:href='%23a'/%3E%3Cg mask='url(%23b)'%3E%3Cpath d='m0 0h20v20h-20z' fill='%23000' transform='translate(4 4)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: 16px;
    padding-left: 45px;
  }

  .pr-rd-main-header {
    padding-top: 0;
    margin-bottom: var(--space-22);
    position: relative;
  }

  .pr-rd-review-header-contents {
    background-color: var(--lightest-grey);
    position: relative;

    padding: var(--space-20) 1rem;
    //  margin: 0 -1rem;
    //    width: calc(100vw - 1rem);
  }

  .pr-qa-display {
    padding: 0;
  }

  .pr-qa-display-searchbar,
  .pr-rd-search-reviews-input {
    input {
      @include input;
      @include input-bordered;
      border-color: var(--dark-grey);
      height: 44px !important;

      &::placeholder {
        @include text-cta;
        color: var(--black);
      }
    }

    .pr-rd-search-reviews-clear-button {
      display: none;
    }

    .pr-qa-search-btn,
    .pr-rd-search-reviews-icon-button {
      height: 44px !important;
      width: 60px;
      border: 0;
      border-radius: 25px;
      padding: 0 var(--space-16);
      background-color: transparent;
      background-image: url("data:image/svg+xml,%3Csvg height='18' viewBox='0 0 18 18' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23333'%3E%3Ccircle cx='7.8' cy='7.8' fill='%23fff' r='7'/%3E%3Cpath d='m12.8 12.8 4.6 4.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E");
      background-position: center center;
      background-repeat: no-repeat;

      .pr-search-icon {
        display: none;
      }
    }
  }

  .pr-filter-btn--button .pr-filter-btn--active,
  .pr-filter-btn--button:active,
  .pr-filter-btn--button:hover {
    background-color: var(--yellow);

    .pr-cross-icon__circle {
      fill: var(--yellow);
    }
  }

  select {
    border-radius: 2rem;
    border: 1px solid var(--grey);
    font-weight: bold;
    letter-spacing: 0;
    padding-left: var(--space-20);
    font-size: var(--font-size-12);
    background-image: url("data:image/svg+xml,%3Csvg height='6' viewBox='0 0 10 6' width='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6.5-2-4.5 4 4.5 4' fill='none' stroke='%23333' stroke-linecap='round' stroke-linejoin='round' transform='matrix(0 -1 -1 0 7.25 7.25)'/%3E%3C/svg%3E");
    background-size: 10px;
    height: 36px;
    min-width: 172px;
    max-width: 100%;
  }

  textarea {
    @include input;
    @include input-bordered;
    border-radius: var(--space-12);
  }

  select, input[type=text], input[type=email], textarea {
    border-color: var(--dark-grey);
  }

  input[type=text], input[type=email] {
    @include input;
    @include input-bordered;
    border-color: var(--dark-grey);
    height: 44px !important;
  }

  .pr-snippet-stars {
    display: flex;
    align-items: center;

    .pr-snippet-rating-decimal {
      border: 0;
    }
  }

  .pr-star-v4 {
    background-image: url("data:image/svg+xml,%3Csvg height='26' viewBox='0 0 28 26' width='28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m146 1.54294637 2.899853 8.46574903 8.947509.1418633-7.155302 5.373984 2.630012 8.5534254-7.322072-5.1444442-7.322072 5.1444442 2.630012-8.5534254-7.155302-5.373984 8.947509-.1418633z' fill='none' stroke='%23C27D00' transform='translate(-132)'/%3E%3C/svg%3E");
  }

  .pr-star-v4-100-filled {
    background-image: url("data:image/svg+xml,%3Csvg height='26' viewBox='0 0 28 26' width='28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m146 19.5445964-8.228994 5.7816415 2.95577-9.6128634-8.041567-6.03961242 10.055759-.15943476 3.259032-9.51432732 3.259032 9.51432732 10.055759.15943476-8.041567 6.03961242 2.95577 9.6128634z' fill='%23C27D00' fill-rule='evenodd' transform='translate(-132)'/%3E%3C/svg%3E");
  }

  .pr-rd-review-header-sorts {
    @include regular-border-bottom;
    padding: var(--space-20) 0;
  }

  .pr-rd-sort-group {
    margin-top: 0;
    padding-top: 0;
  }

  .pr-qa-display {
    display: flex;
    flex-direction: column;
  }

  .pr-qa-display .pr-qa-display-sort:after,
  .pr-qa-display .pr-qa-display-sort:before {
    display: none;
  }


  .pr-qa-sort-group {
    display: flex;
    align-items: center;
    margin-top: 0;
    padding-top: 0;

    label {
      flex-shrink: 0;
      margin-bottom: 0;
    }

    &:after {
      display: none;
    }
  }

  .pr-qa-display-questionlist,
  .pr-review {
    @include regular-border-bottom;
    margin-bottom: 0;
  }

  .pr-qa-display-questionlist + .pr-qa-display-questionlist,
  .pr-review + .pr-review {
    margin-top: var(--space-38);
  }

  .pr-review-display .pr-rd-footer {
    padding-bottom: var(--space-28);
  }

  .pr-rd-main-footer {
    padding: var(--space-20) 0 0 0;
    border-bottom: 0;

    .pr-rd-content-block {
      display: flex;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
    }

    .pr-rd-review-position {
      margin-right: var(--space-12);
      margin-top: 0;
      margin-bottom: 0;
    }

    .pr-rd-pagination {
      flex: 1 0 100%;
      border: 0;
      margin-top: 0;

      .pr-rd-pagination-btn {
        @include text-cta;
      }
    }
  }

  .pr-rd-to-top {
    @include text-cta;
    text-decoration: none;
    border-left: 1px solid var(--grey);
    padding-left: var(--space-12);
  }

  .pr-rd-review-total {
    font-size: var(--font-size-14);
    display: block;
    text-align: center;
  }

  .pr-rd-header {
    margin-bottom: var(--space-12);

    .pr-rd-star-rating {
      display: block;
      float: none;
    }

    .pr-rd-review-headline {
      margin-left: 0;
      margin-top: var(--space-8);
    }
  }

  .pr-rd-description {
    margin-bottom: var(--space-4);
  }

  .pr-rd-inner-content-block {
    .pr-rd-bold {
      display: block;
    }
  }

  .pr-rd-helpful-action-group {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    //flex-direction: row;
    //display: block;
    .pr-rd-helpful-action-btn-group {
      margin-right: var(--space-12);
    }
  }

  .pr-rd-helpful-action-legend {
    flex: 1 0 100%;
    width: 100%;
    margin-bottom: var(--space-4);
  }

  // Like/dislike buttons.
  .pr-rd-helpful-action-btn-group, .pr-helpful-voting {
    margin-left: 0;
    display: flex;
    align-items: center;

    .pr-helpful-btn {
      margin: 0;
      height: 32px;
      line-height: 1;
      box-sizing: border-box;
      padding: 0 var(--space-8);
      display: flex;
      align-items: center;
      justify-content: center;

      svg * {
        fill: var(--grey);
      }
    }

    .pr-helpful-yes {
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
      border-color: var(--black);
      border-bottom-width: 1px;
    }

    .pr-helpful-no {
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      border-color: var(--black);
      border-bottom-width: 1px;
    }

    .pr-helpful-active {
      background: var(--yellow) !important;

      svg * {
        fill: var(--darkest-grey);
      }
    }

    .pr-helpful-count {
      font-size: var(--font-size-12);
    }
  }

  .pr-rd-flag-review-container {
    .pr-underline {
      @include text-cta;
      text-decoration: none;
    }
  }

  .pr-btn-show-more,
  .pr-accordion-btn {
    position: relative;
    padding-left: var(--space-20);
    margin-left: 0;

    &:before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg height='11' viewBox='0 0 11 11' width='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6.5 1v11m-5.5-5.5h11' fill='none' stroke='%23333' stroke-linecap='round' transform='translate(-1 -1)'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      width: 12px;
      height: 12px;
      position: absolute;
      left: 0;
      top: 1px;
    }

    span {
      @include text-cta;
    }

    .pr-caret-icon {
      display: none;
    }
  }

  .pr-btn-show-more {
    &:before {
      top: 4px;
    }
  }

  .pr-accordion-btn[aria-expanded="true"] {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg height='2' viewBox='0 0 13 1' width='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1 7h11' fill='none' stroke='%23000' stroke-linecap='round' transform='translate(0 -6)'/%3E%3C/svg%3E");
    }
  }

  .pr-rd-subratings {
    margin-left: var(--space-20);

    dt, dd {
      @include regular-border-bottom;
      padding: var(--space-12) 0;
    }
  }


  .pr-rd-reviewer-type {
    display: flex;
    align-items: center;
  }

  .pr-badging-icon {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg height='22' viewBox='0 0 22 22' width='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%232db928' transform='translate(1 1)'%3E%3Ccircle cx='10' cy='10' r='10'/%3E%3Cpath d='m6 11 3 3 6-7' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    height: 22px;
    width: 22px;

    svg {
      display: none;
    }
  }

  .pr-rd-badging-text {
    font-weight: bold;
    padding-left: var(--space-8);
  }

  .pr-rd-review-header-contents {
    margin: 0 -1rem;
    width: 100vw;
    @include middle {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      padding-left: 0;
      padding-right: 0;
      margin: 0;
      text-align: left;

      &:before {
        width: 100vw;
        background-color: var(--lightest-grey);
        height: 100%;
        position: absolute;
        top: 0;
        left: calc(50% - 50vw);
        z-index: -1;
      }
    }

    .pr-rd-review-total {
      text-align: left;
    }
  }

  /**
   * Reviews tablet/desktop only.
   */
  .pr-rd-display-tablet, .pr-rd-display-desktop {
    .pr-rd-side-content-block {
      // we want the right part to begin at the header level
      margin-top: -65px;
    }

    .pr-rd-review-header-contents {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      padding-left: 0;
      padding-right: 0;
      margin: 0;

      &:before {
        width: 100vw;
        background-color: var(--lightest-grey);
        height: 100%;
        position: absolute;
        top: 0;
        left: calc(50% - 50vw);
        z-index: -1;
      }
    }

    .pr-rd-description-text {
      max-width: 650px;
    }

    .pr-rd-search-container {
      margin-left: auto;
      max-width: 460px;
    }

    .pr-rd-main-footer {
      border-bottom: 0;

      .pr-rd-content-block {
        display: flex;
        align-items: center;
        justify-content: start;
        width: 100%;
      }

      .pr-rd-pagination {
        margin-left: auto;
        flex: 0 1 auto;

        .pr-rd-pagination-btn {
          @include text-cta;
        }
      }
    }
  }

  /**
  QA
   */

  .pr-qa-display-headline {

    margin: 0;
    position: relative;

    h1, .pr-qa-display-ask-question {
      display: none;
    }

    &:after {
      background-color: var(--lightest-grey);
      content: '';
      width: 100vw;
      position: absolute;
      top: 0;
      left: calc(50% - 50vw);
      height: 84px;
      z-index: -1;
    }
  }

  .pr-qa-display-search {
    background-color: var(--lightest-grey);
    padding: var(--space-20) 0;

    label {
      display: none;
    }

    .pr-qa-search-btn {
      width: 60px !important;
    }
  }

  .pr-qa-display-question {
    float: none;
    width: 100%;
    margin-bottom: 0;
    padding-top: var(--space-38);
  }

  .pr-qa-display-item {
    margin-bottom: var(--space-4);
  }

  .pr-qa-display-item-details {
    position: static;

    .pr-subscript {
      font-size: var(--font-size-14);
      font-weight: bold;
    }

    time {
      font-size: var(--font-size-14);
      position: relative;
      top: 4px;
    }
  }

  .pr-qa-display-item-icon {
    display: none;
  }

  .pr-qa-display-questionlist {
    padding-left: 0;
    border-left: 0;
    padding-bottom: var(--space-38);
  }

  .pr-qa-display-item-text {
    font-weight: bold;
  }

  .pr-qa-display-answer-link-container {
    margin-left: 0;

    button {
      @include btn;
      @include btn--border-yellow;
      display: flex;

      span {
        font-size: var(--font-size-12);
      }

      .pr-cross-icon {
        display: none;
      }
    }
  }

  .pr-qa-display-answer-by-container {
    border-left: 1px solid var(--yellow);
    padding-left: var(--space-12);
    margin-left: var(--space-8);
    padding-top: var(--space-8);
    padding-bottom: var(--space-4);
  }

  .pr-qa-display-answer-icon {
    background-image: url("data:image/svg+xml,%3Csvg height='21' viewBox='0 0 19 21' width='19' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(1 1)'%3E%3Cpath d='m8.3523 19.0046c3.132-.964 8.353-4.131 8.353-7.437v-9.088l-8.353-2.479-8.353 2.479v9.088c0 3.306 5.221 6.473 8.353 7.437' fill='%23fff' stroke='%23333'/%3E%3Cpath d='m8.205 4.132 1.5 3.169 3.223.451-2.362 2.487.644 3.393-3.005-1.583-3.005 1.583.644-3.393-2.361-2.487 3.222-.451z' fill='%23ffa500'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    width: 24px;
    height: 25px;
    left: auto;
    top: 3px;
  }

  .pr-qa-display-answer-icon + .pr-qa-display-answer-by {
    margin-left: var(--space-32);
  }

  .pr-qa-display-answer-by {
    margin-bottom: 0;
    margin-top: 0;
  }

  .pr-qa-display-answer-by * {
    font-size: var(--font-size-12);
  }

  .pr-qa-display-answer-text {
    margin-top: var(--space-8);
  }

  .pr-qa-display-show-more-answers {
    margin-top: 0;
    margin-bottom: 0;
  }

  .pr-qa-display-ask-question {
    display: none;
  }

  .pr-qa-display-sort {
    .pr-qa-sort-headline {
      display: block;
      float: none;
      text-align: center;
      margin-bottom: var(--space-24);
    }

    .pr-qa-sort-group {
      display: block;
      float: none;
      align-items: center;

      > * + * {
        margin-top: var(--space-8);
      }
    }

    label {
      display: block !important;
      text-align: center;
    }
  }

  .pr-helpful-voting {
    margin: var(--space-8) 0;
  }

  .pr-qa-display-show-more-questions {
    @include btn;
    @include btn--black;
    @include hasIcon;
    width: auto !important;
    align-self: center;

    * {
      color: var(--white) !important;
      line-height: 1.4;
      font-size: var(--font-size-12);
      margin: 0;
      padding: 0
    }

    .pr-caret-icon {
      background-image: url("data:image/svg+xml,%3Csvg height='11' viewBox='0 0 11 11' width='11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6.5 1v11m-5.5-5.5h11' fill='none' stroke='%23fff' stroke-linecap='round' transform='translate(-1 -1)'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      width: 11px;
      height: 11px;

      svg {
        display: none;
      }
    }

    @include mobile {
      width: 100% !important;
    }
  }

  /**
   * Tablet/desktop only.
   */
  .pr-qa-display-tablet, .pr-qa-display-desktop {
    .pr-qa-display-answer-by-container {

    }

    .pr-qa-display-show-more-answers {
      margin-top: var(--space-12);
      margin-bottom: 0;
    }

    .pr-qa-display-answer {
      margin-left: var(--space-20);
      //margin-bottom: 0;
      padding-bottom: 0;
    }

    .pr-qa-display-answer-icon {
      top: -4px;
    }

    .pr-qa-display-answer-icon + .pr-qa-display-answer-by {
      margin-left: var(--space-8);
    }

    .pr-qa-display-sort {
      @include regular-border-bottom;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      float: none;

      .pr-qa-sort-headline {
        margin: 0;
      }
    }


    .pr-qa-sort-group {
      display: flex;
      align-items: center;

      select {
        margin-top: 0;
      }
    }

  }

  .form-control ~ .pr-form-control-error-icon, .pr-control-label ~ .pr-form-control-error-icon, legend.pr-control-label .pr-form-control-error-icon {
    right: var(--space-16);
  }

  /**
   * Write a review
   */
  .pr-war {
    margin: 0 auto;

    input, textarea {
      &::placeholder {
        text-transform: none;
        color: var(--grey);
        letter-spacing: normal;
        font-size: var(--font-size-15);
        font-weight: normal;
      }
    }

    select {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='28.0'%3E%3Cpolygon points='0,0 50,0 25.0,28.0' style='fill: %23000;'%3E%3C/polygon%3E%3C/svg%3E");
      background-size: 16px;
      height: 44px;
      font-size: var(--font-size-15);
    }

    .pr-star-v4 {
      background-image: url("data:image/svg+xml,%3Csvg height='50' viewBox='0 0 50 50' width='50' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m40 1c2.4852814 0 4.7352814 1.00735931 6.363961 2.63603897 1.5578675 1.5578675 2.547268 3.684202 2.6303579 6.04115992l.0056811.32280111v30c0 2.4852814-1.0073593 4.7352814-2.636039 6.363961-1.5578675 1.5578675-3.684202 2.547268-6.0411599 2.6303579l-.3228011.0056811h-30c-2.48528137 0-4.73528137-1.0073593-6.36396103-2.636039-1.5578675-1.5578675-2.54726799-3.684202-2.63035789-6.0411599l-.00568108-.3228011v-30c0-2.48528137 1.00735931-4.73528137 2.63603897-6.36396103 1.5578675-1.5578675 3.684202-2.54726799 6.04115992-2.63035789l.32280111-.00568108zm-15 6.82188955-4.2177392 12.72358895-12.90747625.2114705 10.32174115 8.0105402-3.8131874 12.8147713 10.6166617-7.707843 10.6166617 7.707843-3.8131874-12.8147713 10.3217411-8.0105402-12.9074762-.2114705z' fill='%23fff' fill-rule='evenodd' stroke='%23cdcdcd' stroke-width='2'/%3E%3C/svg%3E");
      margin-right: var(--space-8);
    }

    .pr-star-v4-100-filled {
      background-image: url("data:image/svg+xml,%3Csvg height='50' viewBox='0 0 50 50' width='50' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m40 0c5.5228475 0 10 4.4771525 10 10v30c0 5.5228475-4.4771525 10-10 10h-30c-5.5228475 0-10-4.4771525-10-10v-30c0-5.5228475 4.4771525-10 10-10zm-15 11-3.49182 10.5337195-10.7740277.1765171 8.6159643 6.6867137-3.1668954 10.6428131 8.8167788-6.4011032 8.8167788 6.4011032-3.1668954-10.6428131 8.6159643-6.6867137-10.7740277-.1765171z' fill='%23C27D00' fill-rule='evenodd'/%3E%3C/svg%3E");
    }

    .pr-header, .pr-footer {
      border-color: var(--grey);
    }

    .pr-header-title {
      @include as-h4;
    }

    .pr-header-required {
      margin-bottom: var(--space-32);

      span {
        font-size: var(--font-size-15);
      }
    }

    .pr-footer {
      a {
        font-size: var(--space-16);
        font-weight: bold;
        text-decoration: none;
      }
    }
  }

  .pr-btn-review {
    @include btn;
    @include btn--yellow;
  }

  .pr-required-indicator {
    color: var(--red) !important;
  }

  .btn-group-radio-vertical, .btn-group-radio-horizontal {
    border-radius: var(--space-12);

    label {
      @include text-cta;
      border-color: var(--dark-grey);
      border-width: 1px !important;
      text-align: center;
    }

  }

  .btn-group-radio-vertical {
    label {
      justify-content: center;
    }

    .pr-btn:first-of-type {
      border-top-left-radius: var(--space-12) !important;
      border-top-right-radius: var(--space-12) !important;
    }

    .pr-btn:last-of-type {
      border-bottom-left-radius: var(--space-12) !important;
      border-bottom-right-radius: var(--space-12) !important;
    }
  }

  .form-control {
    @include mobile {
      width: 100%;
      max-width: 100%;
    }
  }

  .btn-group-radio-horizontal {
    display: flex;

    //border: 1px solid var(--dark-grey);
    border-radius: 0;
    //border-radius: var(--space-20);
    overflow: hidden;

    .pr-btn {
      border-radius: 0 !important;
      text-align: center;
      display: block;
    }

    .pr-btn:first-of-type {
      border-top-left-radius: var(--space-20) !important;
      border-bottom-left-radius: var(--space-20) !important;
      //border-top-right-radius: 0 !important;
      //border-bottom-right-radius: 0 !important;
    }

    .pr-btn:last-of-type {
      border-top-right-radius: var(--space-20) !important;
      border-bottom-right-radius: var(--space-20) !important;
    }

    @include mobile {
      display: block;
      width: 100%;

      .pr-btn {
        width: 100%;
        display: block;
        border-left: 1px solid var(--dark-grey) !important;
        border-top: 0;
      }
      .pr-btn:first-of-type, .pr-btn + .pr-btn {
        border-top: 1px solid var(--dark-grey);
      }

      .pr-btn:first-of-type {
        border-top-left-radius: var(--space-20) !important;
        border-top-right-radius: var(--space-20) !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      .pr-btn:last-of-type {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: var(--space-20) !important;
        border-bottom-right-radius: var(--space-20) !important;
      }
    }
  }

  /**
   * Flag as inappropriate dialog.
   */
  .pr-flagging-radio {
    label {
      display: flex;
      align-items: flex-end;
      margin-bottom: 0;
    }

    input[type=radio] {
      width: 20px !important;
      height: 20px !important;
      background-color: transparent;
    }
  }

  .pr-flagging-radio + .pr-flagging-radio {
    margin-top: var(--space-8);
  }
}

