/**
 The layout
 */
.tire-page {
  padding-top: var(--space-20);
  @include mobile {
    padding-top: 0;
  }
}

.tire-page__top {
  display: flex;
  @include mobile {
    display: block;
  }
}

.tire-page__bottom {
  & > * {
    padding-top: var(--space-80);
    padding-bottom: var(--space-80);
    @include mobile {
      padding-top: var(--space-38);
      padding-bottom: var(--space-38);
    }
    @media print {
      @include regular-border-top;
      padding-top: var(--space-20);
    }
  }
}

.tire-page__top-left {
  flex: 1;
  margin-right: var(--space-38);

  @include mobile {
    margin-right: 0;
    margin-bottom: var(--space-20);
  }
}

.tire-page__tire-details-bottom {
  @include regular-border-top;
  > div:first-child {
    border-top: 0;
  }
}

.tire-page__column-section {
  @include regular-border-top;
  padding: var(--space-20) 0;
  display: block;
  width: 100%;
}

.tire-page__column-section + .tire-page__column-section {

}

.tire-page__top-right {
  min-width: 22rem; // 368px
  width: 100%;
  flex: 0 1 22rem;

  @include mobile {
    min-width: auto;
    padding-right: 0;
  }

  @media print {
    flex-basis: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--space-38);
  }

  //& > * {
  //  padding: var(--space-20) 0;
  //  display: block;
  //  width: 100%;
  //}
  //
  & > * + * {
    //@include regular-border-top;
  }

  .tire-page__header {
    text-align: center;
  }

  .tire-warranty__wrapper {
    @include regular-border-top;
  }

  > *:last-child {
    @include mobile {
      @include regular-border-bottom;
    }
  }

  con-tire-fit {
    margin-top: var(--space-20);
  }

  .tire-page__description {
    margin-top: var(--space-28);
    text-align: center;
    hr {
      background-color: var(--grey);
      height: 1px;
      border: none;
    }
  }

  > con-details {
    padding: var(--space-32) 0;
    @include mobile {
      padding: var(--space-20) 0;
    }
  }

  .tire-warranty__wrapper {
    @include mobile {
      padding: var(--space-32) 0;
    }
  }
}

.tire-page__header {
  text-align: center;
  //margin-bottom: var(--space-20);

  > * + * {
    //margin-top: var(--space-12);
  }

  .tire-page__title {
    margin-top: var(--space-38);
    @include mobile {
      margin-top: var(--space-4);
    }
  }

  .offer-ribbon + .tire-page__title {
    margin-top: var(--space-16);
  }
}

.tire-page__description-cta {
  margin-top: var(--space-20);
}

.tire-page__tire-details {
  margin-bottom: var(--space-20);
}

/**
 Elements
 */
.tire-page__title {
  @extend .as-h2;
}

.tire-page__stars {
  display: inline-block;
}


.tire-page__description {
  @include text-lg;
  > * + * {
    margin-top: 1rem;
  }

  ul {
    @include horizontal-line-list;
    margin-left: auto;
    margin-right: auto;
  }
}

.tire-page__description-cta {
  display: block;
  text-align: center;
  .btn {
    @include mobile {
      width: 100%;
    }
  }
}

/**
 Animations
 */
.tire-page__top-left--animated {
  .media--cta {
    @include middle {
      @include animated;
      @include animated--fadeInUp;
      @include animated-order(4);
    }
  }

  .media--cta:nth-child(1),
  .media--cta:nth-child(2) {
    --animate-delay: 0;
  }

  .media--cta:nth-child(3),
  .media--cta:nth-child(4) {
    @include animated-order(2);
  }

  .media--cta:nth-child(5),
  .media--cta:nth-child(6) {
    @include animated-order(3);
  }
}
