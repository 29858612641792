.footer-legal-links ul {
  display: flex;

  li {
    line-height: var(--font-size-12);
  }

  li + li {
    border-left: 1px solid var(--grey);
    padding-left: var(--space-8);
    margin-left: var(--space-8);
  }

  a {
    @include text-cta;
    line-height: var(--font-size-12);
  }
}
