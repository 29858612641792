.user-vehicle-info[connected] {
  @include space-x(var(--space-16));
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.20);
  border-radius: 6px;
  padding-top: var(--space-16);
  padding-bottom: var(--space-13);
  display: flex;
  align-items: center;

  .store-finder-nav-banner__items {
    @include mobile {
      margin: 0 auto;
    }
  }

  @include touch {
    flex-wrap: wrap;
  }

  > * {
    flex-shrink: 0;
    padding: 0 var(--space-16);
  }

  > * + * {
    @include touch {
      margin-left: 0;
    }
  }


  dl {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    dt {
      font-weight: normal;
      margin-right: var(--space-8);
      flex-shrink: 0;
    }

    dd {
      font-weight: bold;
      flex-shrink: 1;

    }
  }


  .user-vehicle-info__see-all {
    margin-left: auto;
    text-align: right;
    flex-shrink: 0;
  }

  .user-vehicle-info__vehicle,
  .user-vehicle-info__size {
    @include touch {
      flex-basis: 100%;
    }
  }


  .user-vehicle-info__edit,
  .user-vehicle-info__see-all {
    @include touch {
      flex-basis: auto;
      margin-top: var(--space-20);
    }
  }

}
