.store-finder .marquee__content {
  padding-top: 70px;
  padding-bottom: var(--space-45);
  @include mobile {
    padding-top: var(--space-38);
    padding-bottom: var(--space-12);
  }
}

.store-finder__main {
  padding: var(--space-38) 0;
  @include mobile {
    padding: var(--space-24) 0;
  }
}

.store-finder__main .container {
  scroll-snap-type: y mandatory;
  > * {
    scroll-snap-align: start;
  }
}

.store-finder__location-form {
  width: 100%;
  max-width: 745px;
  min-height: 77px;
  text-align: center;
  margin: var(--space-20) auto 0 auto;

  .link-button {
    // Pulls "Use current location" to the left to compensate
    // offset produced by the pin icon.
    margin-left: calc(-1 * var(--space-34));
  }

  @include mobile {
    padding: 0 0 var(--space-16) 0;
    .link-button {
      margin-left: 0
    }
  }
}

.store-finder__columns {
  display: grid;
  grid-template-columns: 363px auto;
  grid-gap: var(--space-20);

  height: calc(100vh - 110px);
  max-height: 700px;


  @include mobile {
    grid-template-columns: 1fr;
    height: auto;
    max-height: none;
  }
}

.store-finder__results {
  margin-top: -10px;
  overflow-y: auto;
  position: relative;
  @include mobile {
    order: 2;
    margin: 0 -9px;
  }
}

.store-finder__map {
  //position: sticky;

  @include mobile {
    order: 1;
  }

  con-store-finder-map {
    @include middle {
      //position: sticky;
      //top: 130px;
    }
  }
}
