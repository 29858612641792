.tire-reviews {
  padding-top: var(--space-80);
  @include mobile {
    padding-top: var(--space-38);
  }

  > *:not(a) + * {
    margin-top: var(--space-20);
  }
}

.tire-reviews__title {
  @include section-title;
  text-align: center;
}

.tire-reviews__cta-wrapper {
  text-align: center;

  .btn {
    @include mobile {
      width: 100%;
      text-align: left;
    }
  }
}

.tire-reviews + .tire-reviews {
  margin-top: var(--space-80);
  @include mobile {
    margin-top: var(--space-45);
  }
}

.tire-reviews__write-wrapper {
  @include regular-gradient-bg;
  padding-top: var(--space-38);
  padding-bottom: var(--space-38);

  .tire-reviews__embed {
    margin: 0 auto;
  }
}
