.info-table--wrapped-in-borders {
  @include regular-border-top;
  @include regular-border-bottom;
  border-color: var(--dark-grey);
}

.info-table--bordered-rows {
  .info-table__row {
    @include regular-border-top;
    padding: var(--space-12) 0;
  }
}

.info-table__row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: var(--space-20);
  padding: var(--space-8) 0;
  align-items: center;

  & > *:last-child {
    justify-self: end;
  }
}


.info-table__label {
  @include as-h6;
}

.info-table__buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  > * + * {
    margin-top: var(--space-8);
  }
}

