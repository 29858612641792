con-store-finder-filter {
  display: block;
  margin-bottom: var(--space-38);

  @include mobile {
    margin-top: var(--space-28);
  }

  con-details {
    display: block;
  }

  con-details:not([open]) .con-details__toggle .icon__plus-outline-2 svg {
    font-size: 17px;
  }

  con-details[open] {
    .tire-finder-filters__label {
      @include mobile {
        display: none;
      }
    }
  }

  .con-details__toggle {
    display: none;
    @include mobile {
      display: flex;
    }
  }

  .con-details__toggle svg {
    font-size: var(--font-size-14);
  }

  .con-details__toggle > div {
    display: flex;
    align-items: center;
  }

  .con-details__toggle span {
    @include btnTextBase;
  }

  .store-finder-filters__details {
    margin-top: 0;
    @include mobile {
      margin-top: var(--space-20);
    }
  }
}


.store-finder-filters {
  display: flex;
  align-items: center;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  > * {
    @include mobile {
      flex-direction: column;
    }
  }

  > * + * {
    margin-left: var(--space-20);
    @include mobile {
      margin-left: 0;
    }
  }

  ul {
    display: flex;
    @include mobile {
      width: 100%;
    }

    > * {
      margin-right: var(--space-12);
      @include mobile {
        margin-left: var(--space-20);
        margin-right: var(--space-20);
      }
    }

    > * + * {
      @include mobile {
        margin-top: var(--space-12);
      }
    }
  }

  li {
    display: flex;
    align-items: center;
  }
}

.store-finder-filters__button {
  text-transform: none;
  padding: var(--space-8) var(--space-12);

  @include mobile {
    width: 100%;
    justify-content: flex-start;
  }

  & > * {
    margin-left: var(--space-8);
  }

  input[type='checkbox'] {
    border-color: var(--darkest-grey);
  }

  input[type='checkbox']:checked:after {
    border-color: var(--dark-yellow)
  }
}

.store-finder-filters__label {
  @include mobile {
    display: none;
  }
}

.store-finder-filters__reset {
  @include mobile {
    margin-top: var(--space-8);
  }
}
