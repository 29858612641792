con-site-search {
  position: relative;
  display: block;
  input {
    background-color: var(--white);
  }

  [type=submit] {
    margin-top: 0;
  }

  con-close-button {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 20px;
  }
}
