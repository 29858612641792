.blank-marquee {
  padding: var(--space-80) 0 var(--space-38) 0;
  background-color: var(--white);
  @include mobile {
    padding: var(--space-16) 0;
  }
}

.blank-marquee__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: var(--space-8);
  }
}

.blank-marquee__title {
  text-align: center;
  max-width: 15ch;
}

.path-taxonomy .blank-marquee__title {
  max-width: 20ch;
}

.blank-marquee__subtitle {
  @include as-h3;
  text-align: center;

  @include mobile {
    @include as-h6;
    max-width: 28ch;
    margin-top: var(--space-8);
  }
}
