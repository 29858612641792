.offers-listing-page {

  .views-element-container {
    padding: var(--space-60) 0;
    @include mobile {
      padding: var(--space-38) 0;
    }
  }

  .view-rows {
    max-width: 940px;
    margin: 0 auto;

    > * + * {
      margin-top: var(--space-20);
    }
  }

  .page__landing-page {
    background-image: linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 99%);
  }

  .promo-teaser:first-of-type {
    background-color: var(--white);
  }

  .pager-with-summary {
    padding: 0 var(--space-28);
    @include mobile {
      padding: 0;
    }
  }
}
