.image-asset {
  display: block;

  img {
    display: block;
  }
}

.image-asset__image {
  @include regular-shadow;
  border-radius: 0.75rem;
}

.image-asset__title {
  @include as-h5;
}


.image-asset__meta {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    //@include text-base;
    display: block;
    margin-right: var(--space-20);
  }
}

.image-asset__links {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-grow: 1;
  width: 150px;

  & > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.image-asset--logos {
  img {
    width: 100%;
    height: auto;
  }

  .image-asset__image {
    margin-bottom: var(--space-12);
    border-radius: 10px;
    overflow: hidden;
  }

  .image-asset__links {
    @include mobile {
      padding: 0 var(--space-16);
    }
  }
}

.image-asset--tire-images {
  display: flex;
  align-items: center;

  .image-asset__image-wrapper {
    max-width: 172px;
    width: 100%;
    margin-right: var(--space-20);
    @include mobile {
      max-width: 80px;
    }
  }

  .image-asset__image {
    background-color: var(--white);
    overflow: hidden;
    padding-top: 63%;
    position: relative;

    @include mobile {
      width: 100%;
      height: 70px;
      padding-top: 0;
    }

    img {
      position: absolute;
      width: 130%;
      height: auto;
      top: 20px;
      left: -15%;

      @include mobile {
        top: var(--space-12);
      }
    }


  }

  .image-asset__content {
    padding: var(--space-8) 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > * + * {
      margin-top: var(--space-8);
    }
  }

  .image-asset__meta {
    display: block;
  }
}
