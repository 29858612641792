.tire-category-card-list {
  display: flex;
  align-items: center;
  justify-content: center;
  @include mobile {
    display: block;
  }

  .tire-category-card {
    @include mobile {
      @include regular-border-bottom;
    }
  }

  .tire-category-card + .tire-category-card {
    border-left: 1px solid var(--grey);
    @include mobile {
      border-left: 0;
    }
  }
}
