.store-finder-nav-banner {
  padding: var(--space-12) 0;
  background-color: #000000;
  border-top: 4px solid var(--yellow);
  color: var(--white);
  font-size: var(--font-size-12);
  line-height: 1;
  @include mobile {
    padding: var(--space-22) 0;
  }
}

#block-storesnearblock + .store-finder-nav-banner {
  border-top-width: 1px;
}

.store-finder-nav-banner__header,
.store-finder-nav-banner__item {
  font-size: var(--font-size-12);
  line-height: 1;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
}

.store-finder-nav-banner__items, .store-finder-nav-banner__list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.store-finder-nav-banner__items {
  @include mobile {
    flex-direction: column;
  }
  > * + * {
    margin-left: var(--space-38);
    @include mobile {
      margin-left: 0;
      margin-top: var(--space-16);
    }
  }
}

.store-finder-nav-banner__list {
  @include mobile {
    padding: 0 var(--space-16);
    justify-content: space-between;
    width: 100%;
  }
  > * {
    height: 100%;
    display: flex;
  }
  > * + * {
    margin-left: var(--space-38);
    @include mobile {
      margin-left: var(--space-16);
    }
  }
}

.store-finder-nav-banner__item {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    flex-direction: column;
    text-align: center;
    height: 100%;
  }

  &:hover, &:focus {
    text-decoration: underline;
  }

  > * + * {
    margin-left: var(--space-8);
    @include mobile {
      margin-left: 0;
      margin-top: var(--space-8);
    }
  }
}

.store-finder-nav-banner__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
}
