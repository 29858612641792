.partner {

}

.partner__header {
  @include as-h2;
  text-align: center;
}

.partner__columns {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 305px;
  grid-column-gap: var(--space-45);
  @include mobile {
    grid-template-columns: 1fr;
    grid-row-gap: var(--space-38);
  }
}

.partner__sidebar {
  > * + * {
    margin-top: var(--space-38);
  }
}

.partner__body {
  @include article-content;
  @include text-base;
}
