.card-list {
  @include dark-background;
  padding-top: var(--space-80);
  padding-bottom: var(--space-80);

  @include mobile {
    padding-top: var(--space-38);
    padding-bottom: var(--space-38);
  }

  .load-more-pager {
    margin-top: var(--space-38);
    @include mobile {
      margin-top: var(--space-20);
    }
  }

  .view-rows {
    --gap: var(--space-20);
    --vertical-gap: var(--space-60);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: var(--vertical-gap) var(--gap);

    @include mobile {
      display: block;
      --vertical-gap: var(--space-20);
    }

    > * + * {
      @include mobile {
        margin-top: var(--vertical-gap);
      }
    }

    & > * {
      grid-column: span 2;
    }

    .card__title {
      font-weight: normal;
    }

    .card__text {
      display: none;
      margin-top: var(--space-4);
      font-size: var(--font-size-15);
      line-height: var(--line-height-22);
    }
  }

  .banner-with-image {
    flex: 1 1 100%;
  }

  .card {
    height: 100%;
    align-self: stretch;
  }
}

* + .card-list {
  border-top: 1px solid var(--yellow);
}

.nav-tabs + .card-list {
  border-top: 0;
  padding-top: var(--space-12);
}

.card-list--single_leading {
  .view-rows {
    & > :nth-child(1) {
      grid-column: span 6;
      .card__title {
        font-weight: bold;
      }
      .card__text {
        display: block;
      }
    }
  }

  .card-list__grid--animated {
    .card {
      @include animated;
      @include animated--fadeInUp;
      @include animated-staggered(4);
    }
  }

  .card-list-item__teaser + .banner-with-image {
    margin-top: 0;
  }
}

.card-list--double_leading {
  .view-rows {
    & > :nth-child(1),
    & > :nth-child(2) {
      grid-column: span 3;
      .card__title {
        font-weight: bold;
      }
      .card__text {
        display: block;
      }
    }
    .banner-with-image {
      flex: 1 1 100%;
    }
  }

  .card-list__grid--animated {
    .card {
      @include animated;
      @include animated--fadeInUp;
      @include animated-staggered(8);
    }
  }
}


.card-list__title {
  @include as-h1u;
  text-align: center;
  margin-bottom: var(--space-38);
  @include mobile {
    margin-bottom: var(--space-38);
  }
}


.card-list-item__teaser {
  .content-slider__slide {
    @include mobile {
      margin-left: calc(-1 * var(--space-16));
      margin-right: calc(-1 * var(--space-16));
    }
  }
}
