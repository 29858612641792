.tire-warranty__wrapper {}

.tire-warranty {
  display: flex;
  align-items: flex-start;

  .icon {
    margin-right: var(--space-12);
    font-size: 6.25rem; //100px;
  }

  @include mobile {
    svg {
      font-size: var(--space-60);
    }
  }
}
