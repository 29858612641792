@mixin visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.visually-hidden, .sr-only {
  @include visually-hidden;
}

.visually-hidden-desktop {
  @include middle {
    @include visually-hidden;
  }
}
