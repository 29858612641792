.tire-promo-cc-content {
  padding: var(--space-80) 0;
  @include mobile {
    padding: var(--space-38) 0;
  }
}

.tire-promo-cc-content-area {
  display: flex;

  @include mobile {
    display: block;
  }

  > * {
    width: 50%;
    @include mobile {
      width: 100%;
    }
  }

  > * + * {
    @include mobile {
      margin-top: var(--space-34);
    }
  }
}

.tire-promo-cc-content-area__left {
  text-align: center;
}

.tire-promo-cc-howto {
  @include regular-gradient-bg;
  .container > * + * {
    margin-top: var(--space-34);
  }
}

.tire-promo-cc-howto__title {
  font-size: var(--font-size-50);
  line-height: var(--line-height-60);
  text-align: center;
  @include mobile {
    font-size: var(--font-size-30);
    line-height: var(--line-height-34);
  }
}

.tire-promo-cc-howto__boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 6%;
  @include mobile {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
}

.tire-promo-cc-howto__note {
  @include regular-border-top;
  padding-top: var(--space-34);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-0_5);
  font-style: italic;
  text-align: center;
}

.tire-promo-cc-card {
  > * + * {
    margin-top: var(--space-20);
  }

  img {
    display: block;
    width: 100%;
    max-width: 535px;
    height: auto;
    margin: 0 auto;
    @include mobile {
      max-width: 171px;
    }
  }

  p {
    font-size: var(--font-size-20);
    text-align: center;
  }
}


.tire-promo-cc-card__image {
  padding-right: 50px;
}
