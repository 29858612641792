.tire-category-card {
  display: block;
  width: 100%;
  height: 10.375rem; // 166px;
  padding: var(--space-38) var(--space-45) var(--space-12) var(--space-45);

  overflow: hidden;
  position: relative;
  transition: background-color 0.3s;


  @include mobile {
    height: auto;
    padding: var(--space-20) 160px var(--space-20) var(--space-20);
  }
}

.tire-category-card__image {
  position: absolute;
  bottom: 0;
  right: -80px;

  img {
    transition: 0.3s;
    right: 0;
    position: absolute;
    bottom: -10px;
  }

  @include mobile {
    right: -48px;
    img {
      width: 220px;
      height: auto;
    }
  }
}

.tire-category-card__cta {
  max-width: 220px;

  @include mobile {
    max-width: 150px;
  }

  svg {
    display: block;
  }
}

.tire-category-card__title {
  font-size: var(--font-size-30);
  line-height: var(--line-height-38);
  transition: background-color 0.3s, box-shadow 0.3s;
  display: inline;
  background-color: var(--white);
  box-decoration-break: clone;
  .icon {
    display: block;
    font-size: var(--font-size-18);
  }

  @include mobile {
    font-size: var(--font-size-24);
  }
}

.tire-category-card:hover, .tire-category-card:focus {
  background-color: var(--black);
  color: var(--white);
  text-decoration: none;

  .tire-category-card__title {
    background-color: var(--black);
    //box-shadow: 0 0 0 0 var(--black), 0 0 0 0 var(--black);
  }

  .tire-category-card__image img {
    transform: translateY(-10px);
    @include mobile {
      transform: translateY(-5px);
    }
  }

  .icon__arrow-right-outline {
    @include animated;
    @include animated-infinite;
    @include animated--bounceRight;
  }
}
