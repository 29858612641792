/*
---
name: Content slider
category: Components
---

```example.html
<content-slider class="content-slider">
  <content-slider-slide class="content-slider__slide">
    <div class="content-slider__slide-left">
      <div class="content-slider__slide-content">
        <h4>Andie the Lab and VikingContact™ 7 take on winter.</h4>

        <div>
          Just like K-9 car correspondent Andie the Lab, the VikingContact™ 7 was built for winter. See what happens when he gives our new tire his “Lab Test”
        </div>

        <a href="#" class="btn btn--border-black">Read more</a>
      </div>
      <div class="content-slider__pager">
        1 of 3
      </div>
    </div>
    <div class="content-slider__slide-right">
      <img src="demo_assets/video_dog_cover.jpg">
    </div>
  </content-slider-slide>
</content-slider>
```
*/
.content-slider {
  display: block;
  overflow: hidden;

  //@include middle {
  //  // Turns of slide animation on desktop.
  //  --content-slider-section-transition: opacity;
  //}
}

.content-slider__track {
  display: flex;
  align-items: flex-start;
  transition: 0.5s ease;

  @include middle {
    transition: opacity 0.5s ease;
  }
}

.content-slider__slide {
  min-height: 26.875rem; // 430px
  height: 100%;
  display: flex;

  @include middle {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &[active] {
      opacity: 1;
    }
  }

  @include mobile {
    flex-direction: column-reverse;
  }
}

.content-slider__slide-left {
  display: flex;
  flex-direction: column;
  flex: 0 0 25rem; // 400px

  justify-content: center;

  padding: var(--space-20) var(--space-38);
  color: var(--black);
  background-color: var(--white);
  text-align: center;

  @include mobile {
    flex: 1;
    padding-top: 0;
  }
}

.content-slider__slide-right {
  flex: 1;
  display: flex;

  background-color: var(--black);

  @include mobile {
    //display: block;
    flex: 1;
    width: 100%;
  }

  > * {
    width: 100%;
  }

  picture {
    height: 100%;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100% !important;
  }

}

.content-slider__slide-content {
  //@include text-base;
  margin-top: auto;
  margin-bottom: auto;

  > * + * {
    margin-top: var(--space-20);
  }

  h4 + * {
    margin-top: var(--space-12);
  }

  .btn {
    @include mobile {
      width: 100%;
    }
  }

  @include mobile {
    margin-top: var(--space-38);
    margin-bottom: var(--space-32);
  }
}

.content-slider__pager {
  margin-left: auto;
  margin-right: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 5rem;

  font-size: var(--font-size-12);
  line-height: 1;
  font-weight: bold;
  letter-spacing: var(--letter-spacing-1_25);
}
