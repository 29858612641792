.article-thumbnail {

  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
}

.article-thumbnail--video {
  &:after {
    display: block;
    content: '';
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 36px;
    height: 36px;
    background-image: url("data:image/svg+xml,%3Csvg height='36' viewBox='0 0 76 76' width='36' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='38' cy='38' fill='%23000' fill-opacity='.2' r='37' stroke='%23fff' stroke-width='2'/%3E%3Cpath d='m34 26 14 12.5-14 12.5z' fill='%23ffa500'/%3E%3C/g%3E%3C/svg%3E");
  }
}
