
.footer-menu > ul {
  display: grid;
  grid-gap: 50px 60px;
  grid-template-columns: repeat(6, 160px);
  justify-content: space-between;

  @include max-width(1090px) {
    grid-template-columns: repeat(3, minmax(160px, 200px));
    justify-content: flex-start;
  }


  @include mobile {
    @include full-bleed;
  }


  // First level li don't need a top margin.
  & > li {
    margin-top: 0 !important;
    @include mobile {
      padding-left: var(--space-16);
      padding-right: var(--space-16);
      margin-right: 0;
    }
  }

  > li:first-child {
    grid-column: span 2;
    @include max-width(1350px) {
      grid-column: initial;
      grid-row: span 3;
    }
    @include touch {
      grid-column: initial;
    }
  }

  > li + li {
    @include mobile {
      @include regular-border-top;

    }
  }

  // A menu item without a link aka column heading.
  li > span {
    @extend .subtle-heading;
  }

  li > ul {
    margin-top: var(--space-16);
  }

  li + li {
    margin-top: var(--space-12);
  }

  con-details {
    .con-details__toggle .icon {
      margin-right: var(--space-12);
    }

    > span, .con-details__toggle > span {
      @include subtle-heading;
    }

    > *:nth-child(2) {
      margin-top: var(--space-16);
    }
  }

  // Second level ul
  con-details[open] {
    ul {
      @include mobile {
        margin-top: var(--space-12);
        padding-left: 34px;
      }
    }
  }

  a {
    @extend .as-h6;
  }

  @include mobile {
    display: block;

    & > li {
      padding-top: var(--space-13);
      @include mobile {
        padding-top: var(--space-16);
        padding-bottom: var(--space-16);
      }
    }
  }
}
