/*
---
name: Card
category: Components
---

```card.html
<div style="padding: 40px;">
  <article class="card">
    <div class="card__media">
      <img src="https://via.placeholder.com/800x450" />
    </div>
    <div class="card__footer">
      <a class="card__cta" href="">Click me I'm a CTA</a>
    </div>
  </article>
</div>
```
 */
.card {
  @include regular-shadow;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: var(--space-12);

  picture {
    border-radius: var(--space-12);
  }

  &:hover {
    @include regular-shadow-hover;
    .card__cta {
      @include text-underline--yellow;
      .icon__arrow-right-outline {
        @include animated;
        @include animated-infinite;
        @include animated--bounceRight;
      }
    }
  }
}

.card--has-footer {
  picture {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.card__title {
  @include as-h6;
}

.card__media {
  @include animate-image-on-hover;
  display: block;

  img {
    width: 100%;
    height: auto;
  }
}

.card__cta {
  @include text-cta;
}

.card__cta--mobile-centered {
  @include mobile {
    flex-direction: column;
    .icon {
      display: block;
      margin-top: var(--space-4);
    }
  }
}

.card__footer {
  padding: var(--space-16) var(--space-20);
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: flex-start;
  font-size: var(--font-size-18);
  background-color: var(--white);
  color: var(--black);

  > * + * {
    margin-left: var(--space-12);
    @include touch {
      margin-left: 0;
    }
  }

  @include touch {
    flex-direction: column;
    text-align: center;
  }

  .card__cta {
    flex-shrink: 0;
    @include touch {
      margin-top: var(--space-8);
    }
  }
}

a.card__footer {
  &:hover {
    text-decoration: none;
    div {
      @include text-underline--yellow;
    }
  }
}
