.cards-carousel {
  background-color: var(--black);
  color: var(--white);
  overflow: hidden;

  padding: var(--space-80) 0;
  @include mobile {
    padding: var(--space-38) 0;
  }
}

.cards-carousel__container {
  padding: 0 var(--space-20);
}

.cards-carousel--carousel .cards-carousel__container {
  @include wide {
    padding: 0;
    margin-right: 0;
    margin-left: calc(50vw - calc(73rem / 2));
  }

  > * + * {
    margin-top: var(--space-38);
  }
}

.cards-carousel__header {
  @include mobile {
    text-align: center;
  }

  > * + * {
    margin-top: var(--space-12);
    @include mobile {
      margin-top: var(--space-8);
    }
  }
}

.cards-carousel__title {
  font-size: var(--font-size-42);
  line-height: var(--line-height-48);
  letter-spacing: 6px;
  font-weight: 300;
  text-transform: uppercase;
  @include mobile {
    font-size: var(--font-size-32);
    line-height: var(--line-height-36);
    letter-spacing: 6px;
  }
}

.cards-carousel__subtitle {
  font-size: var(--font-size-24);
  line-height: var(--line-height-32);
  @include mobile {
    font-size: var(--font-size-20);
    line-height: var(--line-height-30);
  }
}

.cards-carousel__pagination {
  margin-top: var(--space-20);
  display: none;
  justify-content: flex-end;
  margin-right: var(--space-38);
  height: 22px;
  @include mobile {
    justify-content: center;
    margin-right: 0;
  }
}

.swiper-initialized .cards-carousel__pagination {
  display: flex;
}

.cards-carousel__pager {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    font-size: 28px;
  }

  [aria-disabled="true"] .icon {
    display: none;
  }
}

.cards-carousel__pager__status {
  margin: 0 var(--space-8);
  text-transform: uppercase;
  font-weight: bold;
}

.cards-carousel__pager__button {
  height: 28px;
  width: 28px;
  cursor: pointer;
}


:not(.swiper-initialized) > .cards-carousel__items {
  overflow-x: scroll;
  scrollbar-width: none;
  position: relative;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  padding: var(--space-38) 0 var(--space-20) 0;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--space-20);

  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: 1170px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media screen and (min-width: 1980px) {
    grid-template-columns: repeat(8, 1fr);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    scroll-snap-align: start end;
  }
}

.cards-carousel__item {
  width: 100%;
  height: auto;
  background-color: var(--dark-black);
  padding: var(--space-20) var(--space-20) var(--space-26) var(--space-20);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 2px;

  > * + * {
    margin-top: var(--space-16);
  }

  &.swiper-slide {
    height: auto;
  }
}


.cards-carousel__item__icon {
  width: 56px;
}

.cards-carousel__item__texts {
  > * + * {
    margin-top: var(--space-8);
  }
}

.cards-carousel__item__title {
  font-weight: bold;
  font-size: var(--font-size-14);
  line-height: var(--line-height-18);
  color: var(--yellow);
  text-transform: uppercase;
  letter-spacing: 2px;
}


.cards-carousel--grid {
  .cards-carousel__container {
    @include container;
    max-width: 1390px;
    > * + * {
      margin-top: var(--space-38);
    }
  }
  .cards-carousel__header {
    text-align: center;
  }
  .cards-carousel__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  .cards-carousel__item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .cards-carousel__item__icon {
    flex-shrink: 0;
    margin-right: 20px;
  }
  .cards-carousel__item__texts {
    text-align: left;
    margin-top: 0;
  }
}
