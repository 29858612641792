/*
---
name: Pager
category: Elements
---
## Full pager

```fullpager.html
<nav class="pager">
  <button class="pager__page">PREV</button>
  <button class="pager__page pager__page--active">1</button>
  <button class="pager__page">2</button>
  <button class="pager__page">3</button>
  <button class="pager__page">NEXT</button>
</nav>
```

## Arrows
```arrows.html
<button class="pager__prev"></button>
<button class="pager__next"></button>
```

## Summary
```summary.html
<div class="pager-summary">
  <b>1-10</b> of 68 results
</div>
```

## Pager with summary
```pager-with-summary.html
<div class="pager-with-summary">
  <div class="pager-summary">
    <b>1-10</b> of 68 results
  </div>
  <nav class="pager">
    <button class="pager__page">PREV</button>
    <button class="pager__page pager__page--active">1</button>
    <button class="pager__page">2</button>
    <button class="pager__page">3</button>
    <button class="pager__page">NEXT</button>
  </nav>
</div>
```
*/
.pager__prev,
.pager__next {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.pager__prev {
  background-image: url("data:image/svg+xml,%3Csvg height='18' viewBox='0 0 9 18' width='9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m9 0-9 8 9 8' fill='none' stroke='%23333' stroke-linecap='round' stroke-linejoin='round' transform='translate(0 1)'/%3E%3C/svg%3E");
}

.pager__next {
  background-image: url("data:image/svg+xml,%3Csvg height='18' viewBox='0 0 9 18' width='9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 0 9 8-9 8' fill='none' stroke='%23333' stroke-linecap='round' stroke-linejoin='round' transform='translate(0 1)'/%3E%3C/svg%3E");
}

.pager__page {
  padding: 0 var(--space-4);
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  font-size: var(--font-size-14);
  line-height: var(--line-height-22);
  letter-spacing: var(--letter-spacing-1_25);
  @include hover-reset;
}

.pager__page + .pager__page {
  margin-left: var(--space-8);
}

.pager__page--active {
  border-bottom: 1px solid var(--yellow);
}

.pager-summary {
  font-size: var(--font-size-15);
  line-height: var(--line-height-22);
}

.pager-with-summary {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    display: block;
    text-align: center;
  }

  & > * + * {
    @include mobile {
      margin-top: var(--space-8);
    }
  }

  .pager-summary {
    margin-right: 1rem;
    @include mobile {
      margin-right: 0;
    }
  }
}
