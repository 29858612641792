.tire-overall-ratings {
  @include top-dark-bottom-light-gradient-bg;
}

.tire-overall-ratings__title {
  @include as-h1;
  text-align: center;
  margin-bottom: var(--space-38);
  @include mobile {
    max-width: 12ch;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: var(--space-20);
  }
}

.tire-overall-ratings__ratings-box {
  @include text-small;
  @include regular-shadow;

  display: grid;
  border-radius: 10px;
  grid-template-columns: 1fr max-content 2fr;
  grid-column-gap: var(--space-20);
  padding: var(--space-34) var(--space-8);
  letter-spacing: var(--letter-spacing-0_5);
  font-weight: bold;

  @include mobile {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    padding: var(--space-16) var(--space-8);
  }

  & > * {
    padding-top: var(--space-4);
    padding-bottom: var(--space-4);
    @include mobile {
      padding: 0;
    }
  }

  dl {
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  dd {
    margin-left: var(--space-26);
    @include mobile {
      margin-left: 0;
    }
  }
}

.tire-overall-ratings__summary {
  text-align: center;

  span {
    @include as-h2;
    display: block;
    font-weight: bold;
    color: var(--dark-grey);
    @include mobile {
      @include as-h3;
      font-weight: normal;
    }
  }
}

.tire-overall-ratings__average {
  border-left: 1px solid var(--grey);
  border-right: 1px solid var(--grey);
  padding-left: var(--space-20);
  padding-right: var(--space-20);

  @include mobile {
    border-left: 0;
    border-right: 0;
  }

  span {
    font-weight: normal;
  }
}

.tire-overall-ratings__features {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px 40px;

  @include mobile {
    grid-column: span 2;
    grid-column-gap: 10px;
    grid-template-columns: 1fr 1fr;
    @include regular-border-top;
    padding-top: var(--space-16);
    margin-top: var(--space-16);
  }

  dl {
    width: 100%;
    max-width: 180px;
  }
}
