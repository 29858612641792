.social-gallery {
  //padding-top: var(--space-80);
  //padding-bottom: var(--space-60);
  //@include mobile {
  //  padding-top: var(--space-20);
  //  padding-bottom: var(--space-20);
  //}

  > * > * + * {
    margin-top: var(--space-22);
  }

  con-gallery-item picture > img {
    border-radius: var(--space-12);
  }
}

.social-gallery__title {
  @include as-h1u;
  @include mobile {
    text-align: center;
  }
}
