.image-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;

  & > * + * {
    margin-top: var(--space-20);
    @include mobile {
      margin-top: var(--space-12);
    }
  }

  img {
    @include regular-shadow;
    border-radius: 1rem;
    margin-top: 0;
    width: 100%;
    height: auto;
  }

  p {
    max-width: 27ch;
    @include text-lg;

    @include middle {
      margin-bottom: auto;
    }

    @include mobile {
      max-width: none;
    }
  }

  .btn {
    margin-top: var(--space-16);
    @include mobile {
      width: 100%;
      margin-top: var(--space-8);
    }
  }
}
