con-horizontal-scrollbar {
  display: flex;
  justify-content: center;
}

con-horizontal-scrollbar[leftspace] {
  position: relative;
  display: block;

  &:before {
    pointer-events: none;
    content: "";
    bottom: 0;
    position: absolute;
    width: 100px;
    background-image: url("data:image/svg+xml,%3Csvg height='20' viewBox='0 0 28 52' width='28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 0 28 25-28 25' fill='none' stroke='%23c27e00' stroke-linecap='round' stroke-width='3' stroke-linejoin='round' transform='matrix(1 0 0 -1 0 51)'/%3E%3C/svg%3E"), linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.57) 60%, rgba(255, 255, 255, 1) 100%);
    background-position: center right;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    top: 0;
    left: -20px;
    z-index: 1;
  }
}

con-horizontal-scrollbar[rightspace] {
  position: relative;
  display: block;

  &:after {
    pointer-events: none;
    content: "";
    bottom: 0;
    position: absolute;
    width: 100px;
    background-image: url("data:image/svg+xml,%3Csvg height='20' viewBox='0 0 28 52' width='28' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 0 28 25-28 25' fill='none' stroke='%23c27e00' stroke-linecap='round' stroke-width='3' stroke-linejoin='round' transform='matrix(1 0 0 -1 0 51)'/%3E%3C/svg%3E"), linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.57) 60%, rgba(255, 255, 255, 1) 100%);
    background-position: center right;
    background-repeat: no-repeat;
    top: 0;
    right: -20px;
    z-index: 1;
  }
}
