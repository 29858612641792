/*
---
name: Textfield
category: Fields
---

## Text input
```textinput.html
<br/>
<con-field type="textfield" floatlabel>
  <label for="edit-name">First name</label>
  <input type="text" id="edit-name" name="name" value="" size="60" maxlength="60">
  <div class="field__help">Enter your first name</div>
</con-field>
```

## Focused
```textinput-focused.html
<div style="padding: 1rem;">
  <con-field type="textfield" floatlabel is-focused>
    <label for="edit-name">First name</label>
    <input type="text" id="edit-name" name="name" value="" size="60" maxlength="60">
    <div class="field__help">Enter your bio.</div>
  </con-field>
</div>
```

## Disabled
```textinput-disabled.html
<div style="padding: 1rem;">
  <con-field type="textfield" floatlabel disabled>
    <label for="edit-name">First name</label>
    <input disabled type="text" id="edit-name" name="name" value="" size="60" maxlength="60">
    <div class="field__help">Enter your bio.</div>
  </con-field>
</div>
```

## Success
```textinput-success.html
<div style="padding: 1rem;">
  <con-field type="textfield" floatlabel success-status>
    <label for="edit-name">First name</label>
    <input type="text" id="edit-name" name="name" value="" size="60" maxlength="60">
    <div class="field__help">Enter your bio.</div>
  </con-field>
</div>
```

## Error state
```textinput-error.html
<div style="padding: 1rem;">
  <con-field type="textfield" floatlabel error-status>
    <label for="edit-name">First name</label>
    <input type="text" id="edit-name" name="name" value="" size="60" maxlength="60">
    <div class="field__help field__error">Please enter your bio.</div>
    <div class="field__help">Enter your bio.</div>
  </con-field>
</div>
```
*/
con-field[type=textfield] {
  &[is-focused] {
    input {
      border-color: var(--blue);
    }
  }
}
