.tire-listing-page__top {
  & > * + * {
    @include mobile {
      margin-top: var(--space-12);
    }
  }

  @include mobile {
    padding-bottom: var(--space-16);
  }
}

.tire-listing-page__marquee {
  .user-vehicle-table {
    max-width: 590px;
    margin: 0 auto;
  }

  con-disclosure {
    @include mobile {
      text-align: center;
    }
  }
}

.tire-listing-page__title {
  max-width: 20ch;
  @include middle {
    padding-left: var(--space-60);
  }
}

.tire-listing-page__find-perfect-fit {
  @include dark-background;
  padding: var(--space-13);
  text-align: center;
  svg {
    color: var(--yellow);
  }
}

.tire-listing-page__columns {
  display: block;
  padding: var(--space-80) 0;

  @include mobile {
    padding: var(--space-38) 0;
  }

  & > * {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-column-gap: 130px;

    @include mobile {
      display: block;
    }
  }
}

.tire-listing-page__left {
  padding-left: var(--space-20);
  @include mobile {
    padding-left: 0;
  }
}

body:not(.has-modal-opened) .tire-listing-page__left {
  // Sets z-index only when modal is closed to prevent
  // issues with header getting higher z-index than modal trapped
  // within parent with z-index: 1;
  @include mobile {
    position: relative;
    display: inline-block;
    //z-index: 1;
  }
}

/**
 * Prevents double animation on subsequent fetches.
 */
con-tire-listing-controller:not([fetched]),
con-tire-search-controller:not([fetched]) {
  .tire-listing-page__left--animated {
    @include animated;
    @include animated-order(2);
    @include animated--fadeInUp;
  }
}

.tire-listing-page__right {
  padding-right: var(--space-20);
  @include mobile {
    padding-right: 0;
  }
}

.tire-listing-page__results-wrapper, .tire-listing-page__results .view-rows {
  & > * + * {
    margin-top: var(--space-20);
  }
}

.tire-listing-page__results {
  margin-top: var(--space-12);
}

.tire-listing-page__results--animated {
  article {
    @include animated;
    @include animated--fadeInUp;
    @include animated-staggered(10);
  }
}

.tire-listing-page__loading {
  padding: var(--space-20);
  @include mobile {
    text-align: center;
  }
}

.tire-listing-page__sort-by {
  form {
    width: 150px;
  }
}

.tire-listing-page__results-header {
  display: flex;
  justify-content: space-between;
  @include mobile {
    flex-direction: column;
    margin-top: -33px;
  }
}

.tire-listing-page__results-header-left {
  @include mobile {
    @include as-h3;
    text-align: right;
    width: 50%;
    align-self: flex-end;
  }
}

.tire-listing-page__results-header-right {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    @include regular-border-top;
    padding-top: var(--space-8);
    margin-top: var(--space-20);
  }

  & > * + * {
    margin-left: var(--space-20);
    @include mobile {
      margin-left: var(--space-8);
    }
  }
}

.tire-listing-page__pagination {
  display: flex;
  justify-content: space-between;
}

/**
 * Hide "No match found." on initial load for tire search results.
 */
con-tire-search-controller:not([fetched]) {
  .tire-listing-page__results-wrapper {
    display: none;
  }
}
