@mixin list-base {
  margin-left: 1.5em;
  li + li {
    margin-top: 0.5em;
  }
}

/*
---
name: Checkmark list
category: Lists
---

```checkmark.html
<ul class="checkmark-list">
  <li>50K Limited Warranty</li>
  <li>50 Day Trial</li>
  <li>3 Year Roadside Assistance</li>
</ul>
```
*/
@mixin checkmark-list {
  @include list-base;
  list-style: none !important;

  li, .checkmark-list__item {
    position: relative;

    &:before {
      content: '';
      background: url("data:image/svg+xml,%3Csvg height='14' viewBox='0 0 16 14' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 6.49226357 5.03588867 5.50773643 8.96411133-12' fill='none' stroke='%23ffa500' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(1 1)'/%3E%3C/svg%3E") no-repeat center center;
      display: block;
      position: absolute;
      transform: translateY(15%);
      left: -1.5em;
      width: 1em;
      height: 1em;
      background-size: 1em;
    }
  }

  &--large {
    font-size: var(--font-size-24);
    margin-left: 1.75em;
    @include mobile {
      font-size: var(--font-size-18);
    }
    li, .checkmark-list__item {
      &:before {
        left: -1.75em;
        transform: translateY(-10%);
      }
    }
    li + li {
      margin-top: 1em;
    }
  }

  &--green {
    li, .checkmark-list__item {
      &:before {
        background: url("data:image/svg+xml,%3Csvg height='15' viewBox='0 0 18 15' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 6.759 4.8 5.069 9.6-11.828' fill='none' stroke='%2326a022' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.4' transform='translate(1.8 1.4)'/%3E%3C/svg%3E") no-repeat center center;
      }
    }
  }
}

.checkmark-list {
  @include checkmark-list;
}

/*
---
name: Not allowed list
category: Lists
---

```na.html
<ul class="na-list">
  <li>50K Limited Warranty</li>
  <li>50 Day Trial</li>
  <li>3 Year Roadside Assistance</li>
</ul>
```
*/
@mixin na-list {
  @include list-base;
  list-style: none;

  li {
    list-style: none;
    position: relative;

    &:before {
      content: '';
      background: url("data:image/svg+xml,%3Csvg height='17' viewBox='0 0 17 17' width='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23ff2d37' transform='translate(1 1)'%3E%3Ccircle cx='7.5' cy='7.5' r='7.5'/%3E%3Cpath d='m4.5 10.5 6-6m0 6-6-6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E") no-repeat center center;
      display: block;
      position: absolute;
      transform: translateY(15%);
      left: -1.5em;
      width: 1em;
      height: 1em;
      background-size: 1em;
    }
  }
}

.na-list {
  @include na-list;
}

/*
---
name: Item list
category: Lists
---

```items.html
<ul class="item-list">
  <li>50K Limited Warranty</li>
  <li><svg class="item-list__avatar" height="30" viewBox="0 0 26 30" width="26" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="#333" stroke-linecap="round" stroke-linejoin="round" transform="translate(.4 .6)"><path d="m9.9 1.8 2.7 2.4 2.7-2.4"/><path d="m15.3 27-2.7-2.4-2.7 2.4"/><path d="m12.6 0v28.8"/><path d="m22.2 5.802-.744 3.54 3.426 1.152"/><path d="m3 22.998.744-3.54-3.426-1.152"/><path d="m25.104 7.254-25.008 14.292"/><path d="m24.846 18.414-3.438 1.128.714 3.54"/><path d="m.354 10.386 3.438-1.128-.714-3.54"/><path d="m25.038 21.654-24.876-14.508"/><path d="m8.73 7.704 3.87 2.058 3.87-2.058.15 4.374 3.714 2.322-3.714 2.322-.15 4.374-3.87-2.058-3.87 2.058-.15-4.374-3.714-2.322 3.714-2.322z"/></g></svg> 50 Day Trial</li>
  <li><b class="as-h6">3 Year Roadside Assistance</b></li>
</ul>
```

### Inline
```items-inline.html
<ul class="item-list item-list--inline">
  <li>50K Limited Warranty</li>
  <li><svg class="item-list__avatar" height="30" viewBox="0 0 26 30" width="26" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="#333" stroke-linecap="round" stroke-linejoin="round" transform="translate(.4 .6)"><path d="m9.9 1.8 2.7 2.4 2.7-2.4"/><path d="m15.3 27-2.7-2.4-2.7 2.4"/><path d="m12.6 0v28.8"/><path d="m22.2 5.802-.744 3.54 3.426 1.152"/><path d="m3 22.998.744-3.54-3.426-1.152"/><path d="m25.104 7.254-25.008 14.292"/><path d="m24.846 18.414-3.438 1.128.714 3.54"/><path d="m.354 10.386 3.438-1.128-.714-3.54"/><path d="m25.038 21.654-24.876-14.508"/><path d="m8.73 7.704 3.87 2.058 3.87-2.058.15 4.374 3.714 2.322-3.714 2.322-.15 4.374-3.87-2.058-3.87 2.058-.15-4.374-3.714-2.322 3.714-2.322z"/></g></svg> 50 Day Trial</li>
  <li><b class="as-h6">3 Year Roadside Assistance</b></li>
</ul>
```
*/
@mixin item-list {
  li, .item-list__item {
    display: flex;
    align-items: center;

    .item-list__avatar {
      margin-right: var(--space-8);
    }
  }

  li + li, .item-list__item + .item-list__item {
    margin-top: 0.5em;
  }
}

.item-list {
  @include item-list;
}

.item-list--inline {
  display: flex;
  align-items: center;

  li + li, .item-list__item + .item-list__item {
    margin-top: 0;
    margin-left: var(--space-16);
  }
}

/*
---
name: Horizontal line list
category: Lists
---

```horizontal-line-list.html
<ul class="horizontal-line-list">
  <li>Utra-high performance for sport and luxury vehicles</li>
  <li>Precision handling with true, year-round traction</li>
  <li>Improved braking with X-Sipe technology</li>
</ul>
```
*/
@mixin horizontal-line-list {
  width: max-content;
  max-width: 100%;

  li {
    position: relative;
  }

  li + li {
    padding-top: var(--space-12);
    margin-top: var(--space-12);

    &:after {
      content: '';
      background-color: var(--dark-yellow);
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: var(--space-20);
      height: 2px;
    }
  }
}

.horizontal-line-list {
  @include horizontal-line-list;
}

/*
---
name: Regular list
category: Lists
---

```reqular-list.html
<ul class="reqular-list">
  <li>50K Limited Warranty</li>
  <li>50 Day Trial</li>
  <li>3 Year Roadside Assistance</li>
</ul>
```
*/

@mixin reqular-list {
  @include list-base;
  list-style: none;

  li {
    display: block;
    position: relative;

    &:before {
      content: '•';
      color: var(--dark-yellow);
      display: block;
      width: 1em;
      height: 1em;
      position: absolute;
      left: -1em;
    }
  }
}


.regular-list {
  @include reqular-list;
}

/*
---
name: Headings ordered list
category: Lists
---

```headings-list.html
<ol class="headings-list">
  <li><h2>50K Limited Warranty</h2></li>
  <li><h2>50 Day Trial</h2></li>
  <li><h2>3 Year Roadside Assistance</h2></li>
</ul>
```
*/
.headings-list.headings-list {
  margin-left: 2em;
  counter-reset: listNumbering;

  @include mobile {
    margin-left: 1.5em !important;
  }

  > li {
    counter-increment: listNumbering;
    position: relative;
    list-style: none;
  }

  > li:before {
    content: counter(listNumbering) '.';
    font-size: var(--font-size-30);
    position: absolute;
    left: -1em;
    top: 4px;
    line-height: 1;
    @include mobile {
      font-size: var(--space-22);
      top: 7px;
    }
  }

  > li + li {
    margin-top: 0.5em;
  }
}

/*
---
name: Undecorated list
category: Lists
---

```undecorated.html
<ul class="undecorated-list">
  <li>50K Limited Warranty</li>
  <li>50 Day Trial</li>
  <li>3 Year Roadside Assistance</li>
</ul>
```
*/
.undecorated-list.undecorated-list {
  @include list-base;
  margin-left: 0 !important;
  list-style: none;
  li {
    list-style: none;
    &:before {
      display: none;
    }
  }
}
