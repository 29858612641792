/*
---
name: Tag
category: Elements
---

```tag.html
<span class="tag tag--yellow">New</span>
```

# Smaller version
```no-uppercase-tag.html
<span class="tag tag--no-uppercase tag--yellow">Exclusive Credit Card Offer</span>
```
*/

.tag {
  display: inline-block;
  text-transform: uppercase;
  font-size: var(--font-size-20);
  line-height: var(--line-height-26);
  font-weight: bold;

  &.tag--no-uppercase {
    text-transform: none;
  }
}

.tag--yellow {
  color: var(--yellow);
}
