.product-highlights-block {
  @include very-dark-background;
  --vertical-gap: var(--space-28);
  padding: var(--space-80) 0 var(--space-38) 0;
  @include mobile {
    padding: var(--space-38) 0;
  }

  .views-element-container {
    margin-top: var(--space-24);
  }
}

.product-highlights-block__title {
  @include as-h1u;
  text-align: center;
  margin-bottom: var(--space-28);
  @include mobile {
    margin-bottom: var(--space-20);
  }
}

.product-highlights-block__grid {
  > * + * {
    margin-top: var(--vertical-gap);
  }

  .view-rows {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--space-20);
    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  .card__footer {
    background-color: var(--white);
    color: var(--black);
  }
}

.product-highlights-block__grid--animated {
  .view-rows article {
    @include animated;
    @include animated--fadeInUp;
    @include animated-staggered(4);
  }

  .more-link {
    @include animated;
    @include animated--fadeInUp;
    @include animated-order(6);
  }
}
