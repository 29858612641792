/*
---
name: Top right nav
category: Components
---

```example.html
<nav class="top-right-nav">
  <ul>
    <li>
      <a href="/"><svg height="31" viewBox="0 0 31 31" width="31" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" transform="translate(1 1)"><circle cx="14.5" cy="14.5" fill="#fff" r="14.5" stroke="#ffa500"/><g stroke="#333" stroke-linecap="round"><path d="m12.5 9.5c1.6866048-1.19262695 3.1963298-1.50211589 4.5291748-.9284668 1.9992676.86047364 2.1080615 3.5461039.8015137 4.6246338-1.3065479 1.0785299-3.3306885 2.4051514-3.3306885 3.6623535v1.6414795"/><path d="m14.5 21.5v1"/></g></g></svg></a>
    </li>
    <li>
      <a href="/"><svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle cx="16" cy="16" fill="#fff" r="15" stroke="#ffa500"/><g stroke="#333"><circle cx="14.944828" cy="14.944828" fill="#fff" r="6.155172"/><path d="m19.34138 19.34138 4.044828 4.044828" stroke-linecap="round" stroke-linejoin="round"/></g></g></svg></a>
    </li>
  </ul>
</nav>
```
*/
.top-right-nav {
  @include touch {
    margin-top: var(--space-8);
    display: none;
  }

  ul {
    display: flex;
    @include touch {
      display: block;
    }
  }

  li + li {
    margin-left: var(--space-12);
    @include touch {
      margin-left: 0;
    }
  }

  a {
    @include text-cta-bare;
    text-decoration: none;
    @include touch {
      padding: var(--space-8) var(--space-12);
    }
  }
}

con-header-menu[active-level="root"] {
  .top-right-nav {
    display: flex;
  }
}

.top-right-nav__item-icon {
  font-style: normal;
  border: 1px solid var(--yellow);
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include touch {
    margin-right: var(--space-12);
  }

  .icon {
    font-size: var(--font-size-16);
  }
}

.top-right-nav__item {
  display: flex;
  align-items: center;
  width: 100%;

  &:hover, &:active {
    .top-right-nav__item-icon {
      border-color: var(--black);
      background-color: var(--black);
      text-decoration: none;
      color: var(--white);
      svg {
        --icon-stroke: var(--yellow);
      }
    }
  }
}

.top-right-nav__item-icon-label {
  display: none;
}

@mixin top-right-nav__item-icon--has-label {
  width: auto;
  border-radius: 20px;
  padding: 0 1rem;

  .top-right-nav__item-icon-label {
    display: block;
  }

  .top-right-nav__item-icon-label + .icon {
    margin-left: var(--space-4);
    margin-right: -0.25rem;
  }

  .icon + .top-right-nav__item-icon-label {
    margin-left: var(--space-4);
  }
}

.top-right-nav__item-icon--has-label {
  @include top-right-nav__item-icon--has-label;
}

.wide\:top-right-nav__item-icon--has-label {
  @include wide {
    @include top-right-nav__item-icon--has-label;
  }
}

.top-right-nav__item-label {
  display: none;
  @include touch {
    display: block;
  }
}

.header--dark {
  .top-right-nav__item {
    &:hover, &:focus, &:active {
      .top-right-nav__item-icon {
        background-color: var(--black);
        color: var(--yellow);
      }
      svg {
        --icon-stroke: var(--yellow);
      }
    }
    @include touch {
      color: var(--white);
    }
  }
  .top-right-nav__item-icon {
    border: 1px solid var(--yellow);
    background-color: var(--white);
    color: var(--black);
    @include touch {
      border: 1px solid #000002;
    }
  }
}
