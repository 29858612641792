/*
---
name: Icon in circle
category: Icons
---

Icon in circle
```icon-in-circle.html
<span class="icon icon-in-circle">
  <svg height="11" viewBox="0 0 11 11" width="11" xmlns="http://www.w3.org/2000/svg"><path d="m6.5 1v11m-5.5-5.5h11" fill="none" stroke="#333" stroke-linecap="round" transform="translate(-1 -1)"/></svg>
</span>
```

Icon in filled circle
```icon-in-filled-circle.html
<span class="icon icon-in-filled-circle">
  <svg height="11" viewBox="0 0 11 11" width="11" xmlns="http://www.w3.org/2000/svg"><path d="m6.5 1v11m-5.5-5.5h11" fill="none" stroke="#333" stroke-linecap="round" transform="translate(-1 -1)"/></svg>
</span>
```
 */
@mixin hasIcon {
  display: inline-flex;
  align-items: center;

  > .icon {
    &:first-child:not(:last-child) {
      //      margin-right: var(--space-12);
      //     margin-left: calc(-.5em - 1px);
      margin-right: .5em;
      //}
    }

    &:last-child:not(:first-child) {
      //margin-left: var(--space-12);
      margin-left: .5em;
      //margin-right: calc(-.5em - 1px);
    }
  }
}

.has-icon {
  @include hasIcon;
}

.icon {
  display: inline-flex;
  font-size: var(--font-size-20);
  line-height: 1;

  svg {
    display: block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    font-size: inherit;
  }

  &--yellow {
    color: var(--yellow);
  }

  &--black {
    color: var(--black);
  }

  &--white {
    color: var(--white);
  }

  &--xs {
    svg {
      font-size: var(--font-size-12)
    }
  }

  &--sm {
    svg {
      font-size: var(--font-size-16)
    }
  }

  &--md {
    svg {
      font-size: var(--font-size-24);
    }
  }

  &--lg {
    svg {
      font-size: var(--font-size-30);
    }
  }

}

a, button {
  .icon--hover-visible {
    display: none;
  }
}

a:hover, button:hover {
  .icon--hover-hidden {
    display: none;
  }

  .icon--hover-visible {
    display: inline-block;
  }
}


.icon--border-yellow {
  border-color: var(--yellow);
}

.svg-icon-plus, .svg-icon-minus {
  font-size: 11px;
  stroke: var(--black);
}

.svg-icon--yellow {
  stroke: var(--yellow);
}

.svg-icon--dark-yellow {
  color: var(--dark-yellow);
}

.svg-icon--white, .svg-icon--white * {
  stroke: var(--white);
}

/**
 * Modified icons on hover / parent active.
 */
a:hover,
button:hover,
.active > a,
con-tooltip[opened] {
  & > {
    // Search icon
    .icon__search-circle {
      --circle-fill: var(--black);
      --circle-stroke: var(--black);
      --lens-stroke: var(--yellow);
      --circle-stroke-width: 2px;
    }

    // Help icon
    .icon__help {
      --circle-fill: var(--black);
      --circle-stroke: var(--black);
      --question-stroke: var(--yellow);
      --question-stroke-width: 2px;
    }

    .icon__help--yellow-on-hover {
      --circle-fill: var(--yellow);
      --circle-stroke: var(--yellow);
      --question-stroke: var(--black);
      --question-stroke-width: 2px;
    }

    .icon__close-circle {
      --circle-fill: var(--black);
      --cross-stroke: var(--white);
      --circle-stroke: var(--white);
    }

    .icon__close-circle.icon--white {
      --circle-fill: var(--white);
      --cross-stroke: var(--black);
      --circle-stroke: var(--white);
    }

    .icon__pin {
      --pin-fill: var(--yellow);
      --pin-stroke: var(--yellow);
      --circle-fill: var(--black);
    }
  }
}

.icon__close-circle {}

.icon__newflag.icon--custom-size svg {
  width: 40px;
  height: 20px;
}

.svg__share,
.svg__chat,
.svg__more-details,
.svg__tcp-logo {
  stroke: none;
}

.svg-icon-download-arrow {
  font-size: 15px;
}

