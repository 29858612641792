con-tire-finder-search-by-license-form {
  display: block;

  .tire-finder__fields {

    display: grid;
    grid-gap: var(--space-20);
  }
}

.search-by-license {

}

.search-by-license__subtitle {
  text-align: center;

  b {
    display: block;
    text-transform: uppercase;
  }
}

.search-by-license__info {
  display: block;
  @include regular-border-top;
  @include regular-border-bottom;
  padding: var(--space-4) 0;
  margin-bottom: var(--space-28);
}

.search-by-license__info-row {
  display: grid;
  grid-template-columns: 110px 1fr 100px;
  grid-column-gap: 20px;
  padding: var(--space-4) 0;
  align-items: center;

  & > *:nth-child(3) {
    justify-self: end;
  }
}

.search-by-license__info-label {
  @include as-h6;
}

.search-by-license__not-found {
  display: flex;
  justify-content: center;
  margin: -15px auto var(--space-32) auto;

  font-size: var(--font-size-14);
  line-height: var(--line-height-20);
  font-weight: bold;

  .icon {
    margin-right: var(--space-12);
    font-size: var(--font-size-20);
  }
}

.search-by-license__loading {
  img {
    display: block;
    margin: 0 auto;
  }
}

.search-by-license-form__info {
}

.search-by-license-form__subtitle {
  text-align: center;
  margin-top: var(--space-20);
  font-size: var(--space-20);
}
