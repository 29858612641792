.stars-widget {
  display: inline-flex;
}

.stars-widget__item {
  position: relative;
  margin-right: 0;
  line-height: 0;
}

.stars-widget__item-on {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.stars-widget__item-on--full {
  position: static;
}
