.thumbnails > div {
  display: flex;
  //flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & > * {
    max-width: calc(16.6% - var(--space-20));
    width: 100%;
    height: auto;
    margin-top: var(--space-20);
  }

  & > * + * {
    margin-left: var(--space-20);
  }

  img {
    @include img-responsive;
    border-radius: var(--space-12);
  }
}
