@mixin text-underline--yellow {
  text-decoration: underline;
  text-decoration-color: var(--dark-yellow);
}

@mixin fake-underline($color: var(--dark-yellow), $top: 81%, $bottom: 85%) {
  background-image: linear-gradient(
      180deg, rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) $top,
      $color $top + 0.1%,
      $color $bottom,
      rgba(0, 0, 0, 0) $bottom + 0.1%,
      rgba(0, 0, 0, 0)
  );
  text-decoration: none;
}

@mixin hover-reset {
  transition: none;
  &:hover, &:focus {
    text-decoration: none;
    background-image: none;
  }
}

/*
---
name: Links
category: Typography
---

```links.html
<a href="#">I'm a regular link</a><br/>
<a href="#" class="text-cta-link">I'm a CTA link</a>
```
*/
@mixin a-hover {
  transition: text-decoration 400ms ease-in;
  text-decoration: transparent underline solid;
  &:hover, &:focus {
    @include text-underline--yellow;
    cursor: pointer;
  }
}

a {
  @include a-hover;
  letter-spacing: var(--letter-spacing-0_25);
}

[id],
a[name] {
  @include middle {
    /*
     * Fixes scroll position for jump-links.
     */
    scroll-margin-top: 110px; // Sum of heights of the fixed header and the promo bar.
  }
}

@mixin text-cta-bare {
  font-size: var(--font-size-12);
  font-weight: bold;
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-1_25);
  text-transform: uppercase;
}

@mixin text-cta {
  @include text-cta-bare;
  @include hasIcon;
}

a.link-with-arrow {
  display: inline-block;
  position: relative;

  &:after {
    content: '';
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg height='11' viewBox='0 0 16 11' width='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m10.5795207 0 5.3333333 4.5-5.3333333 4.5m-10.10893246-4.5h15.05882356' fill='none' stroke='%23333' stroke-linecap='round' stroke-linejoin='round' transform='translate(0 1)'/%3E%3C/svg%3E");
    background-position: center center;
    width: 17px;
    height: 9px;
    margin-left: var(--space-8);
  }

  &:hover, &:focus {
    &:after {
      @include animated;
      @include animated-infinite;
      @include animated--bounceRight;
      @include animated-slow;
    }
  }
}

[data-modal-target] * {
  pointer-events: none;
}
