.user-current-vehicle {
  width: 100%;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--space-20);
  @include mobile {
    grid-template-columns: 1fr;
  }
  .fake-input__input {
    --icon-pencil-underline-stroke-color: var(--dark-grey);
  }
}

.user-current-vehicle--size {
  grid-template-columns: 1fr;
  max-width: 400px;
}

.user-current-vehicle__title {

}

.user-current-vehicle__form {

}

.user-current-vehicle__link-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
}
