.legal-page__marquee {
  padding: var(--space-60) 0;
  @include mobile {
    padding: var(--space-38) 0;
  }
  .blank-marquee__title {
    max-width: none;
  }
}

.legal-page__rich-text {
  .article-content {
    margin: 0 auto;
  }

  .rich-text {
    padding: var(--space-60) 0;
    @include mobile {
      padding: var(--space-38) 0;
    }
  }


}
