con-tire-fit {
  display: block;
}

.con-tire-fit__row {
  display: flex;
  align-items: flex-start;
  > * + * {
    margin-left: var(--space-20);
  }
}

.con-tire-fit__label {
  width: 100%;
  line-height: 16px;
}

.con-tire-fit__label b {
  font-size: 12px;
  line-height: 16px;
}

.tire-fit {
  text-align: left;
  --border-color: var(--grey);
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.10);
  display: block;
  border-radius: 6px;
  background-color: var(--white);
  .con-details__toggle {
    width: 100%;
    > .icon {
      display: none;
    }
  }
  .link-button {
    text-decoration: underline;
  }

  &.tire-fit--warning {
    --border-color: var(--yellow);
    background-color: #FEF6E5;
    border: 1px solid var(--border-color);
  }
}



.tire-fit__header-tag--open {
  display: none;
}

.tire-fit[open] {
  .tire-fit__header-tag--open {
    display: block;
  }
  .tire-fit__header-tag--close {
    display: none;
  }
}

.tire-fit__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-12);

}

.tire-fit__status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}

.tire-fit__content.tire-fit__content {
  padding: var(--space-12);
  margin-top: 0;
  border-top: 1px solid var(--border-color);
}

.tire-fit__values {
  padding: var(--space-8);
}

.tire-fit__partial-match-message {
  line-height: var(--line-height-20);
  margin-bottom: var(--space-12);
}

.tire-fit__na-message {
  font-size: var(--font-size-12);
}

.tire-fit__footer {
  padding-top: var(--space-12);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tire-fit__search-by {
  @include space-y(var(--space-12));
  padding: var(--space-20);
}

.tire-fit__search-by-header {
  @include as-h5;
  text-align: center;
}

.tire-fit__search-by-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button {
    @include space-y(var(--space-4));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-12);
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
  .icon {
    font-size: var(--font-size-32);
  }
}
