@media print {
  @page {
    size: Letter; /* DIN A4 standard, Europe */
    margin: 10mm 0 10mm 0;
  }

  // Hide what should be hidden on the desktop
  .no-print,
  .btn,
  // Link buttons
  .link-button,
  .hidden-desktop,
    // Hide main nav
  .main-nav__top,
    // Hide top right nav
  .top-right-nav,
  // Header menu
  .con-header-menu,
    // Hide promo bar
  .promo-bar,
    // Hide play button on media
  .media-video.media--cta:after,
    // Show only selected stores in the store finder
  .con-store-finder-results__item:not(.con-store-finder-results__item--selected),
  // Hide search results form
  .site-search__marquee,
  // Hide perfect fit bar
  .tire-listing-page__find-perfect-fit,
  // Hide see details link on tire listing
  .tire-teaser__header-right,
  .tire-overall-ratings,
  .site-footer,
    // Hide social media links bar
  .social-media-links-bar,
  // Stores near block
  .stores-near-block,
  .news-article__default .news-article__sidebar,
  .news-article__sidebar .sharebar,
  .news-article__related,
  .cookie-popup,
  // Hide site footer
  .site-footer > .container {
    display: none !important;
  }
  .header-wrapper {
    position: static;
  }

  // Open Tire specs block.
  .con-tire-specs__details-list--collapsed,
  // Open con-details.
  con-details:not([open]) > *:nth-child(2) {
    display: block !important;
  }

  /**
   * Store finder.
   */
  .store-finder__main {
    padding-top: 0;
  }

  /**
   * Marquees
   */
  .marquee {
    color: #000;
    min-height: 0;
    .marquee__background {
      display: none;
    }
    .marquee__content {
      color: #000;
      padding: 20px 0;
    }
  }

  /**
   * Tire listing
   */
  .tire-listing-page__columns {
    padding-top: 0;
  }
  .tire-listing-page__left {
    padding-left: 0;
  }

  /**
   * News articles
   */
  .news-article__columns {
    display: flex;
  }

  .news-article__partners .news-article__columns {
    flex-direction: column-reverse;
  }
}

