.more-link {
  display: flex;
  justify-content: center;

  a {
    @include btn;
    @include btn--black;
    @include btn--border-white;
    background-color: transparent;
    @include mobile {
      width: 100%;
    }
  }
}
