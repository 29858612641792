@mixin regular-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 40px 0;
}

@mixin regular-shadow-hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 40px 0;
}

@mixin regular-gradient-bg {
  background-image: linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 27%, #FFFFFF 74%, #F5F5F5 99%);
}

@mixin top-dark-bottom-light-gradient-bg {
  background-color: var(--lightest-grey);
  background-image: linear-gradient(var(--lightest-grey), var(--white));
}

@mixin regular-border-top {
  border-top: 1px solid var(--grey);
}

@mixin regular-border-bottom {
  border-bottom: 1px solid var(--grey);
}

@mixin dark-background {
  --background-color: var(--black);
  background-color: var(--background-color);
  color: var(--white);
  @media print {
    --background-color: var(--white);
    background-color: var(--background-color);
    color: var(--black);
  }
}

@mixin very-dark-background {
  --background-color: #000000;
  background-color: var(--background-color);
  color: var(--white);
  @media print {
    --background-color: var(--white);
    background-color: var(--background-color);
    color: var(--black);
  }
}

.very-dark-background {
  @include very-dark-background;
}
