.heading {
  text-align: center;
  padding: var(--space-38) 0;
}

.heading__heading {
  @include as-h2;
  margin: 0 auto;
  max-width: 50ch;
}

.heading__subheading {
  font-size: var(--font-size-18);
  max-width: 65ch;
  margin: var(--space-8) auto 0 auto;
}
