.tire-rating-stars {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include text-cta;

  &:hover {
    background-image: none;
  }

  & > div {
    margin-right: var(--space-4);
  }
}