.tire-search-options {
  background: var(--lightest-grey);
  padding-top: var(--space-60);
  padding-bottom: var(--space-60);
  .container > * {
    margin-left: auto;
    margin-right: auto;
  }
}

.tire-search-options__tabs-wrapper {
  .container {
    //max-width: 100%;
  }
}

.tire-search-options__marquee-title {
  max-width: 19ch;
}
