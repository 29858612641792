.tire-promo-aon-cc__marquee {
  &.marquee--mobile-bg-divided {
    .marquee__background {
      @include touch {
        min-height: 160px;
      }
    }
  }
  .marquee__container {
    min-height: 368px;
    @include touch {
      min-height: auto;
    }
  }
  .marquee__buttons {
    margin-top: var(--space-38);
    @include touch {
      margin-top: var(--space-20);
    }
  }
}

.tire-promo-aon-cc-content {
  @include very-dark-background;
  padding: var(--space-80) 0;
  @include mobile {
    padding: var(--space-38) 0;
  }
}

.tire-promo-aon-cc-content-area {
  display: flex;
  align-items: center;

  @include mobile {
    display: block;
  }

  > * {
    width: 50%;
    @include mobile {
      width: 100%;
    }
  }

  > * + * {
    @include mobile {
      margin-top: var(--space-34);
    }
  }
}

.tire-promo-aon-cc-content-area__left {
  text-align: center;
}

.tire-promo-aon-cc-content-area__right {
  @include mobile {
    display: flex;
    justify-content: center;
  }
}

.tire-promo-aon-cc__checkmark-list {
  font-size: var(--font-size-24);
  line-height: var(--line-height-34);
  @include mobile {
    font-size: var(--font-size-18);
    line-height: var(--line-height-24);
  }
  li:before {
    background-size: 0.6em;
    transform: translateY(5%);
  }
}

.tire-promo-aon-cc-apply {
  @include dark-background;
  text-align: center;
  padding-bottom: var(--space-38);
  @include mobile {
    padding-bottom: var(--space-20);
  }
  .container > * + * {
    margin-top: var(--space-45);
    @include mobile {
      margin-top: var(--space-28);
    }
  }
  .estimate-payments {
    @include mobile {
      margin-top: var(--space-38);
    }
  }
}

.tire-promo-aon-cc-apply__title {
  @include as-h1;
  text-align: center;
  max-width: 25ch;
  margin: 0 auto;
}

.tire-promo-aon-cc-apply__cta-wrapper {
  > * {
    max-width: 150px;
    width: 100%;
    @include mobile {
      max-width: calc(375px - var(--container-padding) * 2);
    }
  }
  > * + * {
    margin-left: var(--space-20);
    @include mobile {
      margin-left: 0;
      margin-top: var(--space-12);
    }
  }

}

.tire-promo-aon-cc-card {
  > * + * {
    margin-top: var(--space-20);
  }

  img {
    display: block;
    width: 100%;
    max-width: 440px;
    height: auto;
    @include mobile {
      max-width: 215px;
      margin: 0 auto;
    }
  }

  p {
    font-size: var(--font-size-20);
    text-align: center;
  }
}

