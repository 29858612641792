/*
---
name: Marquee
category: Components
---
### Light mode
```light-mode.html
<div class="marquee">
  <div class="marquee__background" style="background-image: url('demo_assets/marquee.png');"></div>

    <div class="marquee__content">
      <div class="marquee__texts">
        <h2 class="marquee__title">Take Control of Winter with VikingContact™ 7</h2>
        <p class="marquee__text">
          Get a $70 Visa Prepaid Card with purchase of 4 VikingContact™ 7 tires.
        </p>
        <div class="marquee__cta"><a href="#">See the VikingContact™ 7 tires</a><div>
      </div>

      <div class="marquee__buttons">
        <a class="btn btn--white">Find tires that fit</a>
      </div>
    </div>

</div>
```

### Dark mode
```dark-mode.html
<div class="marquee marquee--dark">
  <div class="marquee__background" style="background-image: url('demo_assets/marquee.png');"></div>
    <div class="container">
      <div class="marquee__content">
        <div class="marquee__texts">
          <h2 class="marquee__title">Take Control of Winter with VikingContact™ 7</h2>
          <p class="marquee__text">
            Get a $70 Visa Prepaid Card with purchase of 4 VikingContact™ 7 tires.
          </p>
          <div class="marquee__cta"><a href="#">See the VikingContact™ 7 tires</a><div>
        </div>

        <div class="marquee__buttons">
          <a class="btn btn--white">Find tires that fit</a>
        </div>
      </div>
    </div>
</div>
```

### Centered marquee
```centered.html
<div class="marquee marquee--centered">
    <div class="marquee__background"
         style="background-image: url('demo_assets/marquee.png')"></div>
    <div class="container">
        <div class="marquee__content">
            <div class="marquee__texts"><h1 class="marquee__title">Take control of winter.</h1>
                <p class="marquee__text">And get a $70 Visa® Prepaid Card with the purchase of 4
                    VikingContact™ 7 tires<sup><a href="#toc-1">*</a></sup></p>
                <div class="marquee__cta"><a href="#details">See full details</a></div>
            </div>
            <div class="marquee__buttons button-bar"><a href="#" class="btn">Store Finder</a><a href="#" class="btn">Find
                    tire size</a></div>
        </div>
    </div>
</div>
```
*/
.marquee {
  position: relative;
  @include dark-background;
  display: flex;
  flex-direction: column;
  justify-content: start;

  @include touch {
    @include very-dark-background;
    min-height: auto;
  }

  .breadcrumb li + li::before {
    border-right: 2px solid var(--white);
  }
}

.marquee__jump-link {
  position: absolute;
  left: calc(50% - 10px);
  bottom: var(--space-20);
  z-index: 1;

  @include mobile {
    bottom: var(--space-16);
  }

  .icon__arrow-down-outline {
    @include middle {
      stroke-width: 1;
    }

    svg {
      font-size: 28px;
    }
  }
}

.marquee--has-jumplink {
  padding-bottom: 50px;
  @include mobile {
    padding-bottom: 50px;
  }
}


.marquee__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-grow: 1;
  min-height: var(--marquee-min-height, 345px);
  @include touch {
    min-height: var(--mobile-marquee-min-height, 220px);
  }
}

.marquee__content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
  max-width: 660px;
  width: 100%;
  z-index: 1;
  color: var(--white);
  padding: var(--space-28) 0;

  @include touch {
    justify-content: flex-end;
    padding: var(--space-28) 0 var(--space-28) 0;
    margin: 0 auto;
    text-align: center;
  }

  > * + * {
    margin-top: var(--space-16);
    @include touch {
      margin-top: var(--space-20);
    }
  }
}

.marquee--has-jumplink {
  .marquee__content {
    @include touch {
      //padding-bottom: 62px;
    }
  }
}


.marquee--has-breadcrumbs {
  &.marquee--mobile-bg-divided {
    .marquee__breadcrumbs {
      @include touch {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .marquee__background {
      @include touch {
        height: 160px;
      }
    }
  }

  .marquee__container {
    margin-top: calc(-1 * var(--breadcrumb-min-height));
    @include touch {
      margin-top: 0;
    }
  }
}

.marquee__breadcrumbs {
  position: relative;
  z-index: 1;
}

.marquee__jump-link {
  position: absolute;
  // left: calc(50% - 10px);
  bottom: var(--space-20);

  @include touch {
    bottom: var(--space-8);
  }

  .icon__arrow-down-outline {
    @include middle {
      stroke-width: 1;
    }

    svg {
      font-size: 28px;
    }
  }
}

.marquee--centered,
.marquee--center {
  .marquee__content {
    max-width: none;
    align-items: center;
    text-align: center;
  }

  .marquee__texts {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.marquee--bottom {
  justify-content: flex-end;
  .marquee__content {
    max-width: none;
    align-items: center;
    text-align: center;
    padding-bottom: 70px;
  }
}

.marquee--left {
  .marquee__container {
    align-items: flex-start;
  }

  .marquee__content {
    max-width: 60%;
    @include touch {
      max-width: initial;
    }
  }
}

.marquee--bottom {
  justify-content: flex-end;

  .marquee__content {
    max-width: none;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
  }

  &.marquee--has-jumplink {
    .marquee__content {
      padding-bottom: 70px;
      @include touch {
        padding-bottom: 62px;
      }
    }
  }
}

.marquee__buttons {
  @include button-bar;

  @include mobile {
    width: 100%;
  }

  .btn {
    @include mobile {
      width: 100%;
      max-width: calc(375px - var(--container-padding) * 2);
    }
  }
}

.marquee__background {
  background-color: #000;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-image: var(--default-background-image);
  background-repeat: no-repeat;
  background-position: var(--marquee-bg-position, center center);
  background-size: cover;

  @include touch {
    min-height: 240px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, var(--marquee-bg-opacity, .2));
    @include touch {
      background: rgba(0, 0, 0, var(--marquee-bg-opacity--mobile, var(--marquee-bg-opacity, .2)));
    }
  }
}

.marquee--mobile-bg-divided {
  .marquee__background:not(.marquee__background--promo-ended) {
    @include touch {
      min-height: auto;
      background-color: transparent;
      height: var(--mobile-marquee-min-height, 130px);
      background-size: cover;
      background-position: var(--marquee-mobile-bg-position, center center);
      background-image: var(--mobile-background-image, var(--default-background-image));

      position: static;
      width: 100%;

      &:after {
        display: none;
      }
    }
  }

  .marquee__container {
    @include touch {
      min-height: auto;
    }
  }

  .marquee__background--promo-ended {
    @include mobile {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-size: 80vw;
    }
  }
}

.marquee__background--promo-ended {
  background: rgba(255, 255, 255, 0.8) url('/themes/custom/nextcontinental/assets/images/promo_ended.svg') no-repeat center center;
  background-size: 350px;
  z-index: 2;

  @include touch {
    position: absolute;
    background-size: 200px;
  }
}

.marquee__texts {
  > * + * {
    margin-top: var(--space-8);
  }

  .text-large {
    font-size: var(--font-size-50);
    line-height: var(--line-height-60);
    @include touch {
      font-size: var(--font-size-32);
      line-height: var(--line-height-40);
    }
  }

  .text-uppercase {
    letter-spacing: 6px;
    @include touch {
      letter-spacing: 5px;
    }
  }
}

.marquee__texts + .marquee__buttons {
  margin-top: var(--space-26);
}

.marquee__title {
  @include as-h1;
  .text-uppercase {
    @include as-h1u;
  }
  br {
    @include touch {
      display: none;
    }
  }
}

.marquee__text {
  @include text-lg;
  font-size: var(--font-size-20);
  font-weight: normal;
  @include touch {
    @include text-lg;
  }
  br {
    @include touch {
      display: none;
    }
  }
}

.marquee__cta {
  margin-top: var(--space-8);

  a {
    @include text-cta;
    display: inline-block;

    &:hover {
      .icon {
        @include animated;
        @include animated-infinite;
        @include animated--bounceRight;
        @include animated-slow;
      }
    }
  }

  .icon {
    font-size: var(--font-size-18);
    position: relative;
    top: var(--space-4);
  }
}

.marquee__sharebar {
  display: flex;
  align-items: center;

  @include touch {
    justify-content: center;
  }

  li + li {
    margin-left: var(--space-20);
  }

  svg {
    color: var(--white);
  }
}

.marquee__sharebar-title {
  margin-right: var(--space-16);
  @include text-cta;
}

.marquee--light {
  --background-color: #FFFFFF;
  color: var(--black);
  .marquee__background {
    background-color: var(--background-color);
  }
  .marquee__content {
    color: var(--black);
    .text-color-yellow {
      color: var(--dark-yellow);
    }
  }
  .marquee__buttons {
    .btn {
      @include btn--yellow;
    }
  }
}

.marquee--light-mobile-bg-position {
  .marquee__background {
    @include mobile {
      background-position: bottom right !important;
    }

  }
}

.marquee--blank {
  background-image: url("/themes/custom/nextcontinental/assets/images/black-bg.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  .marquee__container {
    min-height: 210px;
    @include touch {
      min-height: auto;
    }
  }

  .marquee__content {
    @include touch {
      justify-content: center;
      padding: var(--space-38) 0;
    }
  }

  &.marquee--has-breadcrumbs {
    .marquee__content {
      @include touch {
        padding-top: var(--space-16);
      }
    }
  }

  .marquee__title {
    @include as-h1u;
  }

  .marquee__text {
    @include as-h3;
    font-weight: normal;
    @include touch {
      font-size: var(--font-size-14);
      line-height: var(--line-height-20);
    }
  }
}


.marquee--brand22 {
  min-height: 490px;
  color: var(--white);

  @include touch {
    background: #000000;
    min-height: 330px;

    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
  }

  .marquee__title {
    font-size: var(--font-size-50);
    line-height: var(--line-height-60);
    @include touch {
      font-size: var(--font-size-32);
      line-height: var(--line-height-40);
    }

    br + span {
      @include touch {
        display: block;
        margin-top: var(--space-8);
      }
    }

    span {
      font-weight: normal;
      font-size: var(--font-size-42);
      line-height: var(--line-height-48);
      letter-spacing: 8px;
      @include touch {
        font-size: var(--font-size-28);
        line-height: var(--font-size-32);
        letter-spacing: 5px;
      }
    }

    b {
      color: var(--yellow);
      font-weight: normal;
    }


  }

  .marquee__container {
    @include touch {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
  }


  .marquee__content {
    color: var(--white);
  }

  &.marquee--bottom .marquee__content {
    padding-bottom: var(--space-26);
    @include touch {
      padding-bottom: var(--space-38);
    }
  }

  &.marquee--bottom .marquee__container {
    @include touch {
      justify-content: flex-end;
    }
  }

  .marquee__background {
    @include touch {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
    }
  }
}

.marquee--brand22 {
  min-height: 490px;
  color: var(--white);

  @include mobile {
    background: #000000;
    min-height: 330px;

    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
  }

  .marquee__title {
    font-size: var(--font-size-50);
    line-height: var(--line-height-60);
    @include mobile {
      font-size: var(--font-size-32);
      line-height: var(--line-height-40);
    }

    br + span {
      @include mobile {
        display: block;
        margin-top: var(--space-8);
      }
    }

    span {
      font-weight: normal;
      font-size: var(--font-size-42);
      line-height: var(--line-height-48);
      letter-spacing: 8px;
      @include mobile {
        font-size: var(--font-size-28);
        line-height: var(--font-size-32);
        letter-spacing: 5px;
      }
    }
    b {
      color: var(--yellow);
      font-weight: normal;
    }



  }

  .marquee__container {
    @include mobile {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
  }


  .marquee__content {
    color: var(--white);
  }

  &.marquee--bottom .marquee__content {
    padding-bottom: var(--space-26);
    @include mobile {
      padding-bottom: var(--space-38);
    }
  }

  &.marquee--bottom .marquee__container {
    @include mobile {
      justify-content: flex-end;
    }
  }

  .marquee__background {
    @include mobile {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
    }
  }



}
