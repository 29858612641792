.search-result {
  @include regular-shadow;
  color: var(--black);
  padding: var(--space-20);
  border-radius: var(--space-12);
  background-color: var(--white);

  > * + * {
    margin-top: var(--space-12);
  }
}

.search-result:hover {
  @include regular-shadow-hover;
}

.search-result__title {
  @include as-h4;
}

.search-result__image {
  flex: 0 0 76px;
  overflow: hidden;
  img {
    border-radius: var(--space-12);
    width: 76px;
    max-height: 76px;
  }
}

.search-result__content {
  display: flex;

  > * + * {
    margin-left: var(--space-16);
  }
}


