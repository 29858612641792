body {
  overflow-x: hidden;
  background-color: #000;
}

body.has-modal-opened {
  overflow: hidden;
}

.page {
  overflow: hidden;
  background: #fff;
}

@mixin container {
  margin: 0 auto;
  max-width: 73rem; // 1168px;
  width: 100%;
  padding: 0 var(--container-padding, var(--space-16));
  @include mobile {
    padding: 0 var(--container-padding, var(--space-20));
  }
}

.container {
  @include container;
}

.container--no-limits {
  --container-padding: var(--space-38);
  max-width: 100%;
  @include mobile {
    --container-padding: var(--space-20);
  }
}

.container--header {
  max-width: 100%;
  --container-padding: var(--space-20);
}

.container--wide {
  max-width: 1390px;
}

.container--no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mobile\:container--no-padding {
  @include mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
