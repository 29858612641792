.site-footer__top {
  border-bottom: 1px solid var(--light-grey);
}

.site-footer__main {
  padding: var(--space-60) 0 var(--space-38) 0;
  @include mobile {
    padding: var(--space-8);

  }
}

.site-footer__buttons {
  max-width: 9rem;
  margin-bottom: var(--space-24);

  @include mobile {
    margin-left: 0;
    margin-bottom: 0;
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--space-20);
  }

  > * {
    display: block;
    width: 100%;
  }

  > *:first-child {
    @include mobile {
      grid-column: span 2;
    }
  }

  > * + * {
    margin-top: var(--space-12);
    @include mobile {
      margin-top: 0;
    }
  }
}

.site-footer__store-finder-buttons.site-footer__store-finder-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 60px;

  @include max-width(1350px) {
    grid-template-columns: 1fr;
    display: block;
  }

  @include mobile {
    border-top: 0;
    padding-bottom: 0;
  }
}

.site-footer__bottom {
  background-color: var(--white);
  padding: var(--space-20) 0;
  border-top: 1px solid var(--grey);

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include mobile {
    > div {
      display: block;
    }

    small {
      display: block;
      margin-bottom: var(--space-16);
      @include mobile {
        margin-bottom: var(--space-20);
      }
    }
  }
}

.site-footer__search-for-site {
  h2 {
    margin-bottom: var(--space-13);
  }

  ul {
    margin-bottom: var(--space-20);
    @include mobile {
      text-align: left;
    }

    li + li {
      margin-top: var(--space-12);
    }
  }

  @include mobile {
    text-align: center;
    margin-top: var(--space-24);

    ul {
      display: inline-block;
      margin-bottom: var(--space-28);
    }
  }
}

.site-footer__search-bar {
  @include mobile {
    margin: 0 -1rem;
    background-color: var(--white);
    border-top: 1px solid var(--grey);
    border-bottom: 0;

    a {
      padding: var(--space-20);
      display: flex;
      justify-content: center;
    }

    svg {
      font-size: var(--font-size-30);
    }

  }

  .svg__search-circle {
    --circle-fill: var(--yellow);
    --circle-stroke-width: 2px;
  }

  &:hover, &:focus {
    .svg__search-circle {
      --circle-fill: #000000;
      --lens-stroke: var(--yellow);
    }
  }
}

.site-footer__search-column {
  display: flex;
  justify-content: center;
  @include max-width(1090px) {
    justify-content: flex-start;
  }
  > div {
    @include mobile {
      width: 100%;
    }
  }
}

/**
 * Sets consistent icon size in footer
 */
.site-footer__search-for-site,
.site-footer__search-bar {
  .icon {
    font-size: 25px;
  }
}
