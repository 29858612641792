.sports-thumbnails {
  @include dark-background;
  padding: var(--space-80) 0;
  @include mobile {
    padding: var(--space-38) 0;
  }
}

.sports-thumbnails__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--space-38);

  @include mobile {
    margin-bottom: var(--space-16);
  }

  > * + * {
    margin-top: var(--space-8);
  }
}

.sports-thumbnails__title {
  @include as-h1u;
}

.sports-thumbnails__subtitle {
  @include text-xl;
  @include mobile {
    @include text-base;
    text-align: center;
  }
}

.sports-thumbnails__grid .view-rows {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(365px, 1fr));
  grid-gap: var(--space-20);
  @include mobile {
    grid-template-columns: 1fr;
  }
}

.sports-thumbnails__grid--animated {
  .view-rows .sports-teaser {
    @include animated;
    @include animated--fadeInUp;
    @include animated-staggered(3);
  }
}
