youtube-embed {
  display: block;
  background-color: #000;
  width: 100%;

  //max-width: 900px;
  //height: calc(100vw / (16 / 9));
  //max-height: calc(900px / (16 / 9));
}

//youtube-embed {
//  background-color: #000;
//  position: relative;
//  display: flex;
//  contain: content;
//  background-position: center center;
//  background-size: cover;
//  cursor: pointer;
//  width: 100%;
//  //max-height: calc(100% / (16 / 9));
//  overflow: hidden;
//}
//
///* gradient */
//youtube-embed::before {
//  content: '';
//  display: block;
//  position: absolute;
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
//  background: rgba(0, 0, 0, 0.3);
//  height: 100%;
//  width: 100%;
//}
//
///* responsive iframe with a 16:9 aspect ratio
//    thanks https://css-tricks.com/responsive-iframes/
//*/
//youtube-embed::after {
//  content: "";
//  display: block;
//  padding-bottom: calc(100% / (16 / 9));
//}
//
//youtube-embed iframe {
//  width: 100%;
//  height: 100%;
//  position: absolute;
//  top: 0;
//  left: 0;
//}
//
///* play button */
//.youtube-embed__playbtn {
//  width: 76px;
//  height: 76px;
//  position: absolute;
//  transform: translate3d(-50%, -50%, 0);
//  top: 50%;
//  left: 50%;
//  z-index: 1;
//  background-image: url("data:image/svg+xml,%3Csvg height='76' viewBox='0 0 76 76' width='76' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='38' cy='38' fill='%23000' fill-opacity='.2' r='37' stroke='%23fff' stroke-width='2'/%3E%3Cpath d='m34 26 14 12.5-14 12.5z' fill='%23ffa500'/%3E%3C/g%3E%3C/svg%3E");
//}
//
//youtube-embed:hover .youtube-embed__playbtn {
//  background-image: url("data:image/svg+xml,%3Csvg height='76' viewBox='0 0 76 76' width='76' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='38' cy='38' fill='%23fff' fill-opacity='.2' r='37' stroke='%23fff' stroke-width='2'/%3E%3Cpath d='m34 26 14 12.5-14 12.5z' fill='%23ffa500'/%3E%3C/g%3E%3C/svg%3E");
//}
//
///* Post-click styles */
//youtube-embed.youtube-embed--active {
//  cursor: unset;
//}
//
//youtube-embed.youtube-embed--active::before,
//youtube-embed.youtube-embed--active > .youtube-embed__playbtn {
//  opacity: 0;
//  pointer-events: none;
//}
//
//youtube-embed:hover img.youtube-embed__cover {
//  transform: scale(1.1);
//}
//
//youtube-embed[status=playing] {
//  .youtube-embed__playbtn,
//  .youtube-embed__cover {
//    display: none;
//  }
//}
