/** Mixins **/

@mixin mobile-nav-item-with-children {
  background-image: url("data:image/svg+xml,%3Csvg height='13' viewBox='0 0 19 13' width='19' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m12.5631808 0 6.3333334 5.5-6.3333334 5.5m-12.00435727-5.5h17.88235297' fill='none' stroke='%23333' stroke-linecap='round' stroke-linejoin='round' transform='translate(0 1)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - var(--space-20)) center;
}

@mixin mobile-nav-item-with-children-expanded {
  padding-left: var(--space-45);
  background-image: url("data:image/svg+xml,%3Csvg height='13' viewBox='0 0 18 13' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6.10714286 0-6.10714286 5.5 6.10714286 5.5m-5.42857143-5.5h17.24369747' fill='none' stroke='%23333' stroke-linecap='round' stroke-linejoin='round' transform='translate(0 1)'/%3E%3C/svg%3E");
  background-position: var(--space-20) center;
}

@mixin mobile-nav-item-with-children-dark {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='22px' height='13px' viewBox='0 0 22 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Nav-/-Link-/-N' transform='translate(-280.000000, -20.000000)' stroke='%23FFA500' stroke-width='2'%3E%3Cg id='arrow-/-outline-R' transform='translate(281.000000, 21.000000)'%3E%3Cpath d='M12.5631808,0 L18.8965142,5.5 L12.5631808,11 M0.558823529,5.5 L18.4411765,5.5'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - var(--space-16)) center;
}

@mixin mobile-nav-item-with-children-expanded-dark {
  padding-left: var(--space-45);
  background-image: url("data:image/svg+xml,%0A%3Csvg width='21px' height='13px' viewBox='0 0 21 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round'%3E%3Cg id='Nav-/-Link-/-N-Back' transform='translate(-13.000000, -20.000000)' stroke='%23FFA500' stroke-width='2'%3E%3Cg id='arrow-/-outline-L' transform='translate(15.000000, 21.000000)'%3E%3Cpath d='M6.10714286,0 L-3.07373175e-13,5.5 L6.10714286,11 M0.678571429,5.5 L17.9222689,5.5'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: var(--space-12) center;
}

/*
---
name: Main nav
category: Components
---

```example.html
<nav class="main-nav">
  <ul>
    <li><a href="">First item</a></li>
    <li><a href="">Second item</a></li>
    <li><a href="">Third item</a></li>
    <li><a href="">Fourth item</a></li>
  </ul>
</nav>
```
*/
.main-nav {
  //height: 100%;
  @include touch {
    background-color: var(--white);
  }
}

.main-nav__top {
  margin-left: var(--space-20);
  display: flex;
  height: 100%;
  @include touch {
    margin-left: 0;
    display: block;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.main-nav__backItem {
  display: none;
  @include touch {
    a {
      display: block;
      width: 100%;
      @include mobile-nav-item-with-children;
    }
  }
}

.main-nav__top-item {
  &:focus {
    outline: 1px solid blue;
  }
}

.main-nav__top-item--active-trail > a {
  @include text-underline--yellow;
}

.main-nav__top-item-link {
  @include text-cta;
  --main-nav-topitemlink-padding: var(--space-28) var(--space-20);
  display: flex;
  padding: var(--main-nav-topitemlink-padding);
  height: 100%;

  @include touch {
    padding: var(--space-20) var(--space-16);
    @include regular-border-bottom;
  }

  span {
    display: block;
    padding: 1px 0;
  }
}

.main-nav__top-item-link:hover {
  background-image: none;
  position: relative;
}

.main-nav__top-item--expanded .main-nav__top-item-link {
  @include touch {
    @include mobile-nav-item-with-children;
  }
}

con-header-menu[active-level="first"] [data-menu-item-open] .main-nav__top-item-link {
  @include touch {
    background-color: var(--lightest-grey);
    padding: var(--space-20);
    background-image: none;
    border-bottom: 0;
  }
}

con-header-menu[active-level="second"] [data-menu-item-open] .main-nav__top-item-link {
  @include mobile {
    @include mobile-nav-item-with-children-expanded;
  }
}

.main-nav__dropdown {
  background: var(--white);
  position: absolute;
  top: 4.5rem;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;

  display: none;

  @include middle {
    @include regular-shadow;
  }

  @include touch {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
  }
}

.main-nav__top-item--expanded .main-nav__dropdown {
  @include middle {
    overflow: hidden;
    display: block;
    transition: max-height 300ms ease-in-out;
    max-height: 0;
  }
}

[data-menu-item-open] .main-nav__dropdown {
  @include middle {
    max-height: 100vh;
  }
}

.main-nav__panel {
  padding: var(--space-38) var(--space-20);
  @include regular-border-top;
  width: 100%;

  @include touch-only {
    padding: var(--space-16);
  }

  @include mobile {
    border-top: 0;
    display: block;
    padding: 0;
  }

  .main-nav__column:not(.main-nav__column--expanded) {
    //margin-bottom: 0;
  }
}

.main-nav__panel:not(.main-nav__panel--cols-1) {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  @include touch-only {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--space-38);
  }
  @include mobile {
    display: block;
  }
}

.main-nav--style-tires .main-nav__panel,
.main-nav--style-default .main-nav__panel {
  > * {
    margin-bottom: var(--space-28);
  }

  @include touch {
    > * {
      margin-bottom: 0;
    }
  }
}

.main-nav--style-default .main-nav__panel {
  padding-bottom: var(--space-12);
}

.main-nav__column {
  display: block;
  padding-right: var(--space-38);
  @include touch {
    padding-right: 0;
  }
  @include mobile {
    padding: 0;
    @include regular-border-bottom;
  }
}

.main-nav__column--expanded {
  @include mobile {
    @include mobile-nav-item-with-children;
  }
}

.main-nav__column-header {
  @include middle {
    @include subtle-heading;
  }

  @include mobile {
    @include text-cta;
    display: block;
    padding: var(--space-20);
  }
}

a.main-nav__column-header {
  @include middle {
    @include subtle-heading-with-yellow-right-arrow;
  }
}

.main-nav__column--expanded .main-nav__column-content {
  @include mobile {
    display: none;
  }
}

.main-nav__column--expanded[data-menu-item-open] {
  @include touch {
    background: none;
  }
}

.main-nav__column--expanded[data-menu-item-open] .main-nav__column-header {
  @include touch {
    background-color: var(--lightest-grey);
    padding: var(--space-20);
    border-bottom: 0;
  }
}

.main-nav__column--expanded[data-menu-item-open] .main-nav__column-content {
  @include touch {
    display: block;
  }
}

.main-nav__second-level {
  margin-top: var(--space-16);
  @include mobile {
    margin-top: 0;
  }
}

.main-nav__item {
  @extend .as-h6;
  @include mobile {
    @include regular-border-top;
    padding: var(--space-20);
  }
}

.main-nav__second-level .main-nav__item {
  @include mobile {
    border-top: 0;
    padding: var(--space-12) var(--space-20);
  }
}

.main-nav__item + .main-nav__item {
  @include middle {
    margin-top: var(--space-12);
  }
}

/**
 * Nav item view modes
 */
.main-nav__item--all_caps {
  & > a {
    @include text-cta;
  }
}

/**
 Main nav styles
 */
.main-nav--style-default {
  .main-nav__panel {
    @include mobile {
      display: block;
    }
  }
}

.main-nav--style-tires {
  .main-nav__panel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 400px;

    @include touch {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include mobile {
      display: block;
    }

    .main-nav__column:first-child {
      grid-row: span 100;
    }

    .main-nav__column--expanded {
      padding-bottom: var(--space-38);
      @include touch {
        padding-bottom: 0;
      }

      @include mobile {
        @include regular-border-bottom;
      }
    }

    .main-nav__column:not(.main-nav__column--expanded) {
      margin-bottom: 0;
    }

    .main-nav__column {
      > * {
        @include touch {
          border-top: 0;
        }
      }
    }

    .main-nav__column:nth-child(5) {
      padding-right: 0;
    }

    .main-nav__column:last-child {
      @include regular-border-top;
      grid-column: span 4;
      padding-top: var(--space-28);

      @include touch {
        grid-column: span 2;
      }

      @include mobile {
        padding-top: 0;
        border-top: 0;
      }
    }
  }
}

[active-level="second"] [data-menu-item-open] {
  @include mobile {
    border-bottom: 0 !important;
  }
}

.main-nav--style-experience {

  .main-nav__panel {
    @media screen and (min-width: 1025px) {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      grid-gap: var(--space-20);

      .main-nav__column:first-child ul {
        column-count: 2;
      }
    }

    @include mobile {
      display: block;
    }
  }
}


.header--dark {
  .main-nav {
    background-color: var(--yellow);
    @include touch {
      background-color: #000002;
      color: var(--white);
    }
  }

  .main-nav__top-item a:hover {
    text-decoration-color: #000002;
  }

  .main-nav__top-item--active-trail > a {
    @include text-underline--yellow;
    text-decoration-color: var(--yellow);
  }

  .main-nav__panel {
    border-top: 0;
    padding: var(--space-38) 42px;

    @include touch {
      padding: var(--space-38) var(--space-20);
    }

    @include mobile {
      padding: 0;
    }
  }

  .main-nav__dropdown {
    background-color: #000000;
    color: var(--white);
  }

  .main-nav__column-header, .subtle-heading {
    color: var(--white);
  }

  a.main-nav__column-header {
    font-weight: bold;
  }

  .simple-stores-near__distance,
  .simple-stores-near__mi {
    color: var(--yellow);
  }

  .main-nav--style-tires .main-nav__panel .main-nav__column--expanded {
    border-bottom-color: var(--darkest-grey);
  }

  .main-nav--style-experience {
    .main-nav__panel {
      .main-nav__column:nth-child(4) {
        @include middle {
          border-color: var(--darkest-grey);
        }
      }
    }
  }

  @include mobile {
    .main-nav__second-level .main-nav__item {
      padding-top: var(--space-26);
      padding-bottom: var(--space-26);
    }
    .main-nav__column--expanded[data-menu-second-level-item-expandable]:not([data-menu-item-open]) {
      @include mobile-nav-item-with-children-dark;
    }
    .main-nav__second-level {
      margin-top: 0;
    }
  }

  @include touch {
    .main-nav__top-item-link, .main-nav--style-tires .main-nav__panel .main-nav__column--expanded {
      border-color: var(--darkest-grey);
    }

    .main-nav__top {
      border-top: 1px solid var(--darkest-grey);
    }

    .main-nav__top-item--expanded .main-nav__top-item-link {
      @include mobile-nav-item-with-children-dark;
    }

    con-header-menu[active-level="second"] [data-menu-item-open] .main-nav__top-item-link,
    .main-nav__backItem a,
    con-header-menu[active-level="first"] [data-menu-back-item] a,
    con-header-menu[active-level="second"] [data-menu-back-item] a {
      @include mobile-nav-item-with-children-expanded-dark;

    }

    con-header-menu[active-level="first"] [data-menu-item-open] .main-nav__top-item-link {
      background-color: var(--black);
    }

    .main-nav__top-item--expanded {
      margin-top: -1px;
    }
    .main-nav__column--expanded[data-menu-item-open] .main-nav__column-header {
      background-color: var(--black);
      margin-top: -1px;
    }


    .main-nav__column {
      border-bottom: 0;
    }
  }
}
