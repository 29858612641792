.offer-ribbon {
  display: inline-block;
  text-align: center;
  color: var(--dark-black);
  font-weight: bold;
  text-transform: uppercase;
  background-color: var(--yellow);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  padding: 2px var(--space-16);
  clip-path: polygon(5% 0, 100% 0, 95% 50%, 100% 100%, 0 100%);
  transition-duration: 0ms;
}

.offer-ribbon:hover {
  position: relative;
  color: var(--dark-black);
  text-decoration: none;
  &:before {
    z-index: -1;
    position: absolute;
    content: '';
    background-color: var(--white);
    top: 1px;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 2px);
    clip-path: polygon(4.5% 0, 100% 0, 95% 50%, 100% 100%, 0 100%);
  }
}
