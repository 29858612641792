.tire-search-modes__section {
  padding: var(--space-80) 0;
  @include mobile {
    padding: var(--space-38) 0;
  }
}

.tire-search-modes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > * + * {
    margin-top: var(--space-20);
  }
}

.tire-search-modes__title,
.tire-search-modes__subtitle {
  @include as-h3;
  @include mobile {
    @include text-base;
    font-weight: bold;
  }
}

.tire-search-modes__list {
  display: flex;
  align-items: center;

  li + li {
    margin-left: var(--space-20);
    @include mobile {
      margin-left: var(--space-16);
    }
  }

  li > * {
    min-width: 153px;
  }
}
