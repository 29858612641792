.rich-text {
  max-width: 104ch;
  margin: 0 auto;
  padding: var(--space-80) 0;
  @include mobile {
    padding: var(--space-38) 0;
  }
}


.banner + .rich-text {
  // When richtext is preceded by banner, get rid of top padding,
  // because banner already have whitespace around.
  padding-top: 0;
}
