.tire-images-filter-form {
  $form-space: var(--space-20);

  > h2 {
    @include mobile {
      text-align: center;
    }
  }

  & > *:not(.btn) {
    padding-bottom: $form-space;
  }

  & > * + * {
    margin-top: var(--space-20);
  }

  & > * {
    @include regular-border-bottom;
  }

  button {
    border: 0;
  }
}

.tire-images-filter-form legend {
  @include text-cta;
  display: table;
}

.tire-images-filter-form .field__type-checkbox {
  display: flex;
  align-items: center;

  label {
    @include text-small;
    text-transform: none;
    font-weight: bold;
  }
}

.tire-images-filter-form {
  .field__type-checkbox + .field__type-checkbox {
    margin-top: var(--space-8);
  }
}
