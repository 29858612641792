.tire-search-options-list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, 90px);
  grid-gap: var(--space-20);
  justify-content: center;
  max-width: 800px;
}

.tire-search-options-list--empty {
  display: block;
}

.tire-search-options-list--left-aligned {
 justify-content: flex-start;
}

.tire-search-options-list--2col {
  > * {
    flex: 0 1 40%;
  }
}

.tire-search-options-list--3col {
  > * {
    flex: 0 1 30%;
  }
}

.tire-search-options-list--4col {
  max-width: 1200px;

  > * {
    flex: 0 1 20%;
  }
}

.tire-search-options-list--2col,
.tire-search-options-list--3col,
.tire-search-options-list--4col {
  display: flex;
  flex-wrap: wrap;
  grid-gap: unset;

  > * {
    margin: var(--space-12);
    @include mobile {
      flex: 1 1 200px;
    }
  }
}

.tire-search-options-list__item {
  width: 100%;
}

.tire-search-options-list__item_wrapper {
  width: 100%;
  flex-basis: 100%;
}

.tire-search-options-list__item-custom-size {
  width: 100%;
  flex-basis: 100%;
  text-align: center;
  margin-top: var(--space-20);
}
